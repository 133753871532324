import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLanguage } from '@wfp/freerice-core/modules/app';

import './LevelCircularBar.scss';

import strings from 'res/strings';

const getLevelColor = (level, currentLevel) => {
  if (level > currentLevel) {
    // undefined
    return 'default';
  }
  if (currentLevel <= 2) {
    // green
    return 'green';
  }
  if (currentLevel === 3) {
    // yellow
    return 'yellow';
  }
  // red
  return 'red';
};

/* eslint-disable max-len */
const LevelCircularBar = ({ level, language }) => {
  const levelsCircularBarClass = language === 'ar' ? 'levels-circular-bar-rtl' : 'levels-circular-bar';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45.123"
      height="45.063"
      viewBox="0 0 45.123 45.063"
      className={levelsCircularBarClass}
    >
      <g transform="translate(-23.939 -88)">
        <path
          className={getLevelColor(1, level)}
          d="M231.371,593.346A18.7,18.7,0,0,1,247.1,581.929a.615.615,0,0,0,.572-.61v-2.63a.627.627,0,0,0-.663-.626,22.574,22.574,0,0,0-19.28,14,.627.627,0,0,0,.39.824l2.5.812A.614.614,0,0,0,231.371,593.346Z"
          transform="translate(-202.128 -490.063)"
        />
        <path
          className={getLevelColor(2, level)}
          d="M234.929,618.386l1.534-2.111a.614.614,0,0,0-.1-.83,18.668,18.668,0,0,1-6.519-14.189,18.467,18.467,0,0,1,.518-4.3.616.616,0,0,0-.405-.734l-2.493-.81a.625.625,0,0,0-.8.437,22.539,22.539,0,0,0,7.366,22.656A.624.624,0,0,0,234.929,618.386Z"
          transform="translate(-202.061 -490.754)"
        />
        <path
          className={getLevelColor(3, level)}
          d="M259.536,618.308a.612.612,0,0,0-.821-.158,18.659,18.659,0,0,1-19.445,0,.612.612,0,0,0-.821.158l-1.539,2.118a.623.623,0,0,0,.167.894,22.56,22.56,0,0,0,23.83,0,.623.623,0,0,0,.167-.894Z"
          transform="translate(-202.492 -491.66)"
        />
        <path
          className={getLevelColor(4, level)}
          d="M268.606,596.219a.616.616,0,0,0-.405.734,18.468,18.468,0,0,1,.518,4.3,18.668,18.668,0,0,1-6.519,14.189.614.614,0,0,0-.1.83l1.534,2.111a.624.624,0,0,0,.9.116,22.538,22.538,0,0,0,7.366-22.656.625.625,0,0,0-.8-.437Z"
          transform="translate(-203.498 -490.754)"
        />
        <path
          className={getLevelColor(5, level)}
          d="M251.072,581.929A18.7,18.7,0,0,1,266.8,593.346a.614.614,0,0,0,.757.355l2.5-.812a.627.627,0,0,0,.39-.824,22.574,22.574,0,0,0-19.28-14,.627.627,0,0,0-.663.626v2.63A.615.615,0,0,0,251.072,581.929Z"
          transform="translate(-203.04 -490.063)"
        />
      </g>
    </svg>
  );
};
/* eslint-enable max-len */

LevelCircularBar.propTypes = {
  level: PropTypes.number,
  language: PropTypes.string,
};

LevelCircularBar.defaultProps = {
  level: 0,
  language: strings.getLanguage(),
};

export default connect((state) => ({
  language: getLanguage(state),
}))(LevelCircularBar);
