import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getUserAccount } from '@wfp/freerice-core/modules/user';
import { createStructuredSelector } from 'reselect';

const mapStateToProps = createStructuredSelector({ user: getUserAccount });

const PrivateArea = ({ history, user, component: WrappedComponent, fallback, ...props }) => {
  if (!user || !user.uuid) {
    history.replace(fallback);
  }
  return <WrappedComponent {...{ history, user, ...props }} />;
};

PrivateArea.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    uuid: PropTypes.string,
  }),
  component: PropTypes.func.isRequired,
  fallback: PropTypes.string.isRequired,
};

PrivateArea.defaultProps = {
  user: {},
};

const ConnectedPrivateArea = connect(mapStateToProps)(PrivateArea);

export default ({ fallback }) => WrappedComponent => props => (
  <ConnectedPrivateArea {...props} fallback={fallback} component={WrappedComponent} />
);
