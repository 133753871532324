import _defineProperty from "/home/vsts/work/1/s/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { createSelector } from 'reselect';
import { createAction, createRequestTypes } from '../utils/actionHelpers';
import { createApiCall } from '../utils/api'; // region ACTION TYPES

export var APP = {
  STARTING: 'APP_STARTING',
  STARTED: 'APP_STARTED',
  SHUTDOWN: 'APP_SHUTDOWN',
  LOAD_PREFERENCE_LANGUAGE: createRequestTypes('LOAD_PREFERENCE_LANGUAGE'),
  SET_PREFERENCE_LANGUAGE: 'APP_SET_LANGUAGE'
}; // endregion
// region ACTIONS

export var appStart = createAction(APP.STARTING);
export var appStarted = createAction(APP.STARTED);
export var appShutdown = createAction(APP.SHUTDOWN);
export var loadPreferenceLanguage = createApiCall(APP.LOAD_PREFERENCE_LANGUAGE, function () {
  return {
    url: '/language',
    method: 'GET'
  };
}, {
  apiType: 'engine'
});
export var setPreferenceLanguage = createAction(APP.SET_PREFERENCE_LANGUAGE); // endregion
// region REDUCER

var initialState = {
  bootstrapped: false
};
export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;

  var _ref = arguments.length > 1 ? arguments[1] : undefined,
      type = _ref.type,
      payload = _ref.payload;

  switch (type) {
    case APP.STARTED:
      return _objectSpread(_objectSpread({}, state), {}, {
        bootstrapped: true
      });

    case APP.SHUTDOWN:
      return _objectSpread(_objectSpread({}, state), {}, {
        bootstrapped: false
      });

    case APP.LOAD_PREFERENCE_LANGUAGE.SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        preferenceLanguage: payload.lang
      });

    case APP.SET_PREFERENCE_LANGUAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        preferenceLanguage: payload
      });

    default:
      return state;
  }
}); // endregion
// region SELECTORS

var appState = function appState(state) {
  return state.app;
};

export var isAppLoaded = createSelector(appState, function (state) {
  return state.bootstrapped;
});
export var getLanguage = createSelector(appState, function (state) {
  return state.preferenceLanguage;
}); // endregion