import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BoxInfoTitle from 'components/pages/common/box/BoxInfoTitle';
import { withTabOrder, TAB_NAMES } from 'components/app/TabOrder';
import './IconList.scss';

class IconList extends Component {
  static propTypes = {
    handlerItemClick: PropTypes.func.isRequired,
    avatars: PropTypes.array,
    icons: PropTypes.array,
    handlerCancel: PropTypes.func.isRequired,
    isVisible: PropTypes.bool,
    title: PropTypes.string.isRequired,
    tabOrder: PropTypes.object.isRequired,
    addFocus: PropTypes.func.isRequired,
  };

  static defaultProps = {
    avatars: [],
    icons: [],
    isVisible: false,
  };

  inputRefs = [];

  renderAvatars() {
    const { handlerItemClick, avatars, isVisible, tabOrder } = this.props;
    return avatars.map((avatar, index) => {
      if (!this.inputRefs[index]) {
        this.inputRefs[index] = React.createRef();
      }

      return (
        <div
          tabIndex={isVisible ? tabOrder.ICON_LIST_START + index : -1}
          key={`icon-number-${avatar}`}
          onKeyPress={(e) => e.charCode === 13 && handlerItemClick({ target: this.inputRefs[index].current }, true)}
        >
          <label
            tabIndex={-1}
            className={`icon-item ${avatar}`}
            htmlFor={`icon-${avatar}`}
          >
            <input
              ref={this.inputRefs[index]}
              id={`icon-${avatar}`}
              type="radio"
              name="icon-input"
              value={avatar}
              onClick={handlerItemClick}
            />
          </label>
        </div>
      );
    });
  }

  renderGroupIcons() {
    const { handlerItemClick, icons, isVisible, tabOrder } = this.props;
    return icons.map((icon, index) => (
      <label
        tabIndex={isVisible ? tabOrder.ICON_LIST_START + index : -1}
        key={`icon-number-${icon}`}
        className={`icon-item ${icon}`}
        htmlFor={`icon-${icon}`}
        onKeyPress={(e) => e.charCode === 13 && handlerItemClick(e)}
      >
        <input
          id={`icon-${icon}`}
          type="radio"
          name="icon-input"
          value={icon}
          onClick={handlerItemClick}
        />
      </label>
    ));
  }

  render() {
    const {
      handlerCancel, isVisible, title, avatars, icons, tabOrder, addFocus,
    } = this.props;
    return (
      <div className={classNames('icon-background', { visible: isVisible }, { invisible: !isVisible })}>
        <div className="icons-container">
          <label
            ref={elem => addFocus(TAB_NAMES.ICON_LIST_CLOSE_BUTTON, () => {
              if (elem) elem.focus();
            })}
            tabIndex={isVisible ? tabOrder.ICON_LIST_CLOSE_BUTTON : -1}
            className="btn modal-close"
            htmlFor="modal-toggle"
            onClick={handlerCancel}
            onKeyPress={(e) => e.charCode === 13 && handlerCancel(e, true)}
          >
            <input id="modal-toggle" type="checkbox" />
            &#x2715;
          </label>
          <BoxInfoTitle className="icons-title" text={title} />
          <div className="icon-list">
            {avatars && this.renderAvatars()}
            {icons && this.renderGroupIcons()}
          </div>
        </div>
      </div>
    );
  }
}

export default withTabOrder(IconList);
