/* eslint-disable max-len,react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getCookie from '@wfp/freerice-core/utils/getCookie';
import { getLanguage } from '@wfp/freerice-core/modules/app';
import {
  getChallenges,
  loadChallenges,
  loadChallenge,
  loadUserContributions,
  getUserContributions,
} from '@wfp/freerice-core/modules/challenges';
import { getUserAccount } from '@wfp/freerice-core/modules/user';

import ChallengeItem, { CHALLENGE_ITEM_SHOW_MODES } from 'components/pages/challenges/ChallengeItem';

import './ChallengesContent.scss';

const ChallengesContent = ({ challenges, user, userContributions, loadChallenge, loadChallenges, loadUserContributions, language }) => {
  const [anonymousJoinedChallenges, setAnonymousJoinedChallenges] = useState([]);
  const isUserAnonymous = !(user && user.uuid);

  useEffect(() => {
    loadChallenges({ lang: language });
    if (!isUserAnonymous) {
      loadUserContributions(user.uuid);
    }
  }, [loadChallenges, loadUserContributions, user]);

  useEffect(() => {
    const challenges = getCookie('challenges');
    setAnonymousJoinedChallenges(challenges.split(','));
  }, []);

  useEffect(() => {
    anonymousJoinedChallenges.forEach((id) => {
      loadChallenge({ id });
    });
  }, [anonymousJoinedChallenges]);

  const isAnonymousJoinedChallenge = (id) => !!lodash.find(anonymousJoinedChallenges, (c) => c === id);
  const loggedInUserContribute = (id) => lodash.find(userContributions, (c) => c.challenge_id === id);

  return (
    <div className="challenge-content--container">
      {challenges.filter((challenge) => challenge.public || !!loggedInUserContribute(challenge.id) || isAnonymousJoinedChallenge(challenge.id)).map((challenge) => (
        <ChallengeItem
          key={challenge.id}
          id={challenge.id}
          showMode={CHALLENGE_ITEM_SHOW_MODES.TEASER}
          userContribution={loggedInUserContribute(challenge.id)}
          attributes={challenge}
        />
      ))}
    </div>
  );
};

ChallengesContent.propTypes = {
  language: PropTypes.string.isRequired,
  challenges: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  userContributions: PropTypes.array.isRequired,
  loadChallenges: PropTypes.func.isRequired,
  loadChallenge: PropTypes.func.isRequired,
  loadUserContributions: PropTypes.func.isRequired,
};

ChallengesContent.defaultProps = {
};

export default connect(
  createStructuredSelector({
    user: getUserAccount,
    challenges: getChallenges,
    userContributions: getUserContributions,
    language: getLanguage,
  }),
  { loadChallenge, loadChallenges, loadUserContributions },
)(ChallengesContent);
