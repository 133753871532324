/* eslint-disable camelcase, max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import lodash from 'lodash';
import { createStructuredSelector } from 'reselect';
import getCookie from '@wfp/freerice-core/utils/getCookie';

import {
  getChallenges,
  loadChallenges,
  loadChallenge,
  loadUserContributions,
  getUserContributions,
  joinChallenge,
  leaveChallenge,
  isChallengesLoading,
  isUserContributionsLoading,
} from '@wfp/freerice-core/modules/challenges';
import { getUserAccount } from '@wfp/freerice-core/modules/user';

import ChallengeItem, { CHALLENGE_ITEM_SHOW_MODES } from 'components/pages/challenges/ChallengeItem';
import BackButton from 'components/app/BackButton';

import strings from 'res/strings';
import './ChallengeContent.scss';

const ChallengeContent = ({ id, challenges, user, userContributions, loadChallenge, loadChallenges, loadUserContributions, isChallengesLoading, isUserContributionsLoading, joinChallenge, leaveChallenge }) => {
  const [challenge, setChallenge] = useState(null);
  const [userContribution, setUserContribution] = useState(null);

  useEffect(() => {
    loadChallenge({ id });

    if (user && user.uuid) {
      loadUserContributions(user.uuid);
    }
  }, [loadChallenges, loadUserContributions, user, id]);

  useEffect(() => {
    setChallenge(challenges.find(challenge => challenge.id === id));
  }, [challenges]);

  useEffect(() => {
    if (user && user.uuid) {
      setUserContribution(userContributions.find(contribution => contribution.challenge_id === id));
    } else {
      // Anonymous flow.
      const challenges = getCookie('challenges');
      if (challenges && lodash.find(challenges.split(','), (c) => c === id)) {
        const contribution = getCookie(`challenge_${id}`);
        const rice = Number(contribution.split('.')[0]) || 0;
        setUserContribution({ rice });
      }
    }
  }, [id, user, isChallengesLoading, userContributions]);

  const handleActionClick = async () => {
    if (userContribution) {
      leaveChallenge(id);
    } else {
      joinChallenge(id);
    }
  };

  if (!challenge) {
    return (
      <></>
    );
  }

  return (
    <div className="challenge-content--container">
      <ChallengeItem
        id={challenge.id}
        attributes={challenge}
        showMode={CHALLENGE_ITEM_SHOW_MODES.FULL}
        userContribution={userContribution}
        user={user}
        handleActionClick={handleActionClick}
        isChallengesLoading={isChallengesLoading}
        isUserContributionsLoading={isUserContributionsLoading}
      />
      {(user && user.uuid) ? (
        <BackButton to="/challenges" text={strings.back_to_challenges} className="challenge-content--back-button" />
      ) : (!!userContribution && (
        <BackButton to="/categories" text={strings['challenges.chooseCategory']} className="challenge-content--back-button" />
      ))}
    </div>
  );
};

ChallengeContent.propTypes = {
  id: PropTypes.string.isRequired,
  challenges: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  userContributions: PropTypes.array.isRequired,
  loadChallenge: PropTypes.func.isRequired,
  loadChallenges: PropTypes.func.isRequired,
  loadUserContributions: PropTypes.func.isRequired,
  joinChallenge: PropTypes.func.isRequired,
  leaveChallenge: PropTypes.func.isRequired,
  isChallengesLoading: PropTypes.bool.isRequired,
  isUserContributionsLoading: PropTypes.bool.isRequired,
};

ChallengeContent.defaultProps = {
};


export default connect(
  createStructuredSelector({
    user: getUserAccount,
    challenges: getChallenges,
    userContributions: getUserContributions,
    isChallengesLoading,
    isUserContributionsLoading,
  }),
  { loadChallenge, loadChallenges, loadUserContributions, joinChallenge, leaveChallenge },
)(ChallengeContent);
