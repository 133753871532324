import { createSelector } from 'reselect';
import { produce } from 'immer';
import { createRequestTypes, createAction } from '../utils/actionHelpers';
import { createApiCall } from '../utils/api'; // region ACTION TYPES

var base = 'LEADERBOARD';
export var LEADERBOARD = {
  LOAD: createRequestTypes("".concat(base, "_LOAD")),
  LOAD_GLOBAL_STATS: createRequestTypes("".concat(base, "_LOAD_GLOBAL_STATS"))
}; // endregion
// region ACTIONS

export var loadLeaderboard = createAction(LEADERBOARD.LOAD.REQUEST);
export var loadGlobalStats = createApiCall(LEADERBOARD.LOAD_GLOBAL_STATS, '/gamestats/rice-totals'); // endregion
// region REDUCER

var initialState = {
  users: null,
  groups: null,
  loading: false,
  loadingGlobal: false,
  error: null,
  global: {},
  ricePerAnswer: 5
};
export default produce(function (draft, _ref) {
  var type = _ref.type,
      payload = _ref.payload;

  switch (type) {
    case LEADERBOARD.LOAD.REQUEST:
      draft.loading = true;
      break;

    case LEADERBOARD.LOAD.SUCCESS:
      draft.loading = false;
      draft.users = payload.users;
      draft.groups = payload.groups;
      break;

    case LEADERBOARD.LOAD_GLOBAL_STATS.REQUEST:
      draft.loadingGlobal = true;
      break;

    case LEADERBOARD.LOAD_GLOBAL_STATS.SUCCESS:
      draft.global = payload;
      draft.ricePerAnswer = payload.answer || 5;
      draft.loadingGlobal = false;
      break;

    case LEADERBOARD.LOAD_GLOBAL_STATS.FAILURE:
      draft.loadingGlobal = false;
      break;

    case LEADERBOARD.LOAD.FAILURE:
      draft.loading = false;
      draft.error = payload.message;
      break;

    default:
      break;
  }
}, initialState); // endregion
// region SELECTORS

var leaderboardState = function leaderboardState(state) {
  return state.leaderboard;
};

export var getUsersRanking = createSelector(leaderboardState, function (state) {
  return state.users;
});
export var getGroupsRanking = createSelector(leaderboardState, function (state) {
  return state.groups;
});
export var isLeaderboardLoading = createSelector(leaderboardState, function (state) {
  return state.loading;
});
export var isGlobalLoading = createSelector(leaderboardState, function (state) {
  return state.loadingGlobal;
});
export var getGlobalStats = createSelector(leaderboardState, function (state) {
  return state.global;
});
export var getYesterdayRice = createSelector(getGlobalStats, function (_ref2) {
  var week = _ref2.week;

  if (!week) {
    return null;
  }

  var date = new Date();
  date.setDate(date.getDate() - 1);
  var weekday = date.toLocaleDateString('en-US', {
    weekday: 'short'
  });

  if (weekday && weekday in week) {
    return week[weekday];
  }

  return null;
});
export var getRicePerAnswer = createSelector(leaderboardState, function (state) {
  return state.ricePerAnswer;
}); // endregion