import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Input.scss';

const Input = ({
  label,
  placeholder,
  type,
  id,
  name,
  isChecked,
  isInputError,
  handlerChange,
  value,
  tabIndex,
  disabled,
}) => (
  <>
    {label && <label htmlFor={name || type}>{label}</label>}
    <input
      tabIndex={tabIndex}
      id={id}
      type={type}
      name={name || type}
      onChange={handlerChange}
      placeholder={placeholder}
      defaultChecked={isChecked}
      value={value}
      disabled={disabled}
      className={classNames({ 'input-error': isInputError })}
    />
  </>
);

Input.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  isInputError: PropTypes.bool,
  handlerChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  tabIndex: PropTypes.number,
  disabled: PropTypes.bool,
};

Input.defaultProps = {
  label: '',
  placeholder: '',
  type: 'text',
  id: null,
  isChecked: false,
  isInputError: false,
  tabIndex: -1,
  disabled: false,
};

export default Input;
