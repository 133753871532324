import React from 'react';
import PropTypes from 'prop-types';
import BoxInfoTitle from 'components/pages/common/box/BoxInfoTitle';
import BoxInfoProgressBar from 'components/pages/common/box/BoxInfoProgressBar';
import strings from 'res/strings';
import { withTabOrder } from 'components/app/TabOrder';
import RegistrationButton from './RegistrationButton';

const SignUp = ({ loading, tabOrder }) => (
  <>
    <BoxInfoTitle text={strings['login.title.or']} />
    <BoxInfoProgressBar isVisible={loading}>
      <RegistrationButton tabIndex={tabOrder.LOGIN_REGISTRATION_BUTTON} />
    </BoxInfoProgressBar>
  </>
);

SignUp.propTypes = {
  loading: PropTypes.bool.isRequired,
  tabOrder: PropTypes.object.isRequired,
};

export default withTabOrder(SignUp);
