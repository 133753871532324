import React from 'react';
import PropTypes from 'prop-types';
import './SignOutButton.scss';

import strings from 'res/strings';

const SignOutButton = ({ handlerClick, tabIndex }) => (
  <button
    tabIndex={tabIndex}
    type="button"
    className="sign-out"
    onClick={handlerClick}
  >
    {strings['profile.button.signOut']}
  </button>
);

SignOutButton.propTypes = {
  handlerClick: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
};

export default SignOutButton;
