import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLanguage } from '@wfp/freerice-core/modules/app';
import { getEyeCatchers } from '@wfp/freerice-core/modules/eyeCatchers';

import './MigrateTotalsBlock.scss';

import strings from 'res/strings';
import { withTabOrder } from 'components/app/TabOrder';

const MigrateTotalsBlock = ({ language, tabOrder, eyeCatchers }) => {
  const [isOpen, setOpen] = useState(true);
  const eyeCatcher = eyeCatchers[0];
  const migrateTotalsBlockClass = language === 'ar' ? 'migrate-totals-block-rtl' : 'migrate-totals-block';

  useEffect(() => {
    if (eyeCatcher) {
      const isOpen = localStorage.getItem(`isMigrateTotalsBlockOpen_${eyeCatcher.id}`);

      if (isOpen !== null) {
        setOpen(JSON.parse(isOpen));
      }
    }
  }, [eyeCatcher]);

  const onClose = (eyeCatcher) => {
    setOpen(false);
    localStorage.setItem(`isMigrateTotalsBlockOpen_${eyeCatcher.id}`, JSON.stringify(false));
  };


  if (!eyeCatcher || !isOpen) return null;

  return (
    <div className={migrateTotalsBlockClass}>
      <div className={`${migrateTotalsBlockClass}__wrapper`}>
        <p className={`${migrateTotalsBlockClass}__message`}>{eyeCatcher.title}</p>
        <p className={`${migrateTotalsBlockClass}__message`}>{eyeCatcher.body}</p>
        <div className={`${migrateTotalsBlockClass}__row`}>
          <div
            tabIndex={tabOrder.MIGRATE_TOTAL_BLOCK_ACTION_BUTTON}
            onKeyPress={() => {
              window.location.href = eyeCatcher.button_link;
            }}
          >
            <a
              tabIndex={-1}
              className={`${migrateTotalsBlockClass}__button`}
              href={eyeCatcher.button_link}
              rel="noopener noreferrer"
            >
              {eyeCatcher.button_text}
            </a>
          </div>
          <div
            tabIndex={tabOrder.MIGRATE_TOTAL_BLOCK_CLOSE_IT}
            onKeyPress={() => onClose(eyeCatcher)}
          >
            <button
              tabIndex={-1}
              type="button"
              className={`${migrateTotalsBlockClass}__text-link`}
              onClick={() => onClose(eyeCatcher)}>
              {strings['migrateTotalBlock.web.closeIt']}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

MigrateTotalsBlock.propTypes = {
  language: PropTypes.string,
  eyeCatchers: PropTypes.object.isRequired,
  tabOrder: PropTypes.object.isRequired,
};

MigrateTotalsBlock.defaultProps = {
  language: strings.getLanguage(),
};

export default withTabOrder(connect((state) => ({
  language: getLanguage(state),
  eyeCatchers: getEyeCatchers(state),
}))(MigrateTotalsBlock));
