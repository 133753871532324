import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { getLanguage } from '@wfp/freerice-core/modules/app';

import strings from 'res/strings';

const LeaderboardItem = ({ avatar, type, name, rank, rice, className, language, tabIndex }) => {
  const listItemClass = language === 'ar' ? 'list-item-rtl' : 'list-item';

  return (
    <div tabIndex={tabIndex} className={classNames(`${listItemClass} ${listItemClass}--${type}`, className)}>
      <span className={`${listItemClass}--rank`}>{rank && rank.toLocaleString()}</span>
      <span className={`${listItemClass}--avatar avatar-${avatar}`} />
      <div className={`${listItemClass}--content`}>
        <div className={`${listItemClass}--name`}>{name}</div>
        <div className={`${listItemClass}--rice`}>{(rice || 0).toLocaleString()}</div>
      </div>
    </div>
  );
};

LeaderboardItem.propTypes = {
  avatar: PropTypes.string,
  type: PropTypes.oneOf(['users', 'groups']).isRequired,
  name: PropTypes.string,
  rank: PropTypes.number,
  rice: PropTypes.number,
  className: PropTypes.string,
  language: PropTypes.string,
  tabIndex: PropTypes.number.isRequired,
};

LeaderboardItem.defaultProps = {
  avatar: 'default',
  className: '',
  name: '',
  rank: null,
  rice: null,
  language: strings.getLanguage(),
};

export default connect((state) => ({
  language: getLanguage(state),
}))(LeaderboardItem);
