/* eslint-disable camelcase, max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { getLanguage } from '@wfp/freerice-core/modules/app';
import { addCommasToNumbersInText } from '@wfp/freerice-core/utils/parsing';
import moment from 'moment';
import { truncate } from 'lodash';

import BoxInfo from 'components/pages/common/box/BoxInfo';

import './FeedItem.scss';

const FeedItem = ({ id, icon, title, message, body, image, date, call_to_action, call_to_action_label, language }) => {
  const [isFullView, setFullView] = useState(false);

  const toggleFullView = () => {
    setFullView(!isFullView);
  };

  const callAction = () => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'feed_click', { link: call_to_action, label: call_to_action_label });
    }
    window.open(call_to_action, '_blank').focus();
  };

  const feedItemClass = language === 'ar' ? 'feed-item-rtl' : 'feed-item';
  const truncatedBody = truncate(body, { length: 90, omission: isFullView ? '' : '...', separator: ' ' });
  const secondPartOfTruncatedBody = body.substr(truncatedBody.length);

  return (
    <CSSTransition in={isFullView} timeout={500} classNames={`${feedItemClass}-layout`}>
      <div>
        <BoxInfo type="medium-width" className={`${feedItemClass} ${feedItemClass}--container`}>
          <div className={`${feedItemClass} ${feedItemClass}--left`}>
            <div className={`${feedItemClass}--header-block`}>
              <div className={`${feedItemClass}--header-block--icon`} />
              <span>{moment(date).format('DD-MM-YYYY')}</span>
            </div>
            <div className={`${feedItemClass}--content-block`}>
              {icon === 'rice-bowl' && <div className={`${feedItemClass}--content-block--icon`} />}
              <div>
                <h2>{addCommasToNumbersInText(title)}</h2>
                <p>{addCommasToNumbersInText(message || truncatedBody)}</p>

                {!!body && secondPartOfTruncatedBody && (
                  <>
                    <p className={`${feedItemClass}--content-block--full-view`}>
                      {message ? body : secondPartOfTruncatedBody}
                    </p>
                    <div className={`${feedItemClass}--content-block--extended-button`} onClick={toggleFullView}>
                      {!isFullView ? 'Read More' : 'Read Less'}
                    </div>
                  </>
                )}
                {!!call_to_action && !!call_to_action_label && (
                  <div onClick={callAction} className={`${feedItemClass}--content-block--call-action-button`}>
                    {call_to_action_label}
                  </div>
                )}
              </div>
            </div>
          </div>
          {!!image && (
            <div className={`${feedItemClass}--image-container`}>
              <style>
                {`
                .${feedItemClass}-${id}--image {
                  background-image: url(${image})!important;
                }
                `}
              </style>
              <div className={`${feedItemClass}--image ${feedItemClass}-${id}--image`} />
            </div>
          )}
        </BoxInfo>
        <div onClick={toggleFullView} className={`${feedItemClass}-overlay`} />
      </div>
    </CSSTransition>
  );
};

FeedItem.propTypes = {
  language: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
  message: PropTypes.string,
  image: PropTypes.string,
  date: PropTypes.string,
  call_to_action: PropTypes.string,
  call_to_action_label: PropTypes.string,
};

FeedItem.defaultProps = {
  body: '',
  message: '',
  icon: null,
  image: null,
  date: null,
  call_to_action: '',
  call_to_action_label: '',
};

export default connect((state) => ({
  language: getLanguage(state),
}))(FeedItem);
