import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLanguage } from '@wfp/freerice-core/modules/app';
import './Achievement.scss';
import strings from 'res/strings';
import AchievementIcon from './AchievementIcon';


const Achievement = ({ data, isAchieved, language }) => {
  const achievementClass = language === 'ar' ? 'achievement-rtl' : 'achievement';

  return (
    <li className={achievementClass} style={{ opacity: isAchieved ? 1 : 0.5 }}>
      <AchievementIcon imageUri={data.asset_uri} />
      <div className={`${achievementClass}-content`}>
        <span className={`${achievementClass}-label`}>{data.label}</span>
        <span className={`${achievementClass}-description`}>{data.description}</span>
      </div>
    </li>
  );
};

Achievement.propTypes = {
  data: PropTypes.object.isRequired,
  isAchieved: PropTypes.bool,
  language: PropTypes.string,
};

Achievement.defaultProps = {
  isAchieved: false,
  language: strings.getLanguage(),
};

export default connect((state) => ({
  language: getLanguage(state),
}))(Achievement);
