import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import CardButton from 'components/pages/game/CardButton';
import CardTitle from 'components/pages/game/CardTitle';
import { withTabOrder } from 'components/app/TabOrder';
import QuestionCard from '../QuestionCard';
import ExpandButton from './ExpandButton';

const getOptionState = (isSelected, validatedAnswer, isCorrectOption, isCorrect) => {
  if (validatedAnswer) {
    if (isSelected && !isCorrect) {
      return 'WRONG';
    }
    if (isCorrectOption) {
      return 'CORRECT';
    }
  }

  return isSelected ? 'SELECTED' : undefined;
};

class QuestionSingle extends PureComponent {
  static propTypes = {
    answer: PropTypes.shape({
      options: PropTypes.arrayOf(PropTypes.string),
      correct: PropTypes.bool,
    }),
    question: PropTypes.shape({
      options: PropTypes.array.isRequired,
      text: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      resources: PropTypes.array,
    }).isRequired,
    selection: PropTypes.string,
    onAnswer: PropTypes.func.isRequired,
    delay: PropTypes.number,
    tabOrder: PropTypes.object.isRequired,
  };

  static defaultProps = {
    answer: { options: [], correct: false },
    selection: null,
    delay: 100,
  };

  state = { openedImage: false };

  imageRef = React.createRef();

  handleAnswerSelect = (e, focusQuestionAfterReload = false) => {
    const { onAnswer } = this.props;
    const answer = e.target.getAttribute('data-target');
    onAnswer(answer, focusQuestionAfterReload);
  };

  toggleImage = () => {
    this.setState(({ openedImage }) => ({ openedImage: !openedImage }));
  };

  imageLoaded = () => this.setState({ imageHeight: this.imageRef.current.naturalHeight });

  renderImage() {
    const { question } = this.props;
    const { imageHeight, openedImage } = this.state;

    return (
      <div
        className="question-image"
        onClick={this.toggleImage}
        style={{ height: `${imageHeight + 50}px`, maxHeight: `${imageHeight + 50}px` }}>
        {(question.resources || []).map(res => (
          <React.Fragment key={res.url}>
            <img
              src={res.url && !res.url.includes('://') ? `https://beta.freerice.com/${res.url}` : res.url}
              alt={question.text}
              ref={this.imageRef}
              onLoad={this.imageLoaded}
            />
            <ExpandButton onClick={this.toggleImage} openedImage={openedImage} />
          </React.Fragment>
        ))}
      </div>
    );
  }

  render() {
    const {
      question: { text, options, id: questionId, resources },
      selection,
      answer: { correct, options: correctOptions = [], questionId: answerQuestionId },
      delay,
      tabOrder,
    } = this.props;
    const { openedImage } = this.state;
    const hasResources = Array.isArray(resources) && resources.length;

    return (
      <CSSTransition in={openedImage} timeout={500} classNames="full-image">
        <div>
          {hasResources && this.renderImage()}
          <QuestionCard id={questionId} timeout={delay}>
            <CardTitle text={text} />
            <TransitionGroup appear exit={false} component={null}>
              {options.map((answer, index) => (
                <CSSTransition key={answer.id} timeout={100 * index + delay} classNames="fade" mountOnEnter>
                  <CardButton
                    tabIndex={tabOrder[`QUESTION_ANSWER_${index + 1}`]}
                    onClick={this.handleAnswerSelect}
                    buttonId={answer.id}
                    keyboardKeyBind={String(index + 1)}
                    state={getOptionState(
                      selection === answer.id,
                      answerQuestionId === questionId,
                      correctOptions.includes(answer.id),
                      correct
                    )}
                    text={answer.text}
                    disabled={!!selection}
                  />
                </CSSTransition>
              ))}
            </TransitionGroup>
          </QuestionCard>
        </div>
      </CSSTransition>
    );
  }
}

export default withTabOrder(QuestionSingle);
