import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { uniq, indexOf } from 'lodash';
import { withTabOrder, TAB_NAMES } from 'components/app/TabOrder';

import ToolBar from 'components/app/ToolBar';
import SideDrawer from 'components/app/SideDrawer';
import Backdrop from 'components/app/Backdrop';
import Footer from 'components/app/Footer';
import Main from 'components/app/Main';

import { isAppLoaded, getLanguage } from '@wfp/freerice-core/modules/app';
import { getUserAccount } from '@wfp/freerice-core/modules/user';
import { getGameCategory } from '@wfp/freerice-core/modules/game';
import { getAnnouncements } from '@wfp/freerice-core/modules/activityFeed';

import { withNotification, LARGE_ANNOUNCEMENT } from 'components/app/Notification';

import strings from 'res/strings';

class App extends Component {
  static propTypes = {
    user: PropTypes.object,
    category: PropTypes.object,
    appLoaded: PropTypes.bool.isRequired,
    language: PropTypes.string,
    focusTo: PropTypes.func.isRequired,
    announcements: PropTypes.array,
    setNotificationVisibility: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: null,
    category: null,
    language: strings.getLanguage(),
    announcements: [],
  };

  state = { sideDrawerOpen: false };

  componentDidUpdate(prevProps) {
    const { announcements, setNotificationVisibility } = this.props;
    if (!!announcements && announcements !== prevProps.announcements) {
      const latestAnnouncement = announcements[0];

      if (latestAnnouncement && latestAnnouncement.promote_to_front_page) {
        const readedAnnouncements = JSON.parse(window.localStorage.getItem('readedAnnouncements')) || [];

        if (indexOf(readedAnnouncements, latestAnnouncement.date) < 0) {
          window.localStorage.setItem(
            'readedAnnouncements',
            JSON.stringify(uniq([...readedAnnouncements, latestAnnouncement.date])),
          );
          setNotificationVisibility(LARGE_ANNOUNCEMENT, true, latestAnnouncement);
        }
      }
    }
  }

  drawerToggleClickHandler = ({ focusAfter }) => {
    const { focusTo } = this.props;

    this.setState(prevState => ({ sideDrawerOpen: !prevState.sideDrawerOpen }), () => {
      if (this.state.sideDrawerOpen && focusAfter) focusTo(TAB_NAMES.SIDE_DRAWER_CLOSE);
    });
  };

  handleSideDrawerClose = ({ focusAfter }) => {
    const { focusTo } = this.props;

    this.setState({ sideDrawerOpen: false });

    if (focusAfter) focusTo(TAB_NAMES.MENU_TOGGLE);
  };

  render() {
    const { sideDrawerOpen } = this.state;
    const { appLoaded, user, category, language } = this.props;
    strings.setLanguage(language);

    return (
      <>
        <ToolBar handlerDrawerClick={this.drawerToggleClickHandler} user={user} />
        <SideDrawer user={user} show={sideDrawerOpen} closeDrawer={this.handleSideDrawerClose} />
        {!!appLoaded && <Main category={category} />}
        <Footer />
        {sideDrawerOpen && <Backdrop handlerClick={this.handleSideDrawerClose} />}
      </>
    );
  }
}

export default withTabOrder(withNotification(withRouter(connect(state => ({
  appLoaded: isAppLoaded(state),
  user: getUserAccount(state),
  category: getGameCategory(state),
  language: getLanguage(state),
  announcements: getAnnouncements(state),
}))(App))));
