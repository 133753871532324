import { createSelector } from 'reselect';
import { produce } from 'immer';
import { createAction, createRequestTypes } from '../utils/actionHelpers';
import { createApiCall } from '../utils/api'; // region ACTION TYPES

var base = 'EYE_CATCHERS';
export var EYE_CATCHERS = {
  LIST_EYE_CATCHERS: createRequestTypes("".concat(base, "_LIST_EYE_CATCHERS")),
  CLEAR_ERRORS: "".concat(base, "_CLEAR_ERRORS")
}; // endregion
// region ACTIONS

export var loadEyeCatchers = createApiCall(EYE_CATCHERS.LIST_EYE_CATCHERS, function (_ref) {
  var lang = _ref.lang;
  return {
    url: '/api/eye-catcher',
    params: {
      _lang: lang
    }
  };
}, {
  apiType: 'accounts'
});
export var clearErrors = createAction(EYE_CATCHERS.CLEAR_ERRORS); // endregion
// region REDUCER

var initialState = {
  eyeCatchers: [],
  loading: false,
  errors: null
};
export default produce(function (draft, _ref2) {
  var type = _ref2.type,
      payload = _ref2.payload;

  switch (type) {
    case EYE_CATCHERS.LIST_EYE_CATCHERS.REQUEST:
      draft.loading = true;
      break;

    case EYE_CATCHERS.LIST_EYE_CATCHERS.SUCCESS:
      draft.loading = false;
      draft.eyeCatchers = payload || [];
      break;

    case EYE_CATCHERS.LIST_EYE_CATCHERS.FAILURE:
      draft.loading = false;
      draft.errors = payload.message;
      break;

    case EYE_CATCHERS.CLEAR_ERRORS:
      draft.errors = null;
      break;

    default:
      break;
  }
}, initialState); // endregion
// region SELECTORS

var eyeCatchersState = function eyeCatchersState(state) {
  return state.eyeCatchers;
};

export var getEyeCatchers = createSelector(eyeCatchersState, function (state) {
  return state.eyeCatchers;
});
export var isEyeCatchersLoading = createSelector(eyeCatchersState, function (state) {
  return state.loading;
});
export var getEyeCatchersErrors = createSelector(eyeCatchersState, function (state) {
  return state.errors;
}); // endregion