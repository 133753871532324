/* eslint-disable camelcase,max-len */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Accumulation_10000 } from '../../../../../res/images/profile/achievements/accumulation_10000.svg';
import { ReactComponent as Persistence_15d } from '../../../../../res/images/profile/achievements/persistence_15d.svg';
import { ReactComponent as Streak_25 } from '../../../../../res/images/profile/achievements/streak_25.svg';
import { ReactComponent as Streak_100 } from '../../../../../res/images/profile/achievements/streak_100.svg';
import { ReactComponent as Streak_175 } from '../../../../../res/images/profile/achievements/streak_175.svg';
import { ReactComponent as Accumulation_1000 } from '../../../../../res/images/profile/achievements/accumulation_1000.svg';
import { ReactComponent as Persistence_30i } from '../../../../../res/images/profile/achievements/persistence_30i.svg';
import { ReactComponent as Accumulation_5000 } from '../../../../../res/images/profile/achievements/accumulation_5000.svg';
import { ReactComponent as Persistence_5d } from '../../../../../res/images/profile/achievements/persistence_5d.svg';
import { ReactComponent as Streak_10 } from '../../../../../res/images/profile/achievements/streak_10.svg';
import { ReactComponent as Streak_75 } from '../../../../../res/images/profile/achievements/streak_75.svg';
import { ReactComponent as Streak_200 } from '../../../../../res/images/profile/achievements/streak_200.svg';
import { ReactComponent as Streak_125 } from '../../../../../res/images/profile/achievements/streak_125.svg';
import { ReactComponent as Streak_150 } from '../../../../../res/images/profile/achievements/streak_150.svg';
import { ReactComponent as Accumulation_2000 } from '../../../../../res/images/profile/achievements/accumulation_2000.svg';
import { ReactComponent as Streak_50 } from '../../../../../res/images/profile/achievements/streak_50.svg';
import { ReactComponent as Persistence_2h } from '../../../../../res/images/profile/achievements/persistence_2h.svg';
import { ReactComponent as Persistence_10d } from '../../../../../res/images/profile/achievements/persistence_10d.svg';
import { ReactComponent as Persistence_1h } from '../../../../../res/images/profile/achievements/persistence_1h.svg';

const icons = {
  accumulation_10000: Accumulation_10000,
  persistence_15d: Persistence_15d,
  streak_25: Streak_25,
  streak_100: Streak_100,
  streak_175: Streak_175,
  accumulation_1000: Accumulation_1000,
  persistence_30i: Persistence_30i,
  accumulation_5000: Accumulation_5000,
  persistence_5d: Persistence_5d,
  streak_10: Streak_10,
  streak_75: Streak_75,
  streak_200: Streak_200,
  streak_125: Streak_125,
  streak_150: Streak_150,
  accumulation_2000: Accumulation_2000,
  streak_50: Streak_50,
  persistence_2h: Persistence_2h,
  persistence_10d: Persistence_10d,
  persistence_1h: Persistence_1h,
};

const AchievementIcon = ({ achievement, size, imageUri }) => {
  const Icon = useMemo(() => {
    if (achievement && achievement in icons) {
      return icons[achievement];
    }
    return null;
  }, [achievement]);

  if (imageUri) return <object data={imageUri} width={size} height={size} aria-label="" type="image/svg+xml" />;
  return Icon && <Icon width={size} height={size} />;
};

AchievementIcon.propTypes = {
  achievement: PropTypes.string,
  size: PropTypes.number,
  imageUri: PropTypes.string,
};

AchievementIcon.defaultProps = {
  size: 60,
  achievement: null,
  imageUri: null,
};

export default AchievementIcon;
