import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './CategoryItem.scss';

const CategoryItem = ({ data, isDisabled, selection, onClick, tabIndex }) => (
  <div
    tabIndex={tabIndex}
    onKeyPress={(e) => e.charCode === 13 && onClick(e)}
    data-target={data.id}
  >
    <div
      tabIndex={-1}
      className={classNames('category-item', {
        'category-item--selected': selection === data.id,
        'category-item--disabled': isDisabled,
      })}
      data-target={data.id}
      onClick={onClick}
    >
      <style>
        {`
        .category-image--${data.machine_name} {
          background-image: url(${data.icon_urls[0]})
        }

        @media (min-width: 992px) {
          .category-image--${data.machine_name} {
            background-image: url(${data.icon_urls[1] || data.icon_urls[0]})
          }
        }

        @media (min-width: 1600px) {
          .category-image--${data.machine_name} {
            background-image: url(${data.icon_urls[2] || data.icon_urls[1] || data.icon_urls[0]})
          }
        }
      `}
      </style>
      <div className={`category-image category-image--${data.machine_name}`} />
      <div className="category-text">
        <p>{data.label}</p>
      </div>
    </div>
  </div>
);

CategoryItem.propTypes = {
  isDisabled: PropTypes.bool,
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
  selection: PropTypes.string,
};

CategoryItem.defaultProps = {
  isDisabled: false,
  selection: null,
};
export default CategoryItem;
