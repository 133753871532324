import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import withPageTitle from 'containers/common/withPageTitle';
import BoxInfoImage from 'components/pages/common/box/BoxInfoImage';
import BackButton from 'components/app/BackButton';
import ProfileContent from 'components/pages/profile/profile/ProfileContent';

import {
  signOut,
  loadAccountData,
  clearUserErrors,
  getUserAccount,
  getUserToken,
  isLoadingUser,
} from '@wfp/freerice-core/modules/user';
import { getBadges } from '@wfp/freerice-core/modules/badges';
import { withTabOrder, tabOrders, TAB_NAMES } from 'components/app/TabOrder';
import strings from 'res/strings';

class Profile extends Component {
  static propTypes = {
    user: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      displayname: PropTypes.string,
    }).isRequired,
    isLoadingAccountData: PropTypes.bool.isRequired,
    badges: PropTypes.array,
    history: PropTypes.object.isRequired,
    clearUserErrors: PropTypes.func.isRequired,
    loadAccountData: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
    setTabOrder: PropTypes.func.isRequired,
    focusTo: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {
    badges: [],
  };

  componentDidMount() {
    const { user, history, loadAccountData, setTabOrder, focusTo, location } = this.props;

    setTabOrder(tabOrders.PROFILE);

    if (location.state && location.state.focusAfter) focusTo(TAB_NAMES.MENU_TOGGLE);

    if (!user.uuid) {
      history.push('/profile-login');
    } else {
      loadAccountData();
    }
  }

  componentWillUnmount() {
    const { clearUserErrors } = this.props;
    clearUserErrors();
  }

  signOutHandler = () => {
    const { history, signOut } = this.props;
    signOut();
    history.push('/profile-login');
  };

  editProfileHandler = () => {
    const { history } = this.props;
    history.push('/profile-edit');
  };

  render() {
    const { user, isLoadingAccountData, badges } = this.props;

    return (
      <BoxInfoImage imageType="profile-avatar" avatarId={user.avatar || ''}>
        <ProfileContent
          handlerEditProfile={this.editProfileHandler}
          handlerSignOut={this.signOutHandler}
          isLoadingAccountData={isLoadingAccountData}
          badges={badges}
          user={user}
        />
        <BackButton />
      </BoxInfoImage>
    );
  }
}

export default compose(
  withPageTitle({ title: strings.profile_link }),
  withTabOrder,
  connect(
    createStructuredSelector({
      user: getUserAccount,
      token: getUserToken,
      isLoadingAccountData: isLoadingUser,
      badges: getBadges,
    }),
    { signOut, loadAccountData, clearUserErrors }
  )
)(Profile);
