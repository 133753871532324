import React from 'react';
import PropTypes from 'prop-types';

import RoundBlock from 'components/app/RoundBlock';

import './GameStatistics.scss';
import AchievementIcon from 'components/pages/profile/profile/Achievement/AchievementIcon';

import strings from 'res/strings';

const GameStatistics = ({ user }) => (
  <RoundBlock title={strings['block.progress.title']} className="block--personal-stats">
    <p>
      {strings['stats.text.donated']}
      <br />
      <strong>{(user.rice || 0).toLocaleString('en-EN')}</strong>
    </p>
    {user.badges.length > 0 && (
      <p>
        {strings['block.progress.achievements']}
        <br />
        {user.badges.map(badge => (
          <AchievementIcon
            key={badge.id}
            className="badge"
            imageUri={badge.asset_uri}
            isActive
            size={28}
            title={badge.label}
          />
        ))}
      </p>
    )}
  </RoundBlock>
);

GameStatistics.propTypes = {
  user: PropTypes.object,
};

GameStatistics.defaultProps = { user: null };

export default GameStatistics;
