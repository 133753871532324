import React, { useCallback, useEffect } from 'react';
import withPageTitle from 'containers/common/withPageTitle';
import { useHistory } from 'react-router-dom';

import strings from 'res/strings';
import CitiSignupContent from 'components/pages/citi/SignUpContent';

const citiChallengeID = process.env.REACT_APP_CITI_CHALLENGE_ID;

const CitySignUpScreen = () => {
  const history = useHistory();

  useEffect(() => {
    const zipCode = localStorage.getItem('zipCode');
    if (zipCode && citiChallengeID) {
      history.replace(`/challenge/${citiChallengeID}`);
    }
  }, [history.replace]);

  const onZipSubmit = useCallback(({ zipCode }) => {
    localStorage.setItem('zipCode', zipCode);
    if (citiChallengeID) {
      history.replace(`/challenge/${citiChallengeID}`);
    }
  });
  return <CitiSignupContent onSubmit={onZipSubmit} />;
};

export default withPageTitle({ title: strings['citi_signup.page_title'] })(CitySignUpScreen);
