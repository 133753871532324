import React from 'react';
import PropTypes from 'prop-types';
import './BoxInfoError.scss';

const BoxInfoError = ({ messages }) => {
  if (!messages.length) {
    return null;
  }

  return (
    <div className="box-info-error">
      {messages.map(message => (
        <div key={message}>{message}</div>
      ))}
    </div>
  );
};

BoxInfoError.propTypes = {
  messages: PropTypes.array,
};

BoxInfoError.defaultProps = {
  messages: [],
};

export default BoxInfoError;
