/* eslint-disable camelcase, max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import './LargeAnnouncement.scss';

import strings from 'res/strings';
import { LARGE_ANNOUNCEMENT } from './Constants';

const LargeAnnouncement = ({ visible, params, setVisibility }) => {
  const { title, image, summary, call_to_action, call_to_action_label } = params;
  const closeAnnouncement = () => setVisibility(LARGE_ANNOUNCEMENT, false, params);

  const callAction = () => {
    window.open(call_to_action, '_blank').focus();
    setVisibility(LARGE_ANNOUNCEMENT, false, params);
  };

  return (
    <CSSTransition in={visible} timeout={500} classNames="slide-y">
      <div>
        <div className="announcement announcement--large">
          <div className="announcement--content">
            {!!image && (
              <style>
                {`
                .announcement--content--announcement-image {
                  background-image: url(${image})!important;
                }
                `}
              </style>
            )}

            {!!image && (
              call_to_action ? (
                <div onClick={callAction} className="announcement--content--announcement-image clickable" />
              ) : (
                <div className="announcement--content--announcement-image" />
              )
            )}

            <h2>{title}</h2>
            <p className="announcement--content--announcement-summary">{summary}</p>

            {!!call_to_action && !!call_to_action_label && (
              <div onClick={callAction} className="call-action-button clickable">
                {call_to_action_label}
              </div>
            )}
          </div>
          <div onClick={closeAnnouncement} className="close-button clickable">
            {strings.back_to_game}
          </div>
        </div>
        <div onClick={closeAnnouncement} className="announcement--large-overlay" />
      </div>
    </CSSTransition>
  );
};

LargeAnnouncement.propTypes = {
  visible: PropTypes.bool.isRequired,
  params: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.string,
    summary: PropTypes.string,
    call_to_action: PropTypes.string,
    call_to_action_label: PropTypes.string,
  }),
  setVisibility: PropTypes.func.isRequired,
};

LargeAnnouncement.defaultProps = {
  params: {
    image: '',
  },
};

export default LargeAnnouncement;
