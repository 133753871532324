import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLanguage } from '@wfp/freerice-core/modules/app';
import strings from 'res/strings';
import { withTabOrder } from 'components/app/TabOrder';

import LevelItem from './LevelItem';
import './LevelBar.scss';


class LevelBar extends Component {
  static propTypes = {
    levels: PropTypes.array.isRequired,
    maxLevels: PropTypes.number,
    handlerLevelItem: PropTypes.func.isRequired,
    currentLevel: PropTypes.number.isRequired,
    tabOrder: PropTypes.object.isRequired,
    language: PropTypes.string,
  };

  static defaultProps = {
    maxLevels: 5,
    language: strings.getLanguage(),
  };

  renderComments = () => {
    const { levels, maxLevels, handlerLevelItem, language, tabOrder } = this.props;
    const orderedLevels = language === 'ar' ? [...levels].reverse() : levels;

    return orderedLevels.map(({ label, levels: level }) => (
      <LevelItem
        tabIndex={tabOrder[`LEVEL_${level}`]}
        key={label}
        level={level}
        label={label}
        enabled={level <= maxLevels}
        handleClick={handlerLevelItem}
      />
    ));
  };

  render() {
    const { currentLevel, language } = this.props;
    const levelsChartClass = language === 'ar' ? 'levels-chart-rtl' : 'levels-chart';

    return (
      <ol
        className={`${levelsChartClass} js-${levelsChartClass} ${levelsChartClass}--active-${currentLevel}`
        }>
        {this.renderComments()}
      </ol>
    );
  }
}

export default withTabOrder(connect((state) => ({
  language: getLanguage(state),
}))(LevelBar));
