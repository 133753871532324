import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { addCommasToNumbersInText } from '@wfp/freerice-core/utils/parsing';

import './SmallNotification.scss';

const SmallNotification = ({ visible, params }) => {
  const { title } = params;
  return (
    <CSSTransition in={visible} timeout={500} classNames="slide-y">
      <div className="notification notification--small">
        <div className="notification--small--icon" />
        <div className="notification--small--content">
          <h2>{addCommasToNumbersInText(title)}</h2>
        </div>
      </div>
    </CSSTransition>
  );
};

SmallNotification.propTypes = {
  visible: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
};

export default SmallNotification;
