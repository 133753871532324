import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';

import withPageTitle from 'containers/common/withPageTitle';
import BoxInfoImage from 'components/pages/common/box/BoxInfoImage';
import BackButton from 'components/app/BackButton';
import GroupContent from 'components/pages/groups/group/GroupContent';
import { withTabOrder, tabOrders, TAB_NAMES } from 'components/app/TabOrder';

import {
  getGroup,
  isLoading,
  isLoadingGroupMembers,
  leaveGroup,
  loadGroupMembers,
  loadGroup,
  loadUserRankInGroup,
} from '@wfp/freerice-core/modules/groups';
import { getUserAccount } from '@wfp/freerice-core/modules/user';

import strings from 'res/strings';

class Group extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }).isRequired,
    group: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
    leaveGroup: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    loadGroup: PropTypes.func.isRequired,
    loadGroupMembers: PropTypes.func.isRequired,
    isLoadingGroupMembers: PropTypes.bool.isRequired,
    loadUserRankInGroup: PropTypes.func.isRequired,
    setTabOrder: PropTypes.func.isRequired,
    focusTo: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {
    group: {},
  };

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      group,
      user,
      loadGroup,
      loadUserRankInGroup,
      setTabOrder,
      focusTo,
      location,
    } = this.props;

    setTabOrder(tabOrders.GROUP);

    if (location.state && location.state.focusAfter) focusTo(TAB_NAMES.MENU_TOGGLE);

    if (!group.uuid || typeof group.rank === 'undefined') {
      loadGroup(id);
    } else if (!group.personalRank) {
      loadUserRankInGroup(id, user.uuid);
    }
  }

  loadMembers = page => {
    const {
      loadGroupMembers,
      match: {
        params: { id },
      },
    } = this.props;

    loadGroupMembers(id, page);
  };

  leaveGroupHandler = () => {
    const {
      history,
      match: {
        params: { id },
      },
      leaveGroup,
    } = this.props;
    leaveGroup(id);
    history.push(`/group-leave/${id}`);
  };

  render() {
    const { group, isLoading, user, isLoadingGroupMembers } = this.props;

    return (
      <BoxInfoImage imageType="group-icon" groupIconId={group.avatar}>
        <GroupContent
          isLoadingGroupMembers={isLoadingGroupMembers}
          loadMembers={this.loadMembers}
          user={user}
          group={group}
          handlerLeaveGroup={this.leaveGroupHandler}
          loading={isLoading}
        />
        <BackButton to="/groups" text={strings.back_to_groups} />
      </BoxInfoImage>
    );
  }
}

export default compose(
  withPageTitle({ title: strings.groups_link }),
  withTabOrder,
  connect(
    (state, props) => ({
      group: getGroup(state, props.match.params.id),
      isLoading: isLoading(state),
      user: getUserAccount(state),
      isLoadingGroupMembers: isLoadingGroupMembers(state),
    }),
    { loadGroup, leaveGroup, loadGroupMembers, loadUserRankInGroup }
  )
)(Group);
