import React from 'react';
import PropTypes from 'prop-types';

import Input from 'components/pages/common/Input';
import strings from 'res/strings';
import Button from 'components/pages/common/Button/Button';
import BoxInfoError from 'components/pages/common/box/BoxInfoError';

import './DeleteProfileForm.scss';

export class DeleteProfileForm extends React.PureComponent {
  state = { password: '', validationErrors: [] };

  handleSubmit = e => {
    e.preventDefault();
    const { password } = this.state;
    if (password.length < 5) {
      this.setState({ validationErrors: [strings['deleteProfile.form.invalid_password']] });
    } else {
      this.props.onSubmit({ password });
    }
  };

  handleChange = e => {
    this.setState({ password: e.target.value, validationErrors: [] });
  };

  render() {
    const { errors } = this.props;
    const { password, validationErrors } = this.state;

    return (
      <form className="delete-profile__form" onSubmit={this.handleSubmit}>
        <p>{strings['deleteProfile.form.description_p1']}</p>
        <p>{strings['deleteProfile.form.description_p2']}</p>
        <p>{strings['deleteProfile.form.description_p3']}</p>
        <Input
          id="password"
          type="password"
          name="password"
          placeholder={strings['deleteProfile.form.password_placeholder']}
          value={password}
          isInputError={validationErrors.length > 0}
          handlerChange={this.handleChange}
        />
        {!!validationErrors && validationErrors.length > 0 && <BoxInfoError messages={validationErrors} />}
        {!!errors && errors.length !== 0 && <BoxInfoError messages={errors} />}
        <Button type="submit" color="red" text={strings['deleteProfile.form.confirm_button']} />
      </form>
    );
  }
}

DeleteProfileForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
};

DeleteProfileForm.defaultProps = {
  errors: [],
};
