import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLanguage } from '@wfp/freerice-core/modules/app';

import './SocialLinks.scss';

import { withTabOrder } from 'components/app/TabOrder';
import strings from 'res/strings';

const socialLinks = [
  {
    name: 'facebook',
    url: 'https://www.facebook.com/freerice',
    title: strings['block.social.follow.facebook'],
  },
  {
    name: 'twitter',
    url: 'https://twitter.com/Freerice',
    title: strings['block.social.follow.twitter'],
  },
  {
    name: 'instagram',
    url: 'https://www.instagram.com/wfpfreerice/',
    title: strings['block.social.follow.instagram'],
  },
];

const SocialLinks = ({ language, tabOrder }) => {
  const socialLinksClass = language === 'ar' ? 'social-rtl' : 'social';

  return (
    <div className={socialLinksClass}>
      {socialLinks.map(({ name, url, title }, index) => (
        <div key={name} tabIndex={tabOrder[`SOCIAL_LINK_${index + 1}`]} onKeyPress={() => window.open(url, '_blank')}>
          <a
            className={`${socialLinksClass}-item ${socialLinksClass}-item--${name}`}
            tabIndex={-1}
            key={`social-links-${name}`}
            target="_blank"
            rel="noopener noreferrer"
            href={url}
          >
            {title}
          </a>
        </div>
      ))}
    </div>
  );
};

SocialLinks.propTypes = {
  language: PropTypes.string,
  tabOrder: PropTypes.object.isRequired,
};

SocialLinks.defaultProps = {
  language: strings.getLanguage(),
};

export default withTabOrder(connect((state) => ({
  language: getLanguage(state),
}))(SocialLinks));
