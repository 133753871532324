import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import withPageTitle from 'containers/common/withPageTitle';
import BoxInfo from 'components/pages/common/box/BoxInfo';
import AnonymousContent from 'components/pages/groups/AnonymousContent';
import BackButton from 'components/app/BackButton';
import { withTabOrder, tabOrders, TAB_NAMES } from 'components/app/TabOrder';
import strings from 'res/strings';

const GroupsAnonymous = ({ setTabOrder, focusTo }) => {
  const { state } = useLocation();

  useEffect(() => {
    setTabOrder(tabOrders.GROUPS_ANONYMOUS);

    if (state && state.focusAfter) focusTo(TAB_NAMES.MENU_TOGGLE);
  }, []);

  return (
    <BoxInfo type="medium-width">
      <AnonymousContent />
      <BackButton />
    </BoxInfo>
  );
};

GroupsAnonymous.propTypes = {
  setTabOrder: PropTypes.func.isRequired,
  focusTo: PropTypes.func.isRequired,
};

export default withTabOrder(withPageTitle({ title: strings.groups_link })(GroupsAnonymous));
