import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';

import strings from 'res/strings';
import { withTabOrder } from 'components/app/TabOrder';
import { withAnimatedNav } from '../Main/AnimatedNavContext';
import './BackButton.scss';

const BackButton = ({
  onClick,
  to,
  isVisible,
  text,
  history,
  animatedNav: { setAnimationState },
  tabOrder,
  className,
}) => (
  <button
    tabIndex={tabOrder.BACK_BUTTON}
    type="button"
    className={classNames(['back-button', className], { visible: isVisible })}
    onClick={() => (onClick ? onClick(setAnimationState) : setAnimationState(true, () => history.push(to)))}
  >
    {text}
  </button>
);

BackButton.propTypes = {
  className: PropTypes.string,
  isVisible: PropTypes.bool,
  text: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,
  animatedNav: PropTypes.shape({
    setAnimationState: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  tabOrder: PropTypes.object.isRequired,
};

BackButton.defaultProps = {
  className: '',
  isVisible: true,
  text: strings.back_to_game,
  onClick: undefined,
  to: '/',
};

export default withAnimatedNav(withTabOrder(withRouter(BackButton)));
