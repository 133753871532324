import { createSelector } from 'reselect';
import { produce } from 'immer';
import _sortBy from 'lodash/sortBy';
import { createRequestTypes } from '../utils/actionHelpers';
import { createApiCall } from '../utils/api'; // region ACTION TYPES

export var BADGES = {
  LOAD_LIST: createRequestTypes('BADGES_LOAD_LIST')
}; // endregion
// region ACTIONS

export var loadBadges = createApiCall(BADGES.LOAD_LIST, function (lang) {
  return {
    url: '/badges/?limit=50',
    params: {
      lang: lang
    }
  };
}); // endregion
// region REDUCER

var initialState = {
  badges: [],
  loading: false,
  error: null
};
export default produce(function (draft, _ref) {
  var type = _ref.type,
      payload = _ref.payload;

  switch (type) {
    case BADGES.LOAD_LIST.REQUEST:
      draft.loading = true;
      break;

    case BADGES.LOAD_LIST.SUCCESS:
      draft.loading = false;
      draft.badges = payload;
      break;

    case BADGES.LOAD_LIST.FAILURE:
      draft.loading = false;
      draft.error = payload.message;
      break;

    default:
      break;
  }
}, initialState); // endregion

var badgeOrder = ['accumulation_1000', 'accumulation_2000', 'accumulation_5000', 'accumulation_10000', 'persistence_30i', 'persistence_1h', 'persistence_2h', 'persistence_5d', 'persistence_10d', 'persistence_15d', 'streak_10', 'streak_25', 'streak_50', 'streak_75', 'streak_100', 'streak_125', 'streak_150', 'streak_175', 'streak_200']; // region SELECTORS

var badgesState = function badgesState(state) {
  return state.badges;
};

export var getBadges = createSelector(badgesState, function (state) {
  return _sortBy(state.badges || [], [function (b) {
    return badgeOrder.indexOf(b.machine_name);
  }]);
}); // endregion