import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTabOrder, TAB_NAMES } from 'components/app/TabOrder';
import './CardTitle.scss';

const CardTitle = ({ text, className, tabOrder, addFocus }) => (
  <>
    {text && (
      <div
        ref={elem => addFocus(TAB_NAMES.QUESTION_TEXT, () => {
          if (elem) elem.focus();
        })}
        tabIndex={tabOrder.QUESTION_TEXT}
      >
        <div
          tabIndex={-1}
          dir="ltr"
          className={classNames('card-title', { 'text-small': text.length > 80, [className]: className })}
        >
          {text}
        </div>
      </div>
    )}
  </>
);

CardTitle.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  tabOrder: PropTypes.object.isRequired,
  addFocus: PropTypes.func.isRequired,
};

CardTitle.defaultProps = {
  className: '',
};

export default withTabOrder(CardTitle);
