import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export const TabOrderContext = React.createContext();

export class TabOrderProvider extends PureComponent {
  static propTypes = { children: PropTypes.node.isRequired };

  focusCallbacks = [];

  /* eslint-disable react/sort-comp,react/no-unused-state */
  setTabOrder = (tabOrder) => this.setState({ tabOrder });

  focusTo = (name) => {
    if (this.focusCallbacks.length) {
      this.focusCallbacks.forEach((focus) => {
        if (name === focus.name) setTimeout(() => focus.callback(), 0);
      });
    }
  };

  addFocus = (name, callback) => this.focusCallbacks.push({ name, callback });
  /* eslint-enable react/sort-comp */

  /* eslint-disable react/no-unused-state,react/sort-comp */
  state = {
    focusTo: this.focusTo,
    addFocus: this.addFocus,

    tabOrder: {},
    setTabOrder: this.setTabOrder,
  };
  /* eslint-enable react/no-unused-state */

  render() {
    const { children } = this.props;

    return (
      <TabOrderContext.Provider value={this.state}>
        {children}
      </TabOrderContext.Provider>
    );
  }
}

export const withTabOrder = WrappedComponent => props => (
  <TabOrderContext.Consumer>
    {({ tabOrder, setTabOrder, addFocus, focusTo }) => (
      <WrappedComponent
        {...props}
        addFocus={addFocus}
        focusTo={focusTo}

        tabOrder={tabOrder}
        setTabOrder={setTabOrder}
      />
    )}
  </TabOrderContext.Consumer>
);
