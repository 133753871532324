import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CountUp from 'react-countup';
import { connect } from 'react-redux';
import { getLanguage } from '@wfp/freerice-core/modules/app';
import strings from 'res/strings';

import LevelCircularBar from '../LevelCircularBar';
import './BowlRice.scss';


const data = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

class BowlRice extends Component {
  static propTypes = {
    category: PropTypes.shape({
      machine_name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired,
    level: PropTypes.shape({
      level: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
    riceCount: PropTypes.number,
    language: PropTypes.string,
  };

  static defaultProps = {
    level: {},
    riceCount: 0,
    language: strings.getLanguage(),
  };

  // eslint-disable-next-line react/destructuring-assignment
  state = { prevRiceCount: this.props.riceCount, riceCount: this.props.riceCount };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.riceCount !== prevState.riceCount) {
      return { prevRiceCount: prevState.riceCount, riceCount: nextProps.riceCount };
    }
    return null;
  }

  renderImages = () => {
    const { riceCount } = this.props;
    let value = 0;
    if (riceCount === 0) {
      value = 0;
    } else {
      value = riceCount % 50;
      if (value === 0) value = 50;
    }
    return data.map(item => (
      <div
        key={`bowl-rice-img-${item}`}
        className={classNames([`rice-bowl rice-bowl--${item}`], { selected: value === item })}
      />
    ));
  };

  render() {
    const { category, level, language } = this.props;
    const { riceCount, prevRiceCount } = this.state;

    const riceCounterClass = language === 'ar' ? 'rice-counter-rtl' : 'rice-counter';

    return (
      <>
        <div className="rice-animation">
          <div key="bowl-rice-img-0" className={classNames('rice-bowl rice-bowl--0', { selected: !riceCount })} />
          {this.renderImages()}
        </div>
        <div className={riceCounterClass}>
          <LevelCircularBar level={level.levels} />
          <style>
            {`
            .category-image--${category.machine_name} {
              background-image: url(${category.icon_urls[0]})
            }

            @media (min-width: 992px) {
              .category-image--${category.machine_name} {
                background-image: url(${category.icon_urls[1] || category.icon_urls[0]})
              }
            }

            @media (min-width: 1600px) {
              .category-image--${category.machine_name} {
                background-image: url(${category.icon_urls[2] || category.icon_urls[1] || category.icon_urls[0]})
              }
            }
          `}
          </style>
          <div className={`${riceCounterClass}__category-image category-image--${category.machine_name}`} />
          <div className={`${riceCounterClass}__category-text`}>{category.label}</div>
          <CountUp
            start={prevRiceCount}
            end={riceCount}
            duration={Math.ceil(riceCount / 50)}
            delay={1}
            formattingFn={value => value.toLocaleString()}
          >
            {({ countUpRef }) => (
              <div className={`${riceCounterClass}__value`}>
                <span ref={countUpRef} />
              </div>
            )}
          </CountUp>
        </div>
      </>
    );
  }
}

export default connect((state) => ({
  language: getLanguage(state),
}))(BowlRice);
