import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import LargeNotification from './LargeNotification';
import SmallNotification from './SmallNotification';
import LargeAnnouncement from './LargeAnnouncement';
import { SMALL_NOTIFICATION, LARGE_NOTIFICATION, LARGE_ANNOUNCEMENT } from './Constants';

export const NotificationContext = React.createContext();

export class NotificationProvider extends PureComponent {
  static propTypes = { children: PropTypes.node.isRequired };

  /* eslint-disable react/sort-comp */
  setVisibility = (type, isVisible, params) => this.setState({
    type,
    visible: isVisible,
    params,
  }, () => {
    if (this.state.type === SMALL_NOTIFICATION && this.state.visible) {
      this.timer = setTimeout(() => {
        if (this.state.type === SMALL_NOTIFICATION && this.state.visible) {
          this.setVisibility(SMALL_NOTIFICATION, false, params);
        }
      }, 5000);
    }
  });
  /* eslint-enable react/sort-comp */

  timer = null;

  state = {
    type: SMALL_NOTIFICATION,
    visible: true,
    params: {},
    // eslint-disable-next-line
    setVisibility: this.setVisibility,
  };

  render() {
    const { children } = this.props;
    const { type, visible, params } = this.state;

    return (
      <NotificationContext.Provider value={{
        ...this.state,
        SmallNotification: (
          <SmallNotification
            visible={type === SMALL_NOTIFICATION && visible}
            params={params}
          />
        ),
      }}>
        {children}
        <LargeNotification
          visible={type === LARGE_NOTIFICATION && visible}
          params={params}
          setVisibility={this.setVisibility}
        />
        <LargeAnnouncement
          visible={type === LARGE_ANNOUNCEMENT && visible}
          params={params}
          setVisibility={this.setVisibility}
        />
      </NotificationContext.Provider>
    );
  }
}

export const withNotification = WrappedComponent => props => (
  <NotificationContext.Consumer>
    {({ type, visible, setVisibility, SmallNotification }) => (
      <WrappedComponent
        {...props}
        SmallNotification={SmallNotification}
        notificationType={type}
        isNotificationVisible={visible}
        setNotificationVisibility={setVisibility}
      />
    )}
  </NotificationContext.Consumer>
);
