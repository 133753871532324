import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTabOrder, TAB_NAMES } from 'components/app/TabOrder';
import './EditButton.scss';

const EditButton = ({ handlerClick, className, tabOrder, addFocus }) => (
  <>
    <input
      id="input-edit-button"
      type="checkbox"
      onClick={handlerClick}
    />
    <div
      ref={elem => addFocus(TAB_NAMES.EDIT_BUTTON, () => {
        if (elem) elem.focus();
      })}
      tabIndex={tabOrder.EDIT_BUTTON}
      onKeyPress={(e) => e.charCode === 13 && handlerClick(e, true)}
      onClick={handlerClick}
      className={classNames('edit-button-container')}
    >
      <label
        className={classNames('edit-button', className)}
        tabIndex={-1}
        htmlFor="input-edit-button"
      />
    </div>
  </>
);

EditButton.propTypes = {
  handlerClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  tabOrder: PropTypes.object.isRequired,
  addFocus: PropTypes.func.isRequired,
};

EditButton.defaultProps = {
  className: '',
};

export default withTabOrder(EditButton);
