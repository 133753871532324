import { combineReducers } from 'redux';
import app from './app';
import about from './about';
import categories from './categories';
import badges from './badges';
import user from './user';
import groups from './groups';
import game from './game';
import leaderboard from './leaderboard';
import levels from './levels';
import activityFeed from './activityFeed';
import challenges from './challenges';
import eyeCatchers from './eyeCatchers';
export default combineReducers({
  app: app,
  about: about,
  game: game,
  user: user,
  categories: categories,
  badges: badges,
  groups: groups,
  leaderboard: leaderboard,
  levels: levels,
  activityFeed: activityFeed,
  challenges: challenges,
  eyeCatchers: eyeCatchers
});