import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './BoxInfoSuccess.scss';

class BoxInfoSuccess extends Component {
  static propTypes = {
    messages: PropTypes.array,
  };

  static defaultProps = {
    messages: [],
  };

  renderMessages() {
    const { messages } = this.props;
    return messages.map((message) => <div key={message}>{message}</div>);
  }

  render() {
    const { messages } = this.props;
    return <>{messages.length && <div className="box-info-success">{this.renderMessages()}</div>}</>;
  }
}

export default BoxInfoSuccess;
