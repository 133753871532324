import React from 'react';

import strings from 'res/strings';
import { ReactComponent as SuccessMark } from 'res/images/profile/done.svg';

import './DeleteSuccess.scss';

export const DeleteSuccess = () => (
  <div className="delete-profile__success">
    <h2>{strings['deleteProfile.success.header']}</h2>
    <SuccessMark width={120} height={120} />
  </div>
);
