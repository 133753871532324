import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/pages/common/Input';
import Button from 'components/pages/common/Button';
import BoxInfoProgressBar from 'components/pages/common/box/BoxInfoProgressBar';
import BoxInfoSuccess from 'components/pages/common/box/BoxInfoSuccess';
import BoxInfoError from 'components/pages/common/box/BoxInfoError';
import BoxInfoTitle from 'components/pages/common/box/BoxInfoTitle';
import { withTabOrder } from 'components/app/TabOrder';
import strings from 'res/strings';

class EditProfileContent extends Component {
  static propTypes = {
    data: PropTypes.shape({
      username: PropTypes.string,
      email: PropTypes.string,
      external: PropTypes.bool,
    }),
    handlerSaveChanges: PropTypes.func.isRequired,
    validate: PropTypes.object,
    isLoading: PropTypes.bool,
    updateUserErrors: PropTypes.array.isRequired,
    progress: PropTypes.string,
    tabOrder: PropTypes.object.isRequired,
  };

  static defaultProps = {
    data: { username: '', email: '', external: false },
    validate: {},
    isLoading: false,
    progress: null,
  };

  /* eslint-disable react/destructuring-assignment */
  state = {
    external: this.props.data.external,
    username: this.props.data.username,
    email: this.props.data.email,
  };
  /* eslint-enable react/destructuring-assignment */

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      data: { username, email },
    } = nextProps;
    if (prevState.username && prevState.email && !username && !email) {
      return null;
    }
    const nextState = {};
    if (!prevState.username) {
      nextState.username = username;
    }
    if (!prevState.email) {
      nextState.email = email;
    }
    return nextState;
  }

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  saveChangesHandler = () => {
    const { handlerSaveChanges } = this.props;
    const { username, email, password, confirmPassword } = this.state;

    handlerSaveChanges(username, email, password, confirmPassword);
  };

  render() {
    const { validate, isLoading, updateUserErrors, progress, tabOrder } = this.props;
    const { username, email, password, confirmPassword, external } = this.state;

    return (
      <>
        <BoxInfoTitle text={strings['editProfile.title.edit']} />
        {!!external && (
          <>
            <BoxInfoError messages={[strings['editProfile.web.facebookLogin.info1']]} />
            <br />
            <BoxInfoError messages={[strings['editProfile.web.facebookLogin.info2']]} />
            <br />
            <BoxInfoError messages={[strings['editProfile.web.facebookLogin.info3']]} />
            <br />
            <BoxInfoError messages={[strings['editProfile.web.facebookLogin.info4']]} />
            <br />
            <BoxInfoError messages={[strings['editProfile.web.facebookLogin.info5']]} />
            <br />
            <BoxInfoError messages={[strings['editProfile.web.facebookLogin.info6']]} />
            <br />
            <BoxInfoError messages={[strings['editProfile.web.facebookLogin.info7']]} />
            <br />
            <br />
          </>
        )}
        <BoxInfoProgressBar isVisible={isLoading || progress === 'savingChanges'}>
          <Input
            tabIndex={tabOrder.EDIT_PROFILE_USERNAME}
            id="register-username"
            type="text"
            name="username"
            placeholder={strings['editProfile.field.username']}
            value={username}
            isInputError={validate.isUsername}
            handlerChange={this.changeHandler}
            label={strings['editProfile.field.username']}
          />
          <Input
            tabIndex={tabOrder.EDIT_PROFILE_EMAIL}
            id="register-email"
            type="email"
            name="email"
            placeholder={strings['editProfile.web.placeholder.email']}
            isInputError={validate.isEmail}
            value={email}
            handlerChange={this.changeHandler}
            label={strings['editProfile.field.email']}
          />
          <h3>{strings['editProfile.title.change']}</h3>
          <Input
            tabIndex={tabOrder.EDIT_PROFILE_PASSWORD}
            id="password"
            type="password"
            name="password"
            placeholder={strings['editProfile.placeholder.password']}
            value={password}
            isInputError={validate.isPassword}
            handlerChange={this.changeHandler}
            label={strings['editProfile.field.password']}
          />
          <Input
            tabIndex={tabOrder.EDIT_PROFILE_PASSWORD_CONFIRM}
            id="password-confirm"
            type="password"
            name="confirmPassword"
            value={confirmPassword}
            isInputError={validate.isConfirmPassword}
            handlerChange={this.changeHandler}
            placeholder={strings['editProfile.placeholder.confirm']}
            label={strings['editProfile.field.confirm']}
          />
          {validate && validate.messages.length !== 0 && <BoxInfoError messages={validate.messages} />}
          {updateUserErrors && updateUserErrors.length !== 0 && <BoxInfoError messages={updateUserErrors} />}
          {progress === 'saved' && !updateUserErrors.length && (
            <BoxInfoSuccess messages={[strings['editProfile.web.update.success']]} />
          )}
          <Button
            tabIndex={tabOrder.EDIT_PROFILE_SAVE_BUTTON}
            color="green"
            text={strings['editProfile.button.save']}
            handlerClick={this.saveChangesHandler}
          />
        </BoxInfoProgressBar>
      </>
    );
  }
}

export default withTabOrder(EditProfileContent);
