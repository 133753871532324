import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './BoxInfoContent.scss';

const BoxInfoContent = ({ children, className }) => (
  <div className={classNames('box-info-content', className)}>{children}</div>
);

BoxInfoContent.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

BoxInfoContent.defaultProps = { className: '' };

export default BoxInfoContent;
