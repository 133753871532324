import React from 'react';
import PropTypes from 'prop-types';
import './BoxInfoSubTitle.scss';

const BoxInfoSubTitle = ({ text }) => <p className="box-info-subtitle">{text}</p>;

BoxInfoSubTitle.propTypes = {
  text: PropTypes.string.isRequired,
};

export default BoxInfoSubTitle;
