import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './BoxInfoTitle.scss';

const BoxInfoTitle = ({ text, className }) => <h2 className={classNames('box-info-title', className)}>{text}</h2>;

BoxInfoTitle.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

BoxInfoTitle.defaultProps = {
  text: '',
  className: '',
};

export default BoxInfoTitle;
