import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import withPageTitle from 'containers/common/withPageTitle';
import BoxInfo from 'components/pages/common/box/BoxInfo';
import BackButton from 'components/app/BackButton';
import BoxInfoContent from 'components/pages/common/box/BoxInfoContent';
import SignIn from 'components/pages/profile/login/SignIn';
import SignUp from 'components/pages/profile/login/SignUp';
import { connect } from 'react-redux';

import {
  signIn,
  clearUserErrors,
  getUserAccount,
  isLoadingUser,
  getUserDataErrors,
  loginWithFacebook,
} from '@wfp/freerice-core/modules/user';
import { getLanguage } from '@wfp/freerice-core/modules/app';

import validate, { rulesMethods } from '@wfp/freerice-core/utils/validators';

import { withTabOrder, tabOrders, TAB_NAMES } from 'components/app/TabOrder';
import strings from 'res/strings';

/* eslint-disable react/no-unused-state */
class Login extends Component {
  static propTypes = {
    signInErrors: PropTypes.array,
    clearUserErrors: PropTypes.func.isRequired,
    user: PropTypes.shape({
      uuid: PropTypes.string,
    }),
    isLoading: PropTypes.bool,
    history: PropTypes.object.isRequired,
    signIn: PropTypes.func.isRequired,
    loginWithFacebook: PropTypes.func.isRequired,
    language: PropTypes.string,
    setTabOrder: PropTypes.func.isRequired,
    focusTo: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {
    signInErrors: [],
    user: null,
    isLoading: false,
    language: strings.getLanguage(),
  };

  state = {
    validate: {
      messages: [],
      isUsername: false,
      isPassword: false,
    },
    loading: {},
    showForgotPassLink: true,
  };

  componentDidMount() {
    const { user, history, setTabOrder, focusTo, location } = this.props;

    setTabOrder(tabOrders.LOGIN);

    if (location.state && location.state.focusAfter) focusTo(TAB_NAMES.MENU_TOGGLE);

    if (user && user.uuid) {
      history.push('/profile');
    }
  }

  componentDidUpdate() {
    const { user, isLoading, signInErrors, history } = this.props;

    if (user && user.uuid && !isLoading && !signInErrors.length) {
      history.push('/profile');
    }
  }

  componentWillUnmount() {
    const { signInErrors, clearUserErrors } = this.props;
    if (signInErrors.length) {
      clearUserErrors();
    }
  }

  signInHandler = (username, password) => {
    if (!this.isValid(username, password)) {
      return;
    }

    const { signIn, language } = this.props;
    signIn({ username, password, lang: language });
  };

  onResponseFacebook = response => {
    const { loginWithFacebook, language } = this.props;
    loginWithFacebook(response, language);
  };

  isValid(username, password) {
    const data = { username, password };
    let messages = [];
    let isValid = false;

    messages = validate(data, {
      username: 'required',
      password: 'required',
    }, strings);

    if (messages.length) {
      this.setState({
        validate: {
          messages,
          isUsername: !rulesMethods.required(data, 'username'),
          isPassword: !rulesMethods.required(data, 'password'),
        },
      });
    } else {
      this.setState({
        validate: {
          messages: [],
          isUsername: false,
          isPassword: false,
        },
      });
      isValid = true;
    }

    return isValid;
  }

  render() {
    const { isLoading, signInErrors } = this.props;

    return (
      <BoxInfo type="medium-width">
        <BoxInfoContent className="box-info-content--auth">
          <SignIn
            data={this.state}
            isLoading={isLoading}
            signInErrors={signInErrors}
            handlerSignIn={this.signInHandler}
          />
          <SignUp loading={isLoading} onResponseFacebook={this.onResponseFacebook} />
        </BoxInfoContent>
        <BackButton />
      </BoxInfo>
    );
  }
}
/* eslint-enable react/no-unused-state */

export default compose(
  withPageTitle({ title: strings['login.title.userProfie'] }),
  withTabOrder,
  connect(
    createStructuredSelector({
      user: getUserAccount,
      isLoading: isLoadingUser,
      signInErrors: getUserDataErrors,
      language: getLanguage,
    }),
    { signIn, clearUserErrors, loginWithFacebook }
  )
)(Login);
