import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { difference } from 'lodash';

import { getUserAccount } from '@wfp/freerice-core/modules/user';
import { getAnnouncements } from '@wfp/freerice-core/modules/activityFeed';
import strings from 'res/strings';
import { withTabOrder } from 'components/app/TabOrder';
import MenuItem from './MenuItem';

class Menu extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    menuName: PropTypes.string.isRequired,
    handlerClick: PropTypes.func,
    tabOrder: PropTypes.object.isRequired,
    announcements: PropTypes.array,
  };

  static defaultProps = {
    handlerClick: null,
    announcements: [],
  };

  haveUnreadedAnnouncements = () => {
    const { announcements } = this.props;

    if (announcements) {
      const readedAnnouncements = JSON.parse(window.localStorage.getItem('readedAnnouncements')) || [];
      const unreadedAnnouncements = difference(
        announcements.map(announcement => announcement.date),
        readedAnnouncements
      );

      if (unreadedAnnouncements.length > 0) return true;
    }

    return false;
  }

  renderMenuItems = () => {
    const menuItemsList = [
      {
        name: 'game',
        exact: false,
        to: '/game',
        title: strings.game_link,
        isActive: (_, location) => location.pathname.includes('/categories/'),
      },
      { name: 'categories', exact: true, to: '/categories', title: strings.categories_link },
      { name: 'levels', exact: false, to: '/levels', title: strings.difficulty_link },
      { name: 'challenges', exact: false, to: '/challenges', title: strings.challenges_link },
      { name: 'groups', exact: false, to: '/groups', title: strings.groups_link },
      { name: 'high-scores', exact: false, to: '/high-scores', title: strings.leaderboard_link },
      {
        name: 'activity-feed',
        exact: false,
        to: '/activity-feed',
        title: strings.activity_feed_link,
        hasRedCircle: this.haveUnreadedAnnouncements(),
      },
      { name: 'about', exact: false, to: '/about', title: strings.about_link },
    ];

    const { menuName, handlerClick, tabOrder } = this.props;
    return menuItemsList.map(({ name, to, title, exact, isActive, hasRedCircle }, index) => (
      <li key={`menu-${name}`} onClick={handlerClick}>
        <MenuItem
          hasRedCircle={hasRedCircle}
          tabIndex={tabOrder[`SIDE_DRAWER_MENU_ITEM_${index + 1}`]}
          groupName={menuName}
          itemName={name}
          exact={exact}
          isActive={isActive}
          to={this.prepareTo(name, to)}
          handlerClick={handlerClick}
        >
          {title}
        </MenuItem>
      </li>
    ));
  };

  prepareTo = (name, to) => {
    const {
      user: { uuid, token },
    } = this.props;

    if (name === 'profile' && uuid && token) {
      return '/profile';
    }

    return to;
  };

  render() {
    const { menuName } = this.props;
    return (
      <nav>
        <ul className={menuName}>{this.renderMenuItems()}</ul>
      </nav>
    );
  }
}

export default withTabOrder(withRouter(
  compose(
    connect(
      createStructuredSelector({
        user: getUserAccount,
        announcements: getAnnouncements,
      }),
      {}
    )
  )(Menu)
));
