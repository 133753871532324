import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './RoundBlock.scss';

const RoundBlock = ({ className, title, children }) => (
  <div className={classNames('block block--round', className)}>
    {!!title && <h3 className="block__title">{title}</h3>}
    <div className="block__body">{children}</div>
  </div>
);

RoundBlock.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

RoundBlock.defaultProps = { className: null, title: '' };

export default RoundBlock;
