import React from 'react';
import PropTypes from 'prop-types';

import globe from 'res/images/globe@2x.png';
import './Globe.scss';

const Globe = ({ children }) => (
  <div className="globe">
    <img src={globe} alt="globe" className="globe__image" />
    {children}
  </div>
);

Globe.propTypes = { children: PropTypes.node.isRequired };

export default Globe;
