import React from 'react';
import PageHeader from 'components/pages/common/PageHeader';
import PageContainer from 'components/pages/common/PageContainer';

const withPageTitle = ({ title, subTitle, className }) => WrappedComponent => props => (
  <PageContainer className={className}>
    <PageHeader title={title} subTitle={subTitle} />
    <div className="page__body">
      <WrappedComponent {...props} />
    </div>
  </PageContainer>
);

export default withPageTitle;
