import React, { Component } from 'react';
import PropTypes from 'prop-types';

import range from 'lodash/range';
import { compose } from 'redux';
import { connect } from 'react-redux';

import withPageTitle from 'containers/common/withPageTitle';
import BoxInfoImage from 'components/pages/common/box/BoxInfoImage';
import BackButton from 'components/app/BackButton';
import EditGroupContent from 'components/pages/groups/editGroup/EditGroupContent';
import IconList from 'components/pages/common/IconList';
import {
  updateGroup,
  getGroup,
  loadGroup,
  isLoading,
  isLoadingCode,
  refreshCodeGroup,
  getGroupsErrors,
  clearErrors,
} from '@wfp/freerice-core/modules/groups';
import { getLanguage } from '@wfp/freerice-core/modules/app';
import validate from '@wfp/freerice-core/utils/validators';

import strings from 'res/strings';

const icons = range(1, 15).map(item => `group-${item}`);

class EditGroups extends Component {
  static propTypes = {
    group: PropTypes.object,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }).isRequired,
    updateGroup: PropTypes.func.isRequired,
    loadGroup: PropTypes.func.isRequired,
    refreshCodeGroup: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isLoadingCode: PropTypes.bool.isRequired,
    clearErrors: PropTypes.func.isRequired,
    groupErrors: PropTypes.any,
    language: PropTypes.string,
  };

  static defaultProps = {
    group: {},
    groupErrors: null,
    language: strings.getLanguage(),
  };

  state = {
    errors: {
      nameMessage: '',
      codeMessage: '',
    },
    progress: null,
    loading: {
      data: false,
      code: false,
      icon: false,
    },
    isEditIcon: false,
    // eslint-disable-next-line react/destructuring-assignment
    groupIcon: this.props.group.avatar || '',
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.progress === 'savingGroup' && !nextProps.isLoading) {
      return { progress: 'savedGroup' };
    }
    if (prevState.progress === 'refreshingCode' && !nextProps.isLoadingCode) {
      return { progress: 'refreshedCode' };
    }
    return null;
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      group,
      loadGroup,
    } = this.props;

    if (!group || typeof group.rice === 'undefined') {
      loadGroup(id);
    }
  }

  componentWillUnmount() {
    const { clearErrors } = this.props;
    clearErrors();
  }

  saveChangesHandler = ({ name }) => {
    if (!this.isValid(name)) {
      return;
    }

    const { updateGroup, group, language } = this.props;
    const { groupIcon } = this.state;

    this.setState({ progress: 'savingGroup' }, () => {
      updateGroup(group.uuid, { name, avatar: groupIcon, description: '' }, language);
    });
  };

  isValid = groupName => {
    const messages = validate({
      name: groupName,
    }, {
      name: 'required|minLength:3',
    }, strings);

    this.setState({
      errors: {
        nameMessage: messages.length ? messages[0] : '',
        codeMessage: '',
      },
    });

    return !messages.length;
  };

  refreshCodeHandler = () => {
    const {
      match: {
        params: { id },
      },
      refreshCodeGroup,
      group: { code },
      language,
    } = this.props;

    this.setState({ progress: 'refreshingCode' }, () => {
      refreshCodeGroup(id, { code, lang: language });
    });
  };

  editIconHandler = () => {
    this.setState({ isEditIcon: true });
  };

  cancelEditIconHandler = () => {
    this.setState({ isEditIcon: false });
  };

  iconClickHandler = event => {
    this.setState({
      isEditIcon: false,
      groupIcon: event.target.value,
    });
  };

  render() {
    const { isEditIcon, errors, loading, progress, groupIcon } = this.state;

    const { group, isLoading, isLoadingCode, groupErrors } = this.props;

    return (
      <>
        <BoxInfoImage
          imageType="group-icon"
          groupIconId={groupIcon}
          editMode
          loading={loading.icon}
          handlerClick={this.editIconHandler}
        >
          <EditGroupContent
            groupErrors={groupErrors}
            handlerSaveChanges={this.saveChangesHandler}
            handlerRefreshCode={this.refreshCodeHandler}
            progress={progress}
            isLoading={isLoading}
            isLoadingCode={isLoadingCode}
            errors={errors}
            data={group}
          />
          <BackButton to="/my-groups" text={strings.back_to_groups} />
        </BoxInfoImage>
        <IconList
          isVisible={isEditIcon}
          title={strings['editGroup.selector.title']}
          icons={icons}
          handlerCancel={this.cancelEditIconHandler}
          handlerItemClick={this.iconClickHandler}
        />
      </>
    );
  }
}

export default compose(
  withPageTitle({ title: strings.groups_link }),
  connect(
    (state, props) => ({
      group: getGroup(state, props.match.params.id),
      isLoading: isLoading(state),
      isLoadingCode: isLoadingCode(state),
      groupErrors: getGroupsErrors(state),
      language: getLanguage(state),
    }),
    { updateGroup, loadGroup, refreshCodeGroup, clearErrors }
  )
)(EditGroups);
