import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLanguage } from '@wfp/freerice-core/modules/app';
import './UserItem.scss';

import strings from 'res/strings';

const UserItem = ({ avatar, rank, username, rice, language }) => {
  const userItemClass = language === 'ar' ? 'user-item-rtl' : 'user-item';

  return (
    <li className={userItemClass}>
      <span className={`${userItemClass}--rank`}>{rank && rank.toLocaleString()}</span>
      <span className={`${userItemClass}--icon user-icon-${avatar}`} />
      <div className={`${userItemClass}-content`}>
        <span className={`${userItemClass}-label`}>{username}</span>
        <span className={`${userItemClass}-description`}>
          {typeof rice === 'undefined' ? '—' : rice.toLocaleString()}
        </span>
      </div>
    </li>
  );
};

UserItem.propTypes = {
  avatar: PropTypes.string,
  username: PropTypes.string,
  rank: PropTypes.number,
  rice: PropTypes.number,
  language: PropTypes.string,
};

UserItem.defaultProps = {
  avatar: 'default',
  rank: 0,
  rice: undefined,
  username: '',
  language: strings.getLanguage(),
};

export default connect((state) => ({
  language: getLanguage(state),
}))(UserItem);
