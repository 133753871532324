import React from 'react';
import PropTypes from 'prop-types';
import BoxInfoContent from 'components/pages/common/box/BoxInfoContent';

import strings from 'res/strings';

const LeaveGroupContent = ({ groupName, groupCode }) => (
  <BoxInfoContent className="groups-content">
    <p>{strings['leaveGroup.title.left']}</p>
    <h2 className="group-name">{groupName}</h2>

    <p>
      {strings['leaveGroup.text.code']}
      <strong className="group-code">{groupCode}</strong>
    </p>
    <p>
      {strings['leaveGroup.instructions.1']}
    </p>
    <p>
      {strings['leaveGroup.instructions.2']}
    </p>
  </BoxInfoContent>
);

LeaveGroupContent.propTypes = {
  groupName: PropTypes.string.isRequired,
  groupCode: PropTypes.string.isRequired,
};

export default LeaveGroupContent;
