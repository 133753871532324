import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { getLanguage } from '@wfp/freerice-core/modules/app';
import { withTabOrder } from 'components/app/TabOrder';

import './Tabs.scss';

import strings from 'res/strings';

class Tabs extends PureComponent {
  static propTypes = {
    tabs: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
    initialTab: PropTypes.string,
    children: PropTypes.func.isRequired,
    tabOrder: PropTypes.object.isRequired,
    language: PropTypes.string,
  };

  static defaultProps = {
    initialTab: null,
    language: strings.getLanguage(),
  };

  state = { activeTab: this.props.initialTab || this.props.tabs[0].name };

  handleChange = e => {
    this.setState({ activeTab: e.target.value });
  };

  render() {
    const { tabs, children, language, tabOrder } = this.props;
    const { activeTab } = this.state;

    const flexTabsClass = language === 'ar' ? 'flex-tabs-rtl' : 'flex-tabs';

    return (
      <div className={`tabs ${flexTabsClass}`}>
        {tabs.map(({ name, label }) => (
          <label key={name} className={classNames('tab', { active: name === activeTab })}>
            <input
              tabIndex={tabOrder.TABS}
              className="state"
              type="radio"
              name="tab"
              value={name}
              checked={name === activeTab}
              onChange={this.handleChange}
            />
            {label}
          </label>
        ))}
        {children(activeTab)}
      </div>
    );
  }
}

export default withTabOrder(connect((state) => ({
  language: getLanguage(state),
}))(Tabs));
