/* eslint-disable camelcase, max-len */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { addCommasToNumbersInText } from '@wfp/freerice-core/utils/parsing';
import { findLastIndex, floor } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';

import { getLanguage } from '@wfp/freerice-core/modules/app';

import Button from 'components/pages/common/Button';
import BoxInfo from 'components/pages/common/box/BoxInfo';

import './ChallengeItem.scss';
import acceptedIcon from 'res/images/challenges/accepted.png';
import clockIcon from 'res/images/challenges/clock.png';
import groupIcon from 'res/images/challenges/group.png';
import speed0Icon from 'res/images/challenges/speed-0.png';
import speed10Icon from 'res/images/challenges/speed-10.png';
import speed30Icon from 'res/images/challenges/speed-30.png';
import speed50Icon from 'res/images/challenges/speed-50.png';
import speed70Icon from 'res/images/challenges/speed-70.png';
import speed90Icon from 'res/images/challenges/speed-90.png';
import speed100Icon from 'res/images/challenges/speed-100.png';

import strings from 'res/strings';

export const CHALLENGE_ITEM_SHOW_MODES = {
  TEASER: 'TEASER',
  FULL: 'FULL',
};

const ChallengeItem = ({
  id,
  showMode,
  user,
  userContribution,
  attributes,
  isChallengesLoading,
  isUserContributionsLoading,
  handleActionClick,
  language,
}) => {
  const {
    image,
    anonymous_play,
    title,
    description,
    participants,
    rice_raised,
    rice_goal,
    milestones,
    datetime_start,
    datetime_end,
  } = attributes;
  const currentLevel = !!userContribution && findLastIndex(milestones, milestone => userContribution.rice >= milestone);
  const currentMeals = !!userContribution && floor(userContribution.rice / 250);
  const totalMeals = floor(rice_raised / 250);
  const challengeItemClass = language === 'ar' ? 'challenge-item-rtl' : 'challenge-item';
  const currentGrainsText = strings.formatString(strings['challenges.grains'], {
    count: userContribution ? userContribution.rice : 0,
  });
  const currentMealsText =
    currentMeals === 1
      ? strings['challenges.meal']
      : strings.formatString(strings['challenges.meals'], {
        count: currentMeals,
      });
  const totalMealsText =
    totalMeals === 1
      ? strings['challenges.meal']
      : strings.formatString(strings['challenges.meals'], {
        count: totalMeals,
      });

  const percentProgress = floor(rice_raised / (rice_goal / 100));
  let speedIcon = speed0Icon;
  if (percentProgress >= 100) speedIcon = speed100Icon;
  else if (percentProgress >= 90) speedIcon = speed90Icon;
  else if (percentProgress >= 70) speedIcon = speed70Icon;
  else if (percentProgress >= 50) speedIcon = speed50Icon;
  else if (percentProgress >= 30) speedIcon = speed30Icon;
  else if (percentProgress >= 10) speedIcon = speed10Icon;

  const timeBeforeStart = moment.duration(moment.unix(datetime_start).diff(moment()));
  const timeBeforeStartAsMinutes = timeBeforeStart.asMinutes();
  const timeLeft = moment.duration(moment.unix(datetime_end).diff(moment()));
  const timeLeftAsMinutes = timeLeft.asMinutes();
  const isFinished = !!(timeLeftAsMinutes < 0);
  let timeString;

  const monthsLeft = Math.abs(timeLeft.get('months'));
  const daysLeft = Math.abs(timeLeft.get('days'));
  const hoursLeft = Math.abs(timeLeft.get('hours'));
  const minutesLeft = Math.abs(timeLeft.get('minutes'));
  const monthsLeftString =
    monthsLeft !== 1
      ? strings.formatString(strings['challenges.months'], { count: monthsLeft })
      : strings['challenges.month'];
  const daysLeftString =
    daysLeft !== 1 ? strings.formatString(strings['challenges.days'], { count: daysLeft }) : strings['challenges.day'];
  const hoursLeftString =
    hoursLeft !== 1
      ? strings.formatString(strings['challenges.hours'], { count: hoursLeft })
      : strings['challenges.hour'];
  const minutesLeftString =
    minutesLeft !== 1
      ? strings.formatString(strings['challenges.minutes'], { count: minutesLeft })
      : strings['challenges.minute'];

  const daysBeforeStart = Math.abs(timeBeforeStart.get('days'));
  const hoursBeforeStart = Math.abs(timeBeforeStart.get('hours'));
  const minutesBeforeStart = Math.abs(timeBeforeStart.get('minutes'));

  const daysBeforeStartString =
    daysBeforeStart !== 1
      ? strings.formatString(strings['challenges.days'], { count: daysBeforeStart })
      : strings['challenges.day'];
  const hoursBeforeStartString =
    hoursBeforeStart !== 1
      ? strings.formatString(strings['challenges.hours'], { count: hoursBeforeStart })
      : strings['challenges.hour'];
  const minutesBeforeStartString =
    minutesBeforeStart !== 1
      ? strings.formatString(strings['challenges.minutes'], { count: minutesBeforeStart })
      : strings['challenges.minute'];

  if (isFinished) {
    timeString = strings['challenges.challengeIsOver'];
  } else if (timeBeforeStartAsMinutes > 60 * 24) {
    timeString = strings.formatString(strings['challenges.startsInDaysHours'], {
      days: daysBeforeStartString,
      hours: hoursBeforeStartString,
    });
  } else if (timeBeforeStartAsMinutes > 60) {
    timeString = strings.formatString(strings['challenges.startsInHoursMinutes'], {
      hours: hoursBeforeStartString,
      minutes: minutesBeforeStartString,
    });
  } else if (timeBeforeStartAsMinutes > 0) {
    timeString = strings.formatString(strings['challenges.startsInMinutes'], {
      minutes: minutesBeforeStartString,
    });
  } else if (monthsLeft > 0) {
    timeString = strings.formatString(strings['challenges.leftMonthsDaysHours'], {
      days: daysLeftString,
      hours: hoursLeftString,
      months: monthsLeftString,
    });
  } else if (timeLeftAsMinutes > 60 * 24) {
    timeString = strings.formatString(strings['challenges.leftDaysHours'], {
      days: daysLeftString,
      hours: hoursLeftString,
    });
  } else if (timeLeftAsMinutes > 60) {
    timeString = strings.formatString(strings['challenges.leftHoursMinutes'], {
      hours: hoursLeftString,
      minutes: minutesLeftString,
    });
  } else if (timeLeftAsMinutes > 0) {
    timeString = strings.formatString(strings['challenges.leftMinutes'], {
      minutes: minutesLeftString,
    });
  }

  const ActionButton = useMemo(() => {
    if (showMode === CHALLENGE_ITEM_SHOW_MODES.FULL && timeLeftAsMinutes > 0) {
      if (anonymous_play) {
        if (!userContribution) {
          return (
            <Button
              isLoading={isChallengesLoading || isUserContributionsLoading}
              isDisabled={isChallengesLoading || isUserContributionsLoading}
              color="blue"
              text={strings['challenges.join']}
              handlerClick={handleActionClick}
            />
          );
        }
        return false;
      }
      if (!!user && !!user.uuid) {
        if (userContribution) {
          return (
            <Button
              isLoading={isChallengesLoading || isUserContributionsLoading}
              isDisabled={isChallengesLoading || isUserContributionsLoading}
              color="red"
              text={strings['challenges.leave']}
              handlerClick={handleActionClick}
            />
          );
        }
        return (
          <Button
            isLoading={isChallengesLoading || isUserContributionsLoading}
            isDisabled={isChallengesLoading || isUserContributionsLoading}
            color="blue"
            text={strings['challenges.join']}
            handlerClick={handleActionClick}
          />
        );
      }
      return <Button color="blue" text={strings['challenges.join']} to="/profile" />;
    }

    return null;
  }, [
    user,
    anonymous_play,
    showMode,
    userContribution,
    handleActionClick,
    isChallengesLoading,
    timeLeftAsMinutes,
    timeBeforeStartAsMinutes,
  ]);

  return (
    <div>
      <BoxInfo type="medium-width" className={`${challengeItemClass} ${challengeItemClass}--container`}>
        <div
          className={`${challengeItemClass}--${image ? 'image' : 'no-image'} ${showMode}`}
          style={image ? { backgroundImage: `url(${image})` } : undefined}
        >
          <div className="action-button">{ActionButton}</div>

          <div className={classNames('content-block', userContribution ? 'joined' : 'not-joined')}>
            {(!userContribution || showMode === CHALLENGE_ITEM_SHOW_MODES.TEASER) && (
              <div className="title-block">
                <h2>{title}</h2>
                {description.split('\n\n').map(p => (
                  <p>{p}</p>
                ))}
              </div>
            )}

            {showMode === CHALLENGE_ITEM_SHOW_MODES.FULL && (
              <div className="stats-block">
                <div className="time-left time-icon">
                  <img src={clockIcon} alt={timeString} />
                </div>
                <div className="time-left time-text">
                  <p>{timeString}</p>
                </div>
                <div className="players-raised speed-icon">
                  <img src={speedIcon} alt={percentProgress} />
                  <p>
                    {strings.formatString(strings['challenges.percent'], {
                      percent: percentProgress,
                    })}
                  </p>
                </div>
                <div className="players-raised speed-text">
                  <p>
                    {strings.formatString(
                      isFinished ? strings['challenges.grainsRaised'] : strings['challenges.grainsSoFar'],
                      {
                        count: <b>{addCommasToNumbersInText(String(rice_raised))}</b>,
                      },
                    )}
                  </p>
                </div>

                <div className="players-participated group-icon">
                  <img src={groupIcon} alt={String(participants)} />
                </div>
                <div className="players-participated group-text">
                  <p>
                    {strings.formatString(
                      isFinished ? strings['challenges.peopleParticipated'] : strings['challenges.peopleParticipating'],
                      {
                        count: <b>{addCommasToNumbersInText(String(participants))}</b>,
                      },
                    )}
                  </p>
                </div>
              </div>
            )}

            {id !== '10443d68-6f4f-458c-bd6c-bed6d31f7634' &&
              id !== 'd347bcf4-4b04-489f-a54b-5b254c34ef64' &&
              !!userContribution &&
              showMode === CHALLENGE_ITEM_SHOW_MODES.FULL && (
              <div className="personal-block">
                <h3>{isFinished ? strings['challenges.howDidYou'] : strings['challenges.howAreYou']}</h3>
                <div className="milestones-block">
                  <div className="person-level-block">
                    <div className={`persona-icon ${user.avatar}`} />
                    <div className="current-level-block-triangle">
                      <div className="current-level-block-triangle-white" />
                    </div>
                    <div className="current-level-block">
                      <p>
                        {strings.formatString(strings['challenges.level'], {
                          level: currentLevel + 1,
                        })}
                      </p>
                    </div>
                  </div>

                  {milestones.map((milestone, index) => (
                    <div
                      key={milestone}
                      className={classNames('level-block', {
                        achieved: currentLevel > index,
                        current: index === currentLevel,
                        'in-progress': index === currentLevel + 1,
                      })}
                    >
                      <p>{index + 1}</p>
                    </div>
                  ))}
                </div>
                <div className="contributed-block">
                  <p>
                    {strings.formatString(
                      isFinished ? strings['challenges.youContributed'] : strings['challenges.youHaveContributed'],
                      {
                        count: userContribution.rice,
                      },
                    )}
                  </p>
                </div>
              </div>
            )}

            {(id === '10443d68-6f4f-458c-bd6c-bed6d31f7634' || id === 'd347bcf4-4b04-489f-a54b-5b254c34ef64') &&
              !!userContribution &&
              showMode === CHALLENGE_ITEM_SHOW_MODES.FULL && (
              <div className="personal-block">
                <h3>{isFinished ? strings['challenges.howDidYou'] : strings['challenges.howAreYou']}</h3>
                <div className="milestones-block">
                  <div className="person-level-block">
                    <div className={`persona-icon ${user.avatar}`} />
                    <div className="current-level-block-triangle">
                      <div className="current-level-block-triangle-white" />
                    </div>
                    <div className="current-level-block">
                      <p>{currentMealsText}</p>
                    </div>
                  </div>

                  {milestones.map((milestone, index) => (
                    <div
                      key={milestone}
                      className={classNames('level-block', {
                        achieved: currentMeals > index + 1 && index < 4,
                        current: index + 1 === currentMeals || currentMeals > 5,
                        'in-progress': index + 1 === currentMeals + 1,
                      })}
                    >
                      <p>{index + 1}</p>
                    </div>
                  ))}
                </div>
                <div className="contributed-block">
                  <p>
                      You&apos;ve contributed
                    {' '}
                    <b>{currentGrainsText}</b>
                    {' '}
so far — that&apos;s
                    {' '}
                    <b>{currentMealsText}</b>
!
                      And Citi volunteers have unlocked
                    {' '}
                    <b>{totalMealsText}</b>
                    {' '}
in total. Keep going, you&apos;re making
                      a difference!
                  </p>
                </div>
              </div>
            )}

            {!!userContribution && showMode === CHALLENGE_ITEM_SHOW_MODES.TEASER && (
              <>
                <div className="accepted-block">
                  <h3>{strings['challenges.accepted']}</h3>
                  <img src={acceptedIcon} alt={strings['challenges.accepted']} />
                </div>
                <Button
                  className="view-stats-button-block"
                  color="green"
                  isOutlined
                  text={strings['challenges.viewStats']}
                  to={`/challenge/${id}`}
                />
              </>
            )}

            {!userContribution && showMode === CHALLENGE_ITEM_SHOW_MODES.TEASER && (
              <Button
                className="view-stats-button-block"
                color="green"
                text={strings['challenges.viewChallenge']}
                to={`/challenge/${id}`}
              />
            )}
          </div>
        </div>
      </BoxInfo>
    </div>
  );
};

ChallengeItem.propTypes = {
  language: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  attributes: PropTypes.object.isRequired,
  userContribution: PropTypes.object,
  user: PropTypes.object,
  handleActionClick: PropTypes.func.isRequired,
  showMode: PropTypes.string,
  isChallengesLoading: PropTypes.bool,
  isUserContributionsLoading: PropTypes.bool,
};

ChallengeItem.defaultProps = {
  showMode: CHALLENGE_ITEM_SHOW_MODES.TEASER,
  userContribution: null,
  user: null,
  isChallengesLoading: false,
  isUserContributionsLoading: false,
};

export default connect((state) => ({
  language: getLanguage(state),
}))(ChallengeItem);
