import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import BoxInfo from 'components/pages/common/box/BoxInfo';
import BackButton from 'components/app/BackButton';
import LeaderBoardContent from 'components/pages/leaderboard/Leaderboard';
import withPageTitle from 'containers/common/withPageTitle';
import {
  getUsersRanking,
  getGroupsRanking,
  isLeaderboardLoading,
  loadLeaderboard,
} from '@wfp/freerice-core/modules/leaderboard';
import { getUserAccount } from '@wfp/freerice-core/modules/user';
import { withTabOrder, tabOrders, TAB_NAMES } from 'components/app/TabOrder';
import strings from 'res/strings';

class Leaderboard extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    users: PropTypes.array,
    groups: PropTypes.array,
    loadLeaderboard: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    currentUser: PropTypes.object,
    setTabOrder: PropTypes.func.isRequired,
    focusTo: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {
    users: null,
    groups: null,
    currentUser: null,
  };

  componentDidMount() {
    const { users, loadLeaderboard, setTabOrder, focusTo, location } = this.props;

    setTabOrder(tabOrders.LEADERBOARD);

    if (location.state && location.state.focusAfter) focusTo(TAB_NAMES.MENU_TOGGLE);

    if (!users) {
      loadLeaderboard();
    }
  }

  backToGameHandler = () => {
    const { history } = this.props;
    history.push('/');
  };

  render() {
    const { users, groups, currentUser, isLoading } = this.props;
    return (
      <BoxInfo type="with-tabs" className="box-info--medium-width">
        <LeaderBoardContent users={users} groups={groups} currentUser={currentUser} isLoading={isLoading} />
        <BackButton />
      </BoxInfo>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  users: getUsersRanking,
  groups: getGroupsRanking,
  isLoading: isLeaderboardLoading,
  currentUser: getUserAccount,
});

export default compose(
  withPageTitle({ title: strings.leaderboard_link }),
  withTabOrder,
  connect(
    mapStateToProps,
    { loadLeaderboard }
  )
)(Leaderboard);
