import _objectWithoutProperties from "/home/vsts/work/1/s/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import { createSelector } from 'reselect';
import { produce } from 'immer';
import { createRequestTypes } from '../utils/actionHelpers';
import { createApiCall } from '../utils/api';
import { getCategories } from './categories'; // eslint-disable-next-line import/no-cycle

import { USER } from './user';
import { APP, getLanguage } from './app';
var spread = produce(Object.assign); // region ACTION TYPES

var base = 'GAME';
export var GAME = {
  CREATE_GAME: createRequestTypes("".concat(base, "_CREATE_GAME")),
  LOAD_GAME: createRequestTypes("".concat(base, "_LOAD_GAME")),
  SUBMIT_ANSWER: createRequestTypes("".concat(base, "_SUBMIT_ANSWER")),
  SET_CATEGORY: createRequestTypes("".concat(base, "_SET_CATEGORY")),
  LOAD_ADS: createRequestTypes('LOAD_ADS')
}; // endregion
// region ACTIONS

export var createGame = createApiCall(GAME.CREATE_GAME, function (category) {
  var level = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var user = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var lang = arguments.length > 3 ? arguments[3] : undefined;
  return {
    url: '/games',
    method: 'POST',
    params: {
      lang: lang
    },
    data: {
      category: category,
      level: level,
      user: user
    }
  };
});
export var loadGame = createApiCall(GAME.LOAD_GAME, function (gameId, lang) {
  return {
    url: "/games/".concat(gameId),
    method: 'GET',
    params: {
      lang: lang
    }
  };
});
export var submitAnswer = createApiCall(GAME.SUBMIT_ANSWER, function (gameId, data, lang) {
  return {
    url: "/games/".concat(gameId, "/answer"),
    method: 'PATCH',
    params: {
      lang: lang
    },
    data: data,
    withCredentials: true
  };
});
export var changeCategory = createApiCall(GAME.SET_CATEGORY, function (gameId, data, lang) {
  return {
    url: "/games/".concat(gameId, "/category"),
    method: 'PATCH',
    params: {
      lang: lang
    },
    data: data
  };
}); // export const loadAdList = createApiCall(GAME.LOAD_ADS, '/api/ads');

export var loadAdList = createApiCall(GAME.LOAD_ADS, function () {
  return {
    url: '/api/ads'
  };
}, {
  apiType: 'accounts'
}); // endregion
// region REDUCER

var initialState = {
  gameId: null,
  gameInfo: {
    category: null,
    level: 1
  },
  prevQuestion: null,
  curQuestion: null,
  answer: {
    correct: false
  },
  notifications: [],
  adList: [],
  loading: false,
  error: null
};
/* eslint-disable consistent-return */

export default produce(function (draft, _ref) {
  var type = _ref.type,
      payload = _ref.payload;

  switch (type) {
    case APP.STARTING:
      return spread(initialState, draft);

    case GAME.CREATE_GAME.REQUEST:
    case GAME.LOAD_GAME.REQUEST:
    case GAME.SET_CATEGORY.REQUEST:
      draft.error = null;
      draft.loading = true;
      break;

    case GAME.SUBMIT_ANSWER.REQUEST:
      draft.loading = true;
      draft.prevQuestion = draft.curQuestion; // draft.curQuestion = null;
      // draft.answer = { correct: false };

      break;

    case GAME.CREATE_GAME.SUCCESS:
    case GAME.LOAD_GAME.SUCCESS:
    case GAME.SUBMIT_ANSWER.SUCCESS:
    case GAME.SET_CATEGORY.SUCCESS:
      {
        var id = payload.id,
            question = payload.question,
            questionId = payload.question_id,
            answer = payload.answer,
            notifications = payload.notifications,
            gameInfo = _objectWithoutProperties(payload, ["id", "question", "question_id", "answer", "notifications"]);

        draft.loading = false;
        draft.gameInfo = produce(Object.assign)(draft.gameInfo, gameInfo);
        draft.gameId = id;
        draft.curQuestion = question || {};
        draft.curQuestion.id = questionId;
        draft.answer = answer;
        draft.notifications = notifications || [];

        if (type === GAME.SUBMIT_ANSWER.SUCCESS) {
          draft.answer.questionId = draft.prevQuestion.id;
        }

        break;
      }

    case GAME.CREATE_GAME.FAILURE:
    case GAME.LOAD_GAME.FAILURE:
    case GAME.SUBMIT_ANSWER.FAILURE:
    case GAME.SET_CATEGORY.FAILURE:
      draft.loading = false;
      draft.error = payload.message;
      break;

    case USER.SIGN_OUT:
    case USER.SIGN_IN.SUCCESS:
    case USER.LOGIN_FACEBOOK.SUCCESS:
    case USER.SIGN_UP.SUCCESS:
      draft.level = 1;
      draft.gameId = null;
      draft.category = process.env.REACT_APP_DEFAULT_CATEGORY;
      draft.prevQuestion = null;
      draft.curQuestion = null;
      draft.answer = {
        correct: false
      };
      break;

    case GAME.LOAD_ADS.SUCCESS:
      draft.adList = payload;
      break;

    default:
      break;
  }
}, initialState);
/* eslint-enable consistent-return */
// endregion
// region SELECTORS

var gameState = function gameState(state) {
  return state.game;
};

export var getGameID = createSelector(gameState, function (game) {
  return game.gameId;
});
export var getGameInfo = createSelector(gameState, function (game) {
  return game.gameInfo;
});
export var getGameCategory = createSelector(getCategories, getGameInfo, getLanguage, function (categories, _ref2, preferenceLanguage) {
  var category = _ref2.category,
      defaultCategory = _ref2.defaultCategory,
      listLangForCategories = _ref2.listLangForCategories;
  // Basic search.
  var firstSearch = categories.find(function (c) {
    return c.id === category || c.machine_name === category;
  });
  var defaultSearch = categories.find(function (c) {
    return c.machine_name === defaultCategory;
  }); // Search for IE browser.

  var preferencedSearch = preferenceLanguage && categories.find(function (c) {
    return c.id === listLangForCategories[preferenceLanguage] || c.machine_name === listLangForCategories[preferenceLanguage];
  });
  return firstSearch || defaultSearch || preferencedSearch;
});
export var getGameLevel = createSelector(getGameInfo, function (gameInfo) {
  return gameInfo.level;
});
export var getGameRiceCount = createSelector(getGameInfo, function (gameInfo) {
  return gameInfo.rice;
});
export var getGameNotifications = createSelector(gameState, function (game) {
  return game.notifications;
});
export var getPrevQuestion = createSelector(gameState, function (state) {
  return state.prevQuestion;
});
export var getCurrentQuestion = createSelector(gameState, function (state) {
  return state.curQuestion;
});
export var getLastAnswer = createSelector(gameState, function (state) {
  return state.answer;
});
export var isGameLoading = createSelector(gameState, function (state) {
  return state.loading;
});
export var getGameError = createSelector(gameState, function (state) {
  return state.error;
});
export var getAdList = createSelector(gameState, function (state) {
  return state.adList;
}); // endregion