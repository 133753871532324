import React from 'react';
import PropTypes from 'prop-types';
import './Backdrop.scss';

const Backdrop = ({ handlerClick }) => <div className="backdrop" onClick={handlerClick} />;

Backdrop.propTypes = {
  handlerClick: PropTypes.func.isRequired,
};

export default Backdrop;
