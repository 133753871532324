import _objectWithoutProperties from "/home/vsts/work/1/s/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import _defineProperty from "/home/vsts/work/1/s/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import isPlainObject from 'lodash/isPlainObject';
import pick from 'lodash/pick';
import { createAction } from './actionHelpers';
export var API_CALL = 'API_CALL';
/**
 * Generic helper to create payload for the api request action.
 *
 * @param {Array} apiActions Set of request actions to dispatch after api call.
 * @param {object} optionsCb Parameter for axios.
 * @param {object} meta (optional)
 */

export var createApiCall = function createApiCall(apiActions, optionsCb) {
  var meta = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return createAction(API_CALL, function () {
    switch (typeof optionsCb) {
      case 'function':
        return optionsCb.apply(void 0, arguments);

      case 'string':
        return {
          url: optionsCb
        };

      default:
        return optionsCb;
    }
  }, function () {
    return _objectSpread(_objectSpread({}, typeof meta === 'function' ? meta.apply(void 0, arguments) : meta), {}, {
      actions: apiActions
    });
  });
};
/**
 * Transforms JSON API Entity model into more simple and liniar object.
 * @param {object} data JSON API Entity Model
 */

var entityTransformer = function entityTransformer(_ref) {
  var links = _ref.links,
      attributes = _ref.attributes,
      fields = _objectWithoutProperties(_ref, ["links", "attributes"]);

  return _objectSpread(_objectSpread({}, fields), attributes);
};
/**
 * Transforms JSON API response to more simple model.
 * @param {Array|object} json JSON response
 */


export var transformJsonAPI = function transformJsonAPI(json) {
  var data = isPlainObject(json) && 'data' in json ? json.data : json;

  if (Array.isArray(data)) {
    return data.map(entityTransformer);
  }

  if (isPlainObject(data) && 'attributes' in data) {
    return entityTransformer(data);
  }

  return data;
};
export var transformUser = function transformUser(_ref2) {
  var userData = _ref2.userData,
      other = _objectWithoutProperties(_ref2, ["userData"]);

  return pick(_objectSpread(_objectSpread({}, other), userData), ['avatar', 'displayname', 'email', 'username', 'token', 'uuid', 'badges', 'rank', 'rice', 'external']);
};