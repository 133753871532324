import { createSelector } from 'reselect';
import { produce } from 'immer';
import { createRequestTypes } from '../utils/actionHelpers';
import { createApiCall } from '../utils/api'; // region ACTION TYPES

export var LEVELS = {
  LOAD_LIST: createRequestTypes('LEVELS_LOAD_LIST')
}; // endregion
// region ACTIONS

export var loadLevels = createApiCall(LEVELS.LOAD_LIST, function (_ref) {
  var lang = _ref.lang;
  return {
    url: '/levels',
    params: {
      lang: lang
    },
    method: 'GET'
  };
}, {
  apiType: 'engine'
}); // endregion
// region REDUCER

var initialState = {
  levels: [],
  loading: false,
  error: null
};
export default produce(function (draft, _ref2) {
  var type = _ref2.type,
      payload = _ref2.payload;

  switch (type) {
    case LEVELS.LOAD_LIST.REQUEST:
      draft.loading = true;
      break;

    case LEVELS.LOAD_LIST.SUCCESS:
      draft.loading = false;
      draft.levels = payload;
      break;

    case LEVELS.LOAD_LIST.FAILURE:
      draft.loading = false;
      draft.error = payload.message;
      break;

    default:
      break;
  }
}, initialState); // endregion
// region SELECTORS

var levelsState = function levelsState(state) {
  return state.levels;
};

export var getLevels = createSelector(levelsState, function (state) {
  return state.levels;
}); // endregion