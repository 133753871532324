import React from 'react';
import PropTypes from 'prop-types';

const { Consumer, Provider } = React.createContext({ animated: false });

export class AnimatedNavProvider extends React.PureComponent {
  static propTypes = {
    children: PropTypes.any.isRequired,
  };

  /* eslint-disable react/no-unused-state,react/sort-comp */
  state = {
    animated: false,
    setAnimationState: (animated, cb) => this.setState({ animated }, cb),
  };
  /* eslint-enable react/no-unused-state */

  render() {
    const { children } = this.props;

    return <Provider value={this.state}>{children}</Provider>;
  }
}

export const AnimatedNavConsumer = Consumer;

export const withAnimatedNav = WrappedComponent => props => (
  <AnimatedNavConsumer>{context => <WrappedComponent {...props} animatedNav={context} />}</AnimatedNavConsumer>
);
