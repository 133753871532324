import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLanguage } from '@wfp/freerice-core/modules/app';
import BoxInfo from 'components/pages/common/box/BoxInfo';
import Button from 'components/pages/common/Button';
import BoxInfoError from 'components/pages/common/box/BoxInfoError/BoxInfoError';
import strings from 'res/strings';

import Input from '../common/Input';

import './SignUpContent.scss';

const CitiSignupContent = ({ language, onSubmit }) => {
  const [error, setError] = useState('');
  const [zipCode, setZipCode] = useState('');
  const screenClass = language === 'ar' ? 'citi-signup-rtl' : 'citi-signup';

  const handleSubmit = useCallback(() => {
    if (!zipCode && zipCode.length < 5) {
      setError(strings['citi_signup.zip_code.invalid']);
      return;
    }
    onSubmit({ zipCode });
  }, [onSubmit, zipCode]);

  return (
    <BoxInfo type={`medium-width ${screenClass}`}>
      <p>{strings['citi_signup.welcome_text']}</p>
      <Input
        id="citi-zip"
        type="text"
        name="zip"
        maxLength={10}
        value={zipCode}
        placeholder={strings['citi_signup.zip_code.placeholder']}
        handlerChange={e => setZipCode(e.target.value)}
        label={strings['citi_signup.zip_code.label']}
      />
      {!!error && <BoxInfoError messages={[error]} />}
      <br />
      <Button
        className={`${screenClass}-save-button`}
        color="green"
        text={strings['citi_signup.button.submit']}
        handlerClick={handleSubmit}
      />
    </BoxInfo>
  );
};

CitiSignupContent.propTypes = {
  language: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

CitiSignupContent.defaultProps = {
  language: strings.getLanguage(),
};

export default connect(state => ({
  language: getLanguage(state),
}))(CitiSignupContent);
