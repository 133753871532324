import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _padStart from 'lodash/padStart';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { getLanguage } from '@wfp/freerice-core/modules/app';

import BoxInfoContent from 'components/pages/common/box/BoxInfoContent';
import Tabs from 'components/pages/common/tab/Tabs';
import Button from 'components/pages/common/Button';
import UserItem from 'components/pages/groups/group/UserItem';
import BoxInfoProgressBar from 'components/pages/common/box/BoxInfoProgressBar';
import './GroupContent.scss';
import { withTabOrder } from 'components/app/TabOrder';

import strings from 'res/strings';

class GroupContent extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    group: PropTypes.object.isRequired,
    handlerLeaveGroup: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    isLoadingGroupMembers: PropTypes.bool,
    loadMembers: PropTypes.func.isRequired,
    language: PropTypes.string,
    tabOrder: PropTypes.object.isRequired,
  };

  static defaultProps = {
    loading: false,
    isLoadingGroupMembers: false,
    language: strings.getLanguage(),
  };

  tabs = [
    { name: 'my-group', label: strings['myGroup.web.title'] },
    { name: 'stats', label: strings['stats.web.title'] },
  ];

  updateUserItems = page => {
    const {
      loadMembers,
      isLoadingGroupMembers,
      group: { membersPagination },
    } = this.props;
    if (!isLoadingGroupMembers && (!membersPagination || page <= membersPagination.total_pages)) {
      loadMembers(page);
    }
  };

  renderUserItems() {
    const { user, group } = this.props;

    return (group.members || []).map(({ id, uuid, rice, name, avatar }, index) => (
      <UserItem
        key={`user-item-${id}`}
        rice={rice}
        avatar={avatar}
        username={uuid === user.uuid ? strings['stats.text.you'] : name}
        rank={index + 1}
      />
    ));
  }

  render() {
    const { handlerLeaveGroup, loading, group, isLoadingGroupMembers, language, tabOrder } = this.props;
    const { membersPagination: pagination } = group;
    const hasMore = (pagination && pagination.total_pages > pagination.current_page) || !pagination;
    const userItemsClass = language === 'ar' ? 'user-items-rtl' : 'user-items';
    const groupTextBlock = language === 'ar' ? 'group-text-block-rtl' : 'group-text-block';

    return (
      <Tabs tabs={this.tabs}>
        {activeTab => (
          <>
            <BoxInfoContent className="profile-content">
              <h2 className="group-name">{group.name}</h2>
              {activeTab === 'my-group' && (
                <div className="panel">
                  <BoxInfoProgressBar isVisible={loading}>
                    <div className="my-group">
                      <p className={groupTextBlock}>
                        {strings['myGroup.text.code']}
                        <strong className="group-code">{group.code}</strong>
                      </p>
                      <p className={groupTextBlock}>
                        {strings['myGroup.instructions.1']}
                      </p>
                      <p className={groupTextBlock}>
                        {strings['myGroup.instructions.2']}
                      </p>
                      <Button
                        tabIndex={tabOrder.EDIT_GROUP_BUTTON}
                        color="green"
                        text={strings['myGroup.button.edit']}
                        to={`/group-edit/${group.uuid}`}
                      />
                      <Button
                        tabIndex={tabOrder.LEAVE_GROUP_BUTTON}
                        color="red"
                        text={strings['myGroup.button.leave']}
                        handlerClick={handlerLeaveGroup}
                      />
                    </div>
                  </BoxInfoProgressBar>
                </div>
              )}
              {activeTab === 'stats' && (
                <div className="panel">
                  <center>
                    <div className="group-stats">
                      <div className="w-100 box-info__counter">
                        <p className="box-info__counter__label">{strings['stats.text.donated']}</p>
                        <p className="box-info__counter__rice">{(group.rice || 0).toLocaleString()}</p>
                      </div>
                      <div className="w-50 box-info__counter">
                        <p className="box-info__counter__label">{strings['stats.web.text.overallRank']}</p>
                        <p className="box-info__counter__rank">
                          {group.rank ? _padStart(group.rank.toLocaleString(), 2, '0') : 0}
                        </p>
                      </div>
                      <div className="w-50 box-info__counter">
                        <p className="box-info__counter__label">{strings['stats.web.text.yourRank']}</p>
                        <p className="box-info__counter__rank">
                          {group.personalRank && group.personalRank.rank
                            ? _padStart(group.personalRank.rank.toLocaleString(), 2, '0')
                            : 0}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <InfiniteScroll
                      pageStart={0}
                      loadMore={this.updateUserItems}
                      hasMore={hasMore}
                      getScrollParent={isLoadingGroupMembers ? () => false : undefined}
                      loader={<div key={0}>{strings['stats.text.loading']}</div>}
                    >
                      <ul className={userItemsClass}>{this.renderUserItems()}</ul>
                    </InfiniteScroll>
                  </center>
                </div>
              )}
            </BoxInfoContent>
          </>
        )}
      </Tabs>
    );
  }
}

export default withTabOrder(connect((state) => ({
  language: getLanguage(state),
}))(GroupContent));
