/* eslint-disable max-len,react/no-array-index-key */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { orderBy, uniq } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { getLanguage } from '@wfp/freerice-core/modules/app';
import { loadNotifications, getNotifications, loadAnnouncements, getAnnouncements } from '@wfp/freerice-core/modules/activityFeed';
import { getUserID } from '@wfp/freerice-core/modules/user';

import PageContainer from 'components/pages/common/PageContainer';
import BodyHeight from 'components/app/MobileBodyHeight';
import FeedItem from 'components/pages/activity-feed/FeedItem';

import './ActivityFeedContent.scss';

const ActivityFeedContent = ({ user, loadNotifications, notifications, loadAnnouncements, announcements, language }) => {
  useEffect(() => {
    if (user) loadNotifications({ id: user, lang: language });

    loadAnnouncements({ lang: language });
  }, []);

  useEffect(() => {
    const readedAnnouncements = JSON.parse(window.localStorage.getItem('readedAnnouncements')) || [];

    window.localStorage.setItem('readedAnnouncements', JSON.stringify(uniq([
      ...readedAnnouncements,
      ...announcements.map(announcement => announcement.date),
    ])));
  }, [announcements]);

  const feedItems = orderBy([...notifications.map(notification => ({
    date: notification.created_on,
    ...notification,
  })), ...announcements], ['date'], ['desc']);

  return (
    <PageContainer>
      <BodyHeight>
        {() => (
          <>
            {feedItems.map((attributes, key) => (
              <FeedItem key={key} id={String(key)} {...attributes} />
            ))}
          </>
        )}
      </BodyHeight>
    </PageContainer>
  );
};

ActivityFeedContent.propTypes = {
  user: PropTypes.string,
  notifications: PropTypes.array.isRequired,
  announcements: PropTypes.array.isRequired,
  loadNotifications: PropTypes.func.isRequired,
  loadAnnouncements: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
};

ActivityFeedContent.defaultProps = {
  user: null,
};

export default connect(
  createStructuredSelector({
    user: getUserID,
    notifications: getNotifications,
    announcements: getAnnouncements,
    language: getLanguage,
  }),
  { loadNotifications, loadAnnouncements }
)(ActivityFeedContent);
