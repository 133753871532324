import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _isObject from 'lodash/isPlainObject';

import BoxInfoContent from 'components/pages/common/box/BoxInfoContent';
import BoxInfoTitle from 'components/pages/common/box/BoxInfoTitle';
import BoxInfoError from 'components/pages/common/box/BoxInfoError';
import BoxInfoProgressBar from 'components/pages/common/box/BoxInfoProgressBar';
import Button from 'components/pages/common/Button';
import Input from 'components/pages/common/Input';
import './GroupsContent.scss';
import { withTabOrder } from 'components/app/TabOrder';
import strings from 'res/strings';

class GroupsContent extends Component {
  static propTypes = {
    handlerMakeNewGroup: PropTypes.func.isRequired,
    handlerJoinGroup: PropTypes.func.isRequired,
    numberOfGroups: PropTypes.number.isRequired,
    showMyGroupsButton: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    isLoadingGroups: PropTypes.bool.isRequired,
    groupsErrors: PropTypes.shape({
      message: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
    }),
    tabOrder: PropTypes.object.isRequired,
  };

  static defaultProps = {
    groupsErrors: null,
  };

  state = { name: '', code: '' };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  makeNewGroupHandler = () => {
    const { name } = this.state;
    const { handlerMakeNewGroup } = this.props;

    this.setState({ name: '' }, () => {
      handlerMakeNewGroup({ name });
    });
  };

  joinGroupHandler = () => {
    const { code } = this.state;
    const { handlerJoinGroup } = this.props;

    handlerJoinGroup({ code });
    this.setState({ code: '' });
  };

  render() {
    const {
      numberOfGroups,
      showMyGroupsButton,
      errors,
      isLoadingGroups,
      user: { username = '' },
      groupsErrors,
      tabOrder,
    } = this.props;
    const { name, code } = this.state;

    return (
      <BoxInfoContent className="groups-content">
        <BoxInfoTitle text={strings.formatString(strings['header.user'], { username })} />
        <BoxInfoProgressBar isVisible={isLoadingGroups}>
          <p>
            {strings.formatString(strings['groups.text.groups'], { count: <strong>{numberOfGroups}</strong> })}
          </p>
          {showMyGroupsButton &&
            <Button
              tabIndex={tabOrder.GO_TO_MY_GROUPS}
              color="green"
              text={strings['groups.button.myGroups']}
              to="/my-groups"
            />
          }
          <div className="group-input-wrapper">
            <Input
              tabIndex={tabOrder.MAKE_NEW_GROUP_INPUT}
              id="group-name"
              type="text"
              name="name"
              value={name}
              isInputError={!!errors.newGroup}
              handlerChange={this.changeHandler}
              placeholder={strings['groups.input.groupName']}
            />
            <Button
              tabIndex={tabOrder.MAKE_NEW_GROUP_BUTTON}
              isDisabled={!name}
              color="blue"
              text={strings['groups.button.makeGroup']}
              className="make-new-group-btn"
              handlerClick={this.makeNewGroupHandler}
            />
            {_isObject(groupsErrors) && groupsErrors.category === 'create' && (
              <BoxInfoError messages={[groupsErrors.message]} />
            )}
            {errors.newGroup && <BoxInfoError messages={[errors.newGroup]} />}
          </div>
          <div className="group-input-wrapper">
            <Input
              tabIndex={tabOrder.JOIN_EXISTING_GROUP_INPUT}
              id="group-code"
              type="text"
              name="code"
              value={code}
              isInputError={!!errors.groupCode}
              handlerChange={this.changeHandler}
              placeholder={strings['groups.input.groupCode']}
            />
            <Button
              tabIndex={tabOrder.JOIN_EXISTING_GROUP_BUTTON}
              isDisabled={!code}
              color="cyan"
              text={strings['groups.button.joinGroup']}
              className="join-group-btn"
              handlerClick={this.joinGroupHandler}
            />
            {_isObject(groupsErrors) && groupsErrors.category === 'join' && (
              <BoxInfoError messages={[groupsErrors.message]} />
            )}
            {errors.groupCode && <BoxInfoError messages={[errors.groupCode]} />}
          </div>
        </BoxInfoProgressBar>
      </BoxInfoContent>
    );
  }
  /* eslint-enable camelcase */
}

export default withTabOrder(GroupsContent);
