import React from 'react';
import PropTypes from 'prop-types';

import './GroupItem.scss';

const GroupItem = ({ id, avatar, name, rice = 0, handlerClick, tabIndex }) => (
  <li
    tabIndex={tabIndex}
    className="group-item"
    onClick={handlerClick}
    onKeyPress={(e) => e.charCode === 13 && handlerClick(e)}
    data-id={id}
  >
    <span className={`group-item--icon group-icon-${avatar}`} />
    <div className="group-item-content">
      <span className="group-item-label">{name}</span>
      <span className="group-item-description">
        {(rice || 0).toLocaleString()}
      </span>
    </div>
  </li>
);

GroupItem.propTypes = {
  id: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  name: PropTypes.string.isRequired,
  rice: PropTypes.number,
  handlerClick: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
};

GroupItem.defaultProps = {
  avatar: null,
  rice: 0,
};

export default GroupItem;
