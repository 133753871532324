import identity from 'lodash/identity';
import isFunction from 'lodash/isFunction';
import isNull from 'lodash/isNull';
export var createAction = function createAction(type) {
  var payloadCreator = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : identity;
  var metaCreator = arguments.length > 2 ? arguments[2] : undefined;

  if (!isFunction(payloadCreator) && !isNull(payloadCreator)) {
    throw new Error('Expected payloadCreator to be a function, undefined or null');
  }

  var finalPayloadCreator = function () {
    if (isNull(payloadCreator) || payloadCreator === identity) {
      return identity;
    }

    return function (head) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      return payloadCreator.apply(void 0, [head].concat(args));
    };
  }();

  var hasMeta = isFunction(metaCreator);
  return function () {
    var payload = finalPayloadCreator.apply(void 0, arguments);
    var action = {
      type: type
    };

    if (payload !== undefined) {
      action.payload = payload;
    }

    if (hasMeta) {
      action.meta = metaCreator.apply(void 0, arguments);
    }

    return action;
  };
};
export var createRequestTypes = function createRequestTypes(base) {
  var extend = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var res = extend;
  ['REQUEST', 'SUCCESS', 'FAILURE'].forEach(function (type) {
    res[type] = "".concat(base, "_").concat(type);
  });
  return res;
};