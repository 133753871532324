import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import AboutContent from 'components/pages/about/AboutContent';
import withPageTitle from 'containers/common/withPageTitle';
import strings from 'res/strings';
import { withTabOrder, tabOrders, TAB_NAMES } from 'components/app/TabOrder';

const About = ({ setTabOrder, focusTo }) => {
  const { state } = useLocation();

  useEffect(() => {
    setTabOrder(tabOrders.ABOUT);

    if (state && state.focusAfter) focusTo(TAB_NAMES.MENU_TOGGLE);
  }, []);

  return (
    <AboutContent />
  );
};

About.propTypes = {
  setTabOrder: PropTypes.func.isRequired,
  focusTo: PropTypes.func.isRequired,
};

export default withTabOrder(withPageTitle({ title: strings.about_link })(About));
