/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MarkdownIt from 'markdown-it';
import { getLanguage } from '@wfp/freerice-core/modules/app';
import { getContent } from '@wfp/freerice-core/modules/about';
import BoxInfo from 'components/pages/common/box/BoxInfo';
import './AboutContent.scss';

import strings from 'res/strings';

const AboutContent = ({ language, content }) => {
  const boxStepClass = language === 'ar' ? 'box-step-rtl' : 'box-step';

  const md = new MarkdownIt();

  return (
    <BoxInfo type={`medium-width ${boxStepClass}`}>
      <div dangerouslySetInnerHTML={{ __html: md.render(content) }} />
    </BoxInfo>
  );
};

AboutContent.propTypes = {
  content: PropTypes.string,
  language: PropTypes.string,
};

AboutContent.defaultProps = {
  language: strings.getLanguage(),
  content: '',
};

export default connect((state) => ({
  language: getLanguage(state),
  content: getContent(state),
}))(AboutContent);
