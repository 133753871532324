import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import BoxInfoContent from 'components/pages/common/box/BoxInfoContent';
import './AnonymousContent.scss';
import { withTabOrder } from 'components/app/TabOrder';
import strings from 'res/strings';

const AnonymousContent = withTabOrder(({ tabOrder }) => (
  <BoxInfoContent>
    <h2>
      {strings['groups.anonymous.title']}
    </h2>
    <p>
      <br />
      {strings['groups.anonymous.text.start']}
      <Link tabIndex={tabOrder.ANONYMOUS_LOGIN} to="/profile-login" className="link--sign-in">
        {strings['groups.anonymous.text.signIn']}
      </Link>
      {strings['groups.anonymous.text.or']}
      <Link tabIndex={tabOrder.ANONYMOUS_SIGN_UP} to="/profile-register" className="link--sign-up">
        {strings['groups.anonymous.text.signUp']}
      </Link>
      {strings['groups.anonymous.text.end']}
    </p>
  </BoxInfoContent>
));

AnonymousContent.propTypes = {
  tabOrder: PropTypes.object.isRequired,
};

export default AnonymousContent;
