import React, { Component } from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import withPageTitle from 'containers/common/withPageTitle';
import BoxInfoImage from 'components/pages/common/box/BoxInfoImage';
import BackButton from 'components/app/BackButton';
import EditProfileContent from 'components/pages/profile/editProfile/EditProfileContent';
import IconList from 'components/pages/common/IconList';
import BoxInfoContent from 'components/pages/common/box/BoxInfoContent';
import { DeleteProfileBlock } from 'components/pages/profile/delete/DeleteProfileBlock';

import {
  getUserAccount,
  loadAccountData,
  clearUserErrors,
  updateUserData,
  isLoadingUser,
  getUserDataErrors,
} from '@wfp/freerice-core/modules/user';
import validate, { rulesMethods } from '@wfp/freerice-core/utils/validators';
import { withTabOrder, tabOrders, TAB_NAMES } from 'components/app/TabOrder';
import strings from 'res/strings';

const avatars = range(1, 17).map(item => `avatar-${item}`);

class EditProfile extends Component {
  static propTypes = {
    clearUserErrors: PropTypes.func.isRequired,
    loadAccountData: PropTypes.func.isRequired,
    user: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    updateUserErrors: PropTypes.array,
    updateUserData: PropTypes.func.isRequired,
    setTabOrder: PropTypes.func.isRequired,
    focusTo: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {
    user: {},
    updateUserErrors: [],
  };

  state = {
    validate: {
      messages: [],
      isUsername: false,
      isEmail: false,
    },
    progress: null,
    // eslint-disable-next-line react/destructuring-assignment
    avatar: this.props.user.avatar || '',
    isEditAvatar: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.progress === 'submitting' && nextProps.isLoading) {
      return { progress: 'savingChanges' };
    }
    if (prevState.progress === 'savingChanges' && !nextProps.isLoading) {
      return { progress: 'saved' };
    }
    return null;
  }

  componentDidMount() {
    const { user, loadAccountData, history, setTabOrder, focusTo, location } = this.props;

    setTabOrder(tabOrders.EDIT_PROFILE);

    if (location.state && location.state.focusAfter) focusTo(TAB_NAMES.MENU_TOGGLE);

    if (!user.uuid) {
      history.push('/profile-login');
    } else if (!user.displayname) {
      loadAccountData();
    }
  }

  componentWillUnmount() {
    const { clearUserErrors } = this.props;
    clearUserErrors();
  }

  saveChangesHandler = (username, email, password, confirmPassword) => {
    if (!this.isValid(username, email, password, confirmPassword)) {
      return;
    }

    const { updateUserData, user } = this.props;
    const { avatar } = this.state;

    this.setState({ progress: 'submitting' }, () => {
      updateUserData(user.uuid, { username, email, avatar, password });
    });
  };

  isValid = (username, email, password, confirmPassword) => {
    let messages = [];
    let isUsername = false;
    let isEmail = false;
    let isPassword = false;
    let isConfirmPassword = false;

    const data = { username, email, password, confirmPassword };

    messages = validate(
      data,
      {
        username: 'required',
        email: 'required|email',
        password: 'required|minLength:5',
        confirmPassword: 'required|match:password',
      },
      strings,
    );

    if (messages.length) {
      if (!rulesMethods.required(data, 'username')) {
        isUsername = true;
      }

      if (!rulesMethods.email(data, 'email')) {
        isEmail = true;
      }

      if (!rulesMethods.required(data, 'password') || !rulesMethods.minLength(data, 'password', 5)) {
        isPassword = true;
      }

      if (!rulesMethods.required(data, 'confirmPassword')) {
        isConfirmPassword = true;
      }

      if (!isPassword && !isConfirmPassword && password && !rulesMethods.match(data, 'password', 'confirmPassword')) {
        isConfirmPassword = true;
        isPassword = true;
      }
    }

    this.setState({
      validate: {
        messages,
        isUsername,
        isEmail,
        isPassword,
        isConfirmPassword,
      },
    });

    return !isUsername && !isEmail && !isPassword && !isConfirmPassword;
  };

  editAvatarHandler = (_, focusAfter) => {
    const { focusTo } = this.props;

    if (focusAfter) setTimeout(() => focusTo(TAB_NAMES.ICON_LIST_CLOSE_BUTTON), 250);

    this.setState({ isEditAvatar: true });
  };

  cancelEditAvatarHandler = (_, focusAfter = false) => {
    const { focusTo } = this.props;

    if (focusAfter) setTimeout(() => focusTo(TAB_NAMES.EDIT_BUTTON), 250);

    this.setState({ isEditAvatar: false });
  };

  avatarClickHandler = (event, focusAfter = false) => {
    const { focusTo } = this.props;

    if (focusAfter) setTimeout(() => focusTo(TAB_NAMES.EDIT_BUTTON), 250);

    this.setState({
      isEditAvatar: false,
      avatar: event.target.value,
    });
  };

  render() {
    const { user, isLoading, updateUserErrors } = this.props;
    const { isEditAvatar, validate, progress, avatar } = this.state;

    return (
      <>
        <BoxInfoImage
          imageType="profile-avatar"
          avatarId={avatar}
          editMode
          loading={isLoading}
          handlerClick={this.editAvatarHandler}
        >
          <BoxInfoContent>
            <EditProfileContent
              handlerSaveChanges={this.saveChangesHandler}
              isLoading={isLoading}
              validate={validate}
              updateUserErrors={updateUserErrors}
              progress={progress}
              data={user}
            />
            <DeleteProfileBlock />
          </BoxInfoContent>
          <BackButton />
        </BoxInfoImage>
        <IconList
          title={strings['editProfile.modal.title']}
          isVisible={isEditAvatar}
          avatars={avatars}
          handlerCancel={this.cancelEditAvatarHandler}
          handlerItemClick={this.avatarClickHandler}
        />
      </>
    );
  }
}

export default compose(
  withPageTitle({ title: strings['editProfile.web.title.profile'] }),
  withTabOrder,
  connect(
    createStructuredSelector({ user: getUserAccount, isLoading: isLoadingUser, updateUserErrors: getUserDataErrors }),
    { updateUserData, loadAccountData, clearUserErrors }
  )
)(EditProfile);
