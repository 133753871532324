import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BoxInfoTitle from 'components/pages/common/box/BoxInfoTitle';
import { withTabOrder } from 'components/app/TabOrder';
import CategoryItem from './CategoryItem';
import './Category.scss';

class Category extends Component {
  static propTypes = {
    currentCategory: PropTypes.object,
    onItemClick: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    startTabIndex: PropTypes.number.isRequired,
    selection: PropTypes.string,
  };

  static defaultProps = {
    currentCategory: null,
    selection: null,
  };

  renderItems = () => {
    const { items, onItemClick, selection, currentCategory, startTabIndex } = this.props;

    return items.map((item, index) => (
      <CategoryItem
        tabIndex={startTabIndex + index}
        key={item.id}
        data={item}
        onClick={onItemClick}
        isDisabled={currentCategory.id === item.id}
        selection={selection}
      />
    ));
  };

  render() {
    const { title } = this.props;
    return (
      <>
        <BoxInfoTitle className="category-label" text={title} />
        <div className="category">{this.renderItems()}</div>
      </>
    );
  }
}

export default withTabOrder(Category);
