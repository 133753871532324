import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import QuestionSingle from './QuestionSingle';
import QuestionCaptcha from './QuestionCaptcha';
import './Question.scss';

const slideOutTime = 550;

const CardQuestion = ({ question, ...props }) => (
  <div
    className={classNames('question', `question--${question.type}`, {
      'question--with-image': Array.isArray(question.resources) && question.resources.length,
    })}
  >
    {question.type === 'single-option' && <QuestionSingle question={question} delay={slideOutTime} {...props} />}
    {question.type === 'recaptcha' && <QuestionCaptcha question={question} delay={slideOutTime} {...props} />}
  </div>
);

CardQuestion.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default CardQuestion;
