import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getLanguage, setPreferenceLanguage } from '@wfp/freerice-core/modules/app';

import Menu from 'components/app/Menu';
import { ReactComponent as MenuCloseIcon } from 'res/images/navigation/close.svg';
import './SideDrawer.scss';

import strings from 'res/strings';
import { withTabOrder, TAB_NAMES } from 'components/app/TabOrder';

const SideDrawer = ({ user, show, closeDrawer, language, tabOrder, addFocus, history }) => {
  const sideDrawerClass = language === 'ar' ? 'side-drawer-rtl' : 'side-drawer';

  return (
    <nav className={classNames(sideDrawerClass, { open: show })}>
      <div className={`${sideDrawerClass}__container`}>
        <div
          tabIndex={tabOrder.SIDE_DRAWER_CLOSE}
          ref={elem =>
            addFocus(TAB_NAMES.SIDE_DRAWER_CLOSE, () => {
              if (elem) elem.focus();
            })
          }
          onKeyPress={() => closeDrawer({ focusAfter: true })}
          className={`${sideDrawerClass}__button-close`}
        >
          <MenuCloseIcon tabIndex={-1} onClick={closeDrawer} title="Menu Close" />
        </div>
        <div className={`${sideDrawerClass}__user`}>
          <div
            tabIndex={tabOrder.SIDE_DRAWER_PROFILE_LINK}
            onKeyPress={() =>
              closeDrawer({ focusAfter: false }) ||
              history.push(user.username ? '/profile' : '/profile-login', { focusAfter: true })
            }
          >
            <Link tabIndex={-1} to={user.username ? '/profile' : '/profile-login'} onClick={closeDrawer}>
              <span className={`${sideDrawerClass}__user-icon user-icon-${user.avatar || 'default'}`} />
            </Link>
          </div>
          <div className={`${sideDrawerClass}__user-content`}>
            <div className={`${sideDrawerClass}__user-salut`}>
              {strings['drawer.header']}
              {user.username ? (
                <Link to="/profile" onClick={closeDrawer}>
                  {user.username}
                </Link>
              ) : (
                strings['drawer.header.anonymous']
              )}
            </div>
            <div className={`${sideDrawerClass}__user-description`}>
              {!!user.uuid &&
                strings.formatString(strings['drawer.text'], {
                  count: (user.rice || 0).toLocaleString(),
                })}
              {!user.uuid && (
                <>
                  <Link tabIndex={tabOrder.SIDE_DRAWER_LOGIN_LINK} to="/profile-login" onClick={closeDrawer}>
                    {strings['anonymous.text.login']}
                  </Link>
                  {strings['anonymous.text.or']}
                  <Link tabIndex={tabOrder.SIDE_DRAWER_REGISTER_LINK} to="/profile-register" onClick={closeDrawer}>
                    {strings['anonymous.text.signUp']}
                  </Link>
                  {strings['anonymous.text.otherPart']}
                </>
              )}
            </div>
          </div>
        </div>
        <Menu menuName={`${sideDrawerClass}-menu`} handlerClick={closeDrawer} />
      </div>
    </nav>
  );
};

SideDrawer.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    rate: PropTypes.number,
  }),
  show: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  language: PropTypes.string,
  tabOrder: PropTypes.object.isRequired,
  addFocus: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

SideDrawer.defaultProps = {
  user: null,
  language: strings.getLanguage(),
};

export default withTabOrder(
  withRouter(
    connect(
      state => ({
        language: getLanguage(state),
      }),
      { setPreferenceLanguage },
    )(SideDrawer),
  ),
);
