import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import withPageTitle from 'containers/common/withPageTitle';
import BoxInfo from 'components/pages/common/box/BoxInfo';
import BoxInfoContent from 'components/pages/common/box/BoxInfoContent';
import BoxInfoProgressBar from 'components/pages/common/box/BoxInfoProgressBar';
import BoxInfoError from 'components/pages/common/box/BoxInfoError';
import Button from 'components/pages/common/Button';
import BackButton from 'components/app/BackButton';

import { oneTimeLogin, isLoadingUser, getUserDataErrors } from '@wfp/freerice-core/modules/user';
import { getLanguage } from '@wfp/freerice-core/modules/app';

import strings from 'res/strings';

class OneTimeLogin extends Component {
  static propTypes = {
    errors: PropTypes.array,
    isLoading: PropTypes.bool,
    oneTimeLogin: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({ hash: PropTypes.string.isRequired }).isRequired,
    }).isRequired,
    language: PropTypes.string,
  };

  static defaultProps = {
    errors: [],
    isLoading: false,
    language: strings.getLanguage(),
  };

  componentDidUpdate(prevProps) {
    const { isLoading, errors, history } = this.props;
    if (!isLoading && prevProps.isLoading && !errors.length) {
      history.push('/profile');
    }
  }

  login = () => {
    const { oneTimeLogin, match, language } = this.props;
    const data = JSON.parse(atob(match.params.hash));
    console.log(data);
    oneTimeLogin(data, language);
  };

  render() {
    const { errors, isLoading, match } = this.props;
    const { username } = JSON.parse(atob(match.params.hash));

    return (
      <BoxInfo type="medium-width">
        <BoxInfoContent>
          <BoxInfoProgressBar isVisible={isLoading}>
            <p>
              {strings.formatString(strings['oneTimeLogin.web.welcome'], { username: <strong>{username}</strong> })}
            </p>
            {errors && <BoxInfoError messages={errors} />}
            <Button color="green" text="Login" className="button-onetime-login" handlerClick={this.login} />
          </BoxInfoProgressBar>
        </BoxInfoContent>
        <BackButton />
      </BoxInfo>
    );
  }
}

export default withPageTitle({ title: 'Login' })(
  connect(
    createStructuredSelector({ errors: getUserDataErrors, isLoading: isLoadingUser, language: getLanguage }),
    { oneTimeLogin }
  )(OneTimeLogin)
);
