import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import withPageTitle from 'containers/common/withPageTitle';
import BoxInfoImage from 'components/pages/common/box/BoxInfoImage';
import MyGroupsContent from 'components/pages/groups/myGroups/MyGroupsContent';
import BackButton from 'components/app/BackButton';
import { withTabOrder, tabOrders, TAB_NAMES } from 'components/app/TabOrder';
import { loadMyGroups, getGroups, isLoading, loadAllGroupsRanks } from '@wfp/freerice-core/modules/groups';
import { getUserAccount } from '@wfp/freerice-core/modules/user';

import strings from 'res/strings';

class MyGroups extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    loadMyGroups: PropTypes.func.isRequired,
    loadAllGroupsRanks: PropTypes.func.isRequired,
    groups: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    setTabOrder: PropTypes.func.isRequired,
    focusTo: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { groups, loadMyGroups, loadAllGroupsRanks, setTabOrder, focusTo, location } = this.props;

    setTabOrder(tabOrders.MY_GROUPS);

    if (location.state && location.state.focusAfter) focusTo(TAB_NAMES.MENU_TOGGLE);

    if (!groups.length) {
      loadMyGroups('full');
    } else {
      loadAllGroupsRanks();
    }
  }

  componentDidUpdate(prevProps) {
    const { groups, loadAllGroupsRanks } = this.props;
    if (!prevProps.groups.length && groups.length) {
      loadAllGroupsRanks();
    }
  }

  itemClickHandler = e => {
    const { history } = this.props;
    const { id } = e.currentTarget.dataset;
    history.push(`/group/${id}`);
  };

  render() {
    const { groups, user, isLoading } = this.props;

    return (
      <BoxInfoImage imageType="profile-avatar" avatarId={user.avatar}>
        <MyGroupsContent loading={isLoading} groups={groups} handlerItemClick={this.itemClickHandler} />
        <BackButton to="/groups" text={strings.back_to_groups} />
      </BoxInfoImage>
    );
  }
}

export default compose(
  withPageTitle({ title: strings.groups_link }),
  withTabOrder,
  connect(
    createStructuredSelector({
      user: getUserAccount,
      groups: getGroups,
      isLoading,
    }),
    { loadMyGroups, loadAllGroupsRanks }
  )
)(MyGroups);
