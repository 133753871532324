import React from 'react';
import PropTypes from 'prop-types';
import './BoxInfo.scss';

const BoxInfo = ({ type = '', theme = '', className, children }) => (
  <div className={`box-info box-info--${type} box-info--${theme} ${className}`}>{children}</div>
);

BoxInfo.propTypes = {
  type: PropTypes.string,
  theme: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
};

BoxInfo.defaultProps = {
  type: '',
  theme: '',
  className: '',
};

export default BoxInfo;
