import React, { Component } from 'react';
import PropTypes from 'prop-types';

import BoxInfoSubTitle from 'components/pages/common/box/BoxInfoSubTitle';
import BoxInfoProgressBar from 'components/pages/common/box/BoxInfoProgressBar';
import BoxInfoError from 'components/pages/common/box/BoxInfoError';
import Button from 'components/pages/common/Button';
import Input from 'components/pages/common/Input';
import { Link, withRouter } from 'react-router-dom';
import './SignIn.scss';

import strings from 'res/strings';
import { withTabOrder } from 'components/app/TabOrder';

class SignIn extends Component {
  static propTypes = {
    data: PropTypes.shape({
      error: PropTypes.object,
      showForgotPassLink: PropTypes.bool,
    }).isRequired,
    handlerSignIn: PropTypes.func.isRequired,
    signInErrors: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    tabOrder: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  state = {
    username: '',
    password: '',
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  signInHandler = () => {
    const { handlerSignIn } = this.props;
    const { username, password } = this.state;

    handlerSignIn(username, password);
  };

  render() {
    const {
      data: { validate, showForgotPassLink },
      isLoading,
      signInErrors,
      tabOrder,
      history,
    } = this.props;
    const { username, password } = this.state;

    return (
      <>
        <BoxInfoSubTitle text={strings['login.paragraph.trackProgress']} />
        <BoxInfoProgressBar isVisible={isLoading}>
          <Input
            tabIndex={tabOrder.LOGIN_USERNAME}
            id="login-username"
            type="text"
            name="username"
            value={username}
            isInputError={validate.isUsername}
            handlerChange={this.changeHandler}
            placeholder={strings['login.placeholder.username']}
          />
          <Input
            tabIndex={tabOrder.LOGIN_PASSWORD}

            id="login-password"
            type="password"
            placeholder={strings['login.placeholder.password']}
            value={password}
            isInputError={validate.isPassword}
            handlerChange={this.changeHandler}
          />
          {validate.messages && validate.messages.length !== 0 && <BoxInfoError messages={validate.messages} />}
          {signInErrors && signInErrors.length !== 0 && <BoxInfoError messages={signInErrors} />}
          {showForgotPassLink && (
            <div
              tabIndex={tabOrder.LOGIN_FORGOT_PASSWORD}
              onKeyPress={() => history.push('/profile-login-recover', { focusAfter: true })}
            >
              <Link tabIndex={-1} to="/profile-login-recover" className="link-forgot-pass">
                {strings['login.button.forgot']}
              </Link>
            </div>
          )}
          <Button
            tabIndex={tabOrder.LOGIN_BUTTON}
            color="green"
            text={strings['login.web.button.signIn']}
            className="user-login-submit"
            handlerClick={this.signInHandler}
          />
        </BoxInfoProgressBar>
      </>
    );
  }
}

export default withTabOrder(withRouter(SignIn));
