import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import withPageTitle from 'containers/common/withPageTitle';
import BoxInfo from 'components/pages/common/box/BoxInfo';
import RegistrationSuccessContent from 'components/pages/profile/register/RegistrationSuccessContent';

import {
  getUserAccount,
} from '@wfp/freerice-core/modules/user';
import { withTabOrder, tabOrders, TAB_NAMES } from 'components/app/TabOrder';

import strings from 'res/strings';

class RegistrationSuccess extends Component {
  static propTypes = {
    user: PropTypes.object,
    history: PropTypes.object.isRequired,
    setTabOrder: PropTypes.func.isRequired,
    focusTo: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {
    user: {},
  };

  componentDidMount() {
    const { setTabOrder, focusTo, location, user, history } = this.props;

    if (!user.uuid) {
      history.push('/profile-register');
    }

    setTabOrder(tabOrders.REGISTRATION_SUCCESS);

    if (location.state && location.state.focusAfter) focusTo(TAB_NAMES.MENU_TOGGLE);
  }

  render() {
    return (
      <BoxInfo type="medium-width">
        <RegistrationSuccessContent />
      </BoxInfo>
    );
  }
}

export default compose(
  withPageTitle({ title: strings['creation.title.access'] }),
  withTabOrder,
  connect(
    createStructuredSelector({
      user: getUserAccount,
    })
  )
)(RegistrationSuccess);
