import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { connect } from 'react-redux';
import { getLanguage } from '@wfp/freerice-core/modules/app';
import './QuestionCard.scss';

import strings from 'res/strings';

const QuestionCard = ({ id, timeout, children, language }) => {
  const thirdCardClass = language === 'ar' ? 'third-card-rtl' : 'third-card';
  const questionCardClass = language === 'ar' ? 'question-card-rtl' : 'question-card';
  const secondCardClass = language === 'ar' ? 'second-card-rtl' : 'second-card';
  const firstCardClass = language === 'ar' ? 'first-card-rtl' : 'first-card';

  return (
    <div className={thirdCardClass}>
      <div className={secondCardClass}>
        <TransitionGroup>
          <CSSTransition key={id} timeout={timeout} unmountOnExit mountOnEnter classNames={questionCardClass}>
            <div className={`card-box ${firstCardClass}`}>{children}</div>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </div>
  );
};

QuestionCard.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.any,
  timeout: PropTypes.number,
  language: PropTypes.string,
};

QuestionCard.defaultProps = {
  children: null,
  timeout: 500,
  language: strings.getLanguage(),
};

export default connect((state) => ({
  language: getLanguage(state),
}))(QuestionCard);
