import { createSelector } from 'reselect';
import groupBy from 'lodash/groupBy';
import { produce } from 'immer';
import { createRequestTypes } from '../utils/actionHelpers';
import { createApiCall } from '../utils/api'; // region ACTION TYPES

export var CATEGORIES = {
  LOAD_LIST: createRequestTypes('CATEGORIES_LOAD_LIST')
}; // endregion
// region ACTIONS

export var loadCategories = createApiCall(CATEGORIES.LOAD_LIST, function (_ref) {
  var lang = _ref.lang;
  return {
    url: '/categories/?limit=50',
    params: {
      lang: lang,
      'filter[lang]': lang
    },
    method: 'GET'
  };
}, {
  apiType: 'engine'
}); // endregion
// region REDUCER

var initialState = {
  categories: [],
  loading: false,
  error: null
};
export default produce(function (draft, _ref2) {
  var type = _ref2.type,
      payload = _ref2.payload;

  switch (type) {
    case CATEGORIES.LOAD_LIST.REQUEST:
      draft.loading = true;
      break;

    case CATEGORIES.LOAD_LIST.SUCCESS:
      draft.loading = false;
      draft.categories = payload;
      break;

    case CATEGORIES.LOAD_LIST.FAILURE:
      draft.loading = false;
      draft.error = payload.message;
      break;

    default:
      break;
  }
}, initialState); // endregion
// region SELECTORS

var categoriesState = function categoriesState(state) {
  return state.categories;
};

export var getCategories = createSelector(categoriesState, function (state) {
  return state.categories;
});
export var getGroupedCategories = createSelector(getCategories, function (categories) {
  return groupBy(categories, 'category_group');
}); // endregion