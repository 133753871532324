import React from 'react';
import PropTypes from 'prop-types';

import RoundBlock from 'components/app/RoundBlock';

import './GlobalStats.scss';

import strings from 'res/strings';

const GlobalStats = ({ yesterdayRice }) => (
  <RoundBlock title={strings['block.globalStat.title']} className="block--global-stats">
    <p>
      {strings['block.globalStat.yesterday']}
:
      {' '}
      <strong>{(yesterdayRice || 0).toLocaleString('en-En')}</strong>
    </p>
    <p>
      {strings['block.globalStat.over']}
      {' '}
      <span className="yellow">{strings['block.globalStat.number']}</span>
      {' '}
      {strings['block.globalStat.lifetime']}
    </p>
  </RoundBlock>
);

GlobalStats.propTypes = {
  yesterdayRice: PropTypes.number,
};

GlobalStats.defaultProps = { yesterdayRice: 0 };

export default GlobalStats;
