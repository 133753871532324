import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import withPageTitle from 'containers/common/withPageTitle';
import BoxInfo from 'components/pages/common/box/BoxInfo';
import BackButton from 'components/app/BackButton';
import RegistrationContent from 'components/pages/profile/register/RegistrationContent';

import {
  getUserAccount,
  getUserDataErrors,
  clearUserErrors,
  isLoadingUser,
  signUp,
} from '@wfp/freerice-core/modules/user';
import { getLanguage } from '@wfp/freerice-core/modules/app';
import validate, { rulesMethods } from '@wfp/freerice-core/utils/validators';
import { withTabOrder, tabOrders, TAB_NAMES } from 'components/app/TabOrder';

import strings from 'res/strings';

class Registration extends Component {
  static propTypes = {
    clearUserErrors: PropTypes.func.isRequired,
    user: PropTypes.object,
    errors: PropTypes.array,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    signUp: PropTypes.func.isRequired,
    language: PropTypes.string,
    setTabOrder: PropTypes.func.isRequired,
    focusTo: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {
    user: {},
    errors: [],
    language: strings.getLanguage(),
  };

  state = {
    validation: {
      messages: [],
      isUsername: false,
      isEmail: false,
      isPassword: false,
      isConfirmPassword: false,
    },
  };

  componentWillMount() {
    const { clearUserErrors } = this.props;
    clearUserErrors();
  }

  componentDidMount() {
    const { setTabOrder, focusTo, location } = this.props;

    setTabOrder(tabOrders.REGISTRATION);

    if (location.state && location.state.focusAfter) focusTo(TAB_NAMES.MENU_TOGGLE);
  }

  componentDidUpdate(prevProps) {
    const { user, errors, history, isLoading } = this.props;
    const { validation } = this.state;

    if (user.uuid && !isLoading && !errors.length && !prevProps.errors.length && !validation.messages.length) {
      history.push('/profile-register-success');
    }
  }

  createAccountHandler = (username, email, password, confirmPassword, captcha) => {
    if (!this.isValid(username, email, password, confirmPassword, captcha)) {
      return;
    }

    const { signUp, language } = this.props;

    signUp({
      username,
      email,
      password,
      confirmPassword,
      captcha,
      lang: language,
    });
  };

  isValid = (username, email, password, confirmPassword, captcha) => {
    const captchaValidate = !!process.env.REACT_APP_GOOGLE_CAPTCHA;

    const data = { username, email, password, confirmPassword, captcha };
    let messages = [];
    let isUsername = false;
    let isEmail = false;
    let isPassword = false;
    let isConfirmPassword = false;
    let isCaptcha = false;

    messages = validate(data, Object.assign({
      username: 'required|minLength:5',
      email: 'required|email',
      password: 'required|minLength:5|match:confirmPassword',
      confirmPassword: 'required',
    }, captchaValidate ? { captcha: 'required' } : {}), strings);

    if (messages.length) {
      if (captchaValidate) {
        if (!rulesMethods.required(data, 'captcha')) {
          isCaptcha = true;
        }
      } else {
        isCaptcha = true;
      }


      if (!rulesMethods.required(data, 'username') || !rulesMethods.minLength(data, 'username', 5)) {
        isUsername = true;
      }

      if (!rulesMethods.email(data, 'email')) {
        isEmail = true;
      }

      if (!rulesMethods.required(data, 'password') || !rulesMethods.minLength(data, 'password', 5)) {
        isPassword = true;
      }

      if (!rulesMethods.required(data, 'confirmPassword')) {
        isConfirmPassword = true;
      }

      if (!isPassword && !isConfirmPassword && !rulesMethods.match(data, 'password', 'confirmPassword')) {
        isConfirmPassword = true;
        isPassword = true;
      }
    }

    this.setState({
      validation: {
        messages,
        isUsername,
        isEmail,
        isPassword,
        isConfirmPassword,
        isCaptcha,
      },
    });

    return !isUsername && !isEmail && !isPassword && !isConfirmPassword && !isCaptcha;
  };

  render() {
    const { isLoading, errors } = this.props;
    const { validation } = this.state;

    return (
      <BoxInfo type="medium-width">
        <RegistrationContent
          data={this.state}
          isLoading={isLoading}
          errors={errors}
          validation={validation}
          handlerCreateAccount={this.createAccountHandler}
        />
        <BackButton />
      </BoxInfo>
    );
  }
}

export default compose(
  withPageTitle({ title: strings['creation.title.access'] }),
  withTabOrder,
  connect(
    createStructuredSelector({
      isLoading: isLoadingUser,
      errors: getUserDataErrors,
      user: getUserAccount,
      language: getLanguage,
    }),
    { signUp, clearUserErrors }
  )
)(Registration);
