import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Game from 'containers/pages/Game';
import Categories from 'containers/pages/Categories';
import Levels from 'containers/pages/Levels';
import Login from 'containers/pages/profile/Login';
import EditProfile from 'containers/pages/profile/EditProfile';
import { DeleteProfile } from 'containers/pages/profile/DeleteProfile';
import Profile from 'containers/pages/profile/Profile';
import Registration from 'containers/pages/profile/Registration';
import RegistrationSuccess from 'containers/pages/profile/RegistrationSuccess';
import ForgotPassword from 'containers/pages/profile/ForgotPassword';
import OneTimeLogin from 'containers/pages/profile/OneTimeLogin';
import GroupsAnonymous from 'containers/pages/groups/GroupsAnonymous';
import LeaveGroup from 'containers/pages/groups/LeaveGroup';
import Groups from 'containers/pages/groups/Groups';
import MyGroups from 'containers/pages/groups/MyGroups';
import Group from 'containers/pages/groups/Group';
import EditGroup from 'containers/pages/groups/EditGroup';
import Leaderboard from 'containers/pages/Leaderboard';
import About from 'containers/pages/About';
import ActivityFeed from 'containers/pages/ActivityFeed';
import Challenges from 'containers/pages/Challenges';
import Challenge from 'containers/pages/Challenge';
import Apps from 'containers/pages/Apps';
import CitiSignUp from 'containers/pages/CitiSignup';
import PrivacyPolicy from 'containers/pages/PrivacyPolicy';
import { withNotification } from 'components/app/Notification';
import { AnimatedNavConsumer, AnimatedNavProvider } from './AnimatedNavContext';

import './Main.scss';

const Main = ({ category, SmallNotification }) => (
  <section role="main" className="main">
    <div className="main-container">
      <AnimatedNavProvider>
        <Route
          render={({ location }) => {
            window.scrollTo(0, 0);

            if (typeof window.gtag === 'function') {
              window.gtag('config', 'UA-5566800-3', { page_path: location.pathname });
            }

            return (
              <AnimatedNavConsumer>
                {({ animated, setAnimationState }) => (
                  <TransitionGroup>
                    <CSSTransition
                      key={location.key}
                      enter={animated}
                      exit={animated}
                      timeout={500}
                      onExited={() => setAnimationState(false)}
                      classNames="back-nav"
                    >
                      <Switch location={location}>
                        <Redirect exact path="/" to={`/categories/${category.machine_name}`} />
                        <Redirect exact path="/game" to={`/categories/${category.machine_name}`} />
                        <Route exact path="/categories/:category" component={Game} />
                        <Route path="/categories" component={Categories} />
                        <Route path="/levels" component={Levels} />
                        <Route path="/profile" component={Profile} />
                        <Route path="/profile-edit" component={EditProfile} />
                        <Route path="/profile-delete" component={DeleteProfile} />
                        <Route path="/profile-login" component={Login} />
                        <Route path="/profile-register" component={Registration} />
                        <Route path="/profile-register-success" component={RegistrationSuccess} />
                        <Route path="/profile-login-recover" component={ForgotPassword} />
                        <Route path="/profile-onetime-login/:hash" component={OneTimeLogin} />
                        <Route path="/group/:id" component={Group} />
                        <Route path="/group-edit/:id" component={EditGroup} />
                        <Route path="/group-leave/:id" component={LeaveGroup} />
                        <Route path="/groups" component={Groups} />
                        <Route path="/my-groups" component={MyGroups} />
                        <Route path="/groups-anonymous" component={GroupsAnonymous} />
                        <Route path="/high-scores" component={Leaderboard} />
                        <Route path="/about" component={About} />
                        <Route path="/activity-feed" component={ActivityFeed} />
                        <Route path="/challenges" component={Challenges} />
                        <Route path="/challenge/:id" component={Challenge} />
                        <Route path="/apps" component={Apps} />
                        <Route path="/privacy-policy" component={PrivacyPolicy} />
                        <Route path="/citi" component={CitiSignUp} />
                      </Switch>
                    </CSSTransition>
                  </TransitionGroup>
                )}
              </AnimatedNavConsumer>
            );
          }}
        />
        {SmallNotification}
      </AnimatedNavProvider>
    </div>
  </section>
);

Main.propTypes = {
  category: PropTypes.shape({
    machine_name: PropTypes.string.isRequired,
  }).isRequired,
  SmallNotification: PropTypes.node.isRequired,
};

export default withNotification(Main);
