import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BoxInfoContent from 'components/pages/common/box/BoxInfoContent';
import BoxInfoTitle from 'components/pages/common/box/BoxInfoTitle';
import BoxInfoProgressBar from 'components/pages/common/box/BoxInfoProgressBar';
import Tabs from 'components/pages/common/tab/Tabs';
import Button from 'components/pages/common/Button';
import Achievement from 'components/pages/profile/profile/Achievement';
import SignOutButton from 'components/pages/profile/profile/SignOutButton';
import { withTabOrder } from 'components/app/TabOrder';
import './ProfileContent.scss';

import strings from 'res/strings';

class ProfileContent extends Component {
  static propTypes = {
    user: PropTypes.shape({
      donated: PropTypes.bool,
      badgeIds: PropTypes.array,
      badges: PropTypes.array,
    }).isRequired,
    badges: PropTypes.array.isRequired,
    handlerEditProfile: PropTypes.func.isRequired,
    handlerSignOut: PropTypes.func.isRequired,
    isLoadingAccountData: PropTypes.bool.isRequired,
    tabOrder: PropTypes.object.isRequired,
  };

  tabs = [
    { name: 'profile', label: strings['profile.web.tab.profile'] },
    { name: 'badges', label: strings['profile.web.tab.badges'] },
  ];

  renderAchievements = () => {
    const {
      badges,
      user: { badges: userBadges = [], badgeIds: userBadgeIds = [] },
    } = this.props;

    return userBadges
      .map(badge => <Achievement key={badge.id} data={badge} isAchieved />)
      .concat(
        badges
          .filter(b => !userBadgeIds.includes(b.id))
          .map(badge => <Achievement key={badge.id} data={badge} isAchieved={false} />)
      );
  };

  render() {
    const {
      handlerEditProfile,
      handlerSignOut,
      isLoadingAccountData,
      user: { username, rice = 0 },
      tabOrder,
    } = this.props;
    return (
      <Tabs tabs={this.tabs}>
        {activeTab => (
          <BoxInfoContent className="profile-content">
            <BoxInfoProgressBar isVisible={isLoadingAccountData}>
              <BoxInfoTitle className="username" text={username} />
              <p id="rice-donated" className="rice-donated">
                {strings['profile.donated']}
                <strong id="rice-counter">{(rice || 0).toLocaleString()}</strong>
                {strings['profile.grains']}
              </p>
              {activeTab === 'profile' && (
                <div className="panel">
                  <hr />
                  <Button
                    tabIndex={tabOrder.EDIT_PROFILE_BUTTON}
                    color="green"
                    text={strings['profile.edit']}
                    handlerClick={handlerEditProfile}
                  />
                  <SignOutButton tabIndex={tabOrder.SIGN_OUT_BUTTON} handlerClick={handlerSignOut} />
                </div>
              )}
              {activeTab === 'badges' && (
                <div className="panel">
                  <hr />
                  <ul id="user-badges" className="achievements">
                    {this.renderAchievements()}
                  </ul>
                </div>
              )}
            </BoxInfoProgressBar>
          </BoxInfoContent>
        )}
      </Tabs>
    );
  }
}

export default withTabOrder(ProfileContent);
