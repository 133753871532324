import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import './LargeNotification.scss';
import { addCommasToNumbersInText } from '@wfp/freerice-core/utils/parsing';

import strings from 'res/strings';
import { LARGE_NOTIFICATION } from './Constants';

const LargeNotification = ({ visible, params, setVisibility }) => {
  const closeNotification = () => setVisibility(LARGE_NOTIFICATION, false, params);
  const { title } = params;

  return (
    <CSSTransition in={visible} timeout={500} classNames="slide-y">
      <div>
        <div className="notification notification--large">
          <div className="notification--content">
            <div className="notification--content--default-image" />
            <h2>{addCommasToNumbersInText(title)}</h2>
          </div>
          <div onClick={closeNotification} className="close-button">
            {strings.back_to_game}
          </div>
        </div>
        <div onClick={closeNotification} className="notification--large-overlay" />
      </div>
    </CSSTransition>
  );
};

LargeNotification.propTypes = {
  visible: PropTypes.bool.isRequired,
  params: PropTypes.shape({
    title: PropTypes.string,
  }),
  setVisibility: PropTypes.func.isRequired,
};

LargeNotification.defaultProps = {
  params: {},
};

export default LargeNotification;
