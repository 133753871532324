import React from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

import CardTitle from 'components/pages/game/CardTitle';
import strings from 'res/strings';
import QuestionCard from '../QuestionCard';


const QuestionCaptcha = ({ question, delay, onAnswer }) => (
  <QuestionCard id={question.id} timeout={delay}>
    <CardTitle text={strings['game.web.captcha.robot']} />
    <div className="recaptcha">
      <ReCAPTCHA key={question.id} size="normal" sitekey={question.key} onChange={onAnswer} />
    </div>
  </QuestionCard>
);

QuestionCaptcha.propTypes = {
  question: PropTypes.shape({
    key: PropTypes.string.isRequired,
  }).isRequired,
  onAnswer: PropTypes.func.isRequired,
  delay: PropTypes.number.isRequired,
};

export default QuestionCaptcha;
