import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './PageContainer.scss';

const PageContainer = ({ children, className }) => (
  <div className={classNames('container page__container', className)}>{children}</div>
);

PageContainer.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

PageContainer.defaultProps = {
  className: '',
};

export default PageContainer;
