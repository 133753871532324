import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getLanguage } from '@wfp/freerice-core/modules/app';
import './RegistrationButton.scss';

import strings from 'res/strings';

const RegistrationButton = ({ className = '', history, language, tabIndex }) => {
  const registerBtnClass = language === 'ar' ? 'register-btn-rtl' : 'register-btn';

  return (
    <div
      tabIndex={tabIndex}
      onKeyPress={() => history.push('/profile-register', { focusAfter: true })}
    >
      <Link
        tabIndex={-1}
        to="/profile-register"
        className={`${registerBtnClass} ${registerBtnClass}--icon ${className}`}
      >
        {strings['login.button.createAccount']}
      </Link>
    </div>
  );
};

RegistrationButton.propTypes = {
  className: PropTypes.string,
  language: PropTypes.string,
  tabIndex: PropTypes.number,
  history: PropTypes.object.isRequired,
};

RegistrationButton.defaultProps = {
  className: '',
  language: strings.getLanguage(),
  tabIndex: 0,
};

export default withRouter(connect((state) => ({
  language: getLanguage(state),
}))(RegistrationButton));
