import React from 'react';
import Button from 'components/pages/common/Button/Button';
import strings from 'res/strings';
import BoxInfoSubTitle from 'components/pages/common/box/BoxInfoSubTitle/BoxInfoSubTitle';

export const DeleteProfileBlock = () => (
  <>
    <h3>{strings['deleteProfile.block.title']}</h3>
    <BoxInfoSubTitle text={strings['deleteProfile.block.description']} />
    <Button to="/profile-delete" color="red" text={strings['deleteProfile.block.button']} />
  </>
);
