import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './BoxInfoMessage.scss';

const BoxInfoMessage = ({ text, title, className }) => (
  <div className={classNames('box-info-message', className)}>
    {title && <h2 className="box-info-message_title">{title}</h2>}
    {Array.isArray(text) ? text.map(t => (<p>{t}</p>)) : (<p>{text}</p>)}
  </div>
);

BoxInfoMessage.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

BoxInfoMessage.defaultProps = { className: '' };

export default BoxInfoMessage;
