import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BoxInfoContent from 'components/pages/common/box/BoxInfoContent';
import Input from 'components/pages/common/Input';
import Button from 'components/pages/common/Button';
import BoxInfoTitle from 'components/pages/common/box/BoxInfoTitle';
import BoxInfoError from 'components/pages/common/box/BoxInfoError';
import BoxInfoSuccess from 'components/pages/common/box/BoxInfoSuccess';
import BoxInfoProgressBar from 'components/pages/common/box/BoxInfoProgressBar';
import './EditGroupContent.scss';

import strings from 'res/strings';

class EditGroupContent extends Component {
  static propTypes = {
    data: PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
    }).isRequired,
    handlerSaveChanges: PropTypes.func.isRequired,
    handlerRefreshCode: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isLoadingCode: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    progress: PropTypes.string,
    groupErrors: PropTypes.object,
  };

  static defaultProps = {
    progress: '',
    groupErrors: null,
  };

  // eslint-disable-next-line react/destructuring-assignment
  state = { name: this.props.data.name || '' };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      data: { name },
    } = nextProps;

    if (!prevState.name && name) {
      return { name };
    }

    return null;
  }

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  saveChangesHandler = () => {
    const { handlerSaveChanges } = this.props;
    const { name } = this.state;

    handlerSaveChanges({ name });
  };

  render() {
    const {
      data: { code },
      handlerRefreshCode,
      isLoading,
      isLoadingCode,
      progress,
      errors,
      groupErrors,
    } = this.props;
    const { name } = this.state;

    return (
      <BoxInfoContent>
        <BoxInfoTitle text={strings['editGroup.title.edit']} />
        <BoxInfoProgressBar isVisible={isLoading || progress === 'savingGame'}>
          <Input
            id="edit-name"
            type="text"
            name="name"
            placeholder={strings['editGroup.web.input.title.placeholder']}
            label={strings['editGroup.input.title']}
            value={name}
            isInputError={!!errors.nameMessage}
            handlerChange={this.changeHandler}
          />
          {!!groupErrors && groupErrors.category === 'update' && <BoxInfoError messages={[groupErrors.message]} />}
          {errors.nameMessage && <BoxInfoError messages={[errors.nameMessage]} />}
          {progress === 'savedGroup' && !errors.nameMessage && (
            <BoxInfoSuccess messages={[strings['editGroup.web.update.success']]} />
          )}
          <Button
            className="save-changes-btn"
            color="green"
            text={strings['editGroup.button.save']}
            handlerClick={this.saveChangesHandler}
          />
        </BoxInfoProgressBar>

        <BoxInfoTitle text={strings['editGroup.title.code']} />
        <BoxInfoProgressBar isVisible={isLoadingCode || (!code && isLoading) || progress === 'refreshingCode'}>
          <strong>{code}</strong>
          <p>
            <small>{strings['editGroup.text.instruction']}</small>
          </p>
          {!!groupErrors && groupErrors.category === 'refresh-code' && (
            <BoxInfoError messages={[groupErrors.message]} />
          )}
          {errors.code && <BoxInfoError messages={[errors.code]} />}
          <Button color="green" text={strings['editGroup.button.refresh']} handlerClick={handlerRefreshCode} />
          {progress === 'refreshedCode' && !isLoadingCode && !errors.code && (
            <BoxInfoSuccess messages={[strings['editGroup.web.refresh.success']]} />
          )}
        </BoxInfoProgressBar>
      </BoxInfoContent>
    );
  }
}

export default EditGroupContent;
