import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import BoxInfoContent from 'components/pages/common/box/BoxInfoContent';
import strings from 'res/strings';
import withPageTitle from 'containers/common/withPageTitle';
import BoxInfo from 'components/pages/common/box/BoxInfo/BoxInfo';
import { DeleteProfileForm } from 'components/pages/profile/delete/DeleteProfileForm';
import BackButton from 'components/app/BackButton/BackButton';
import BoxInfoProgressBar from 'components/pages/common/box/BoxInfoProgressBar/BoxInfoProgressBar';
import { DeleteSuccess } from 'components/pages/profile/delete/DeleteSuccess';

import { isLoadingUser, getUserAccount, deleteAccount, getUserDataErrors } from '@wfp/freerice-core/modules/user';

const DeleteProfileLayout = ({ user, isLoading, errors, deleteAccount }) => (
  <BoxInfo type="medium-width">
    <BoxInfoContent>
      <BoxInfoProgressBar isVisible={isLoading}>
        {user.deleted ? (
          <DeleteSuccess />
        ) : (
          <DeleteProfileForm
            errors={errors}
            onSubmit={({ password }) => {
              if (user.username) {
                deleteAccount({ username: user.username, password });
              }
            }}
          />
        )}
      </BoxInfoProgressBar>
    </BoxInfoContent>
    <BackButton />
  </BoxInfo>
);

DeleteProfileLayout.propTypes = {
  user: PropTypes.shape({ username: PropTypes.string, deleted: PropTypes.bool }),
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  deleteAccount: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

DeleteProfileLayout.defaultProps = {
  user: null,
};

export const DeleteProfile = compose(
  withPageTitle({ title: strings['deleteProfile.web.pageTitle'] }),
  connect(
    createStructuredSelector({ isLoading: isLoadingUser, user: getUserAccount, errors: getUserDataErrors }),
    { deleteAccount },
  ),
)(DeleteProfileLayout);
