import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import BoxInfoContent from 'components/pages/common/box/BoxInfoContent';
import BoxInfoMessage from 'components/pages/common/box/BoxInfoMessage';
import BoxInfoTitle from 'components/pages/common/box/BoxInfoTitle';
import Button from 'components/pages/common/Button';
import './RegistrationSuccessContent.scss';

import { withTabOrder } from 'components/app/TabOrder';
import strings from 'res/strings';

// eslint-disable-next-line react/prefer-stateless-function
class RegistrationSuccessContent extends Component {
  static propTypes = {
    tabOrder: PropTypes.object.isRequired,
  };

  static defaultProps = {};

  render() {
    const { tabOrder } = this.props;

    return (
      <Fragment>
        <BoxInfoContent>
          <BoxInfoTitle text={strings['creation.title.createAccountSuccess']} />
          <BoxInfoMessage
            className="registration-success-message"
            text={[
              <>
                {strings['creation.web.create.success.checkEmail.part1']}
                <b>{strings['creation.web.create.success.checkEmail.part2']}</b>
                {strings['creation.web.create.success.checkEmail.part3']}
              </>,
              <>
                {strings['creation.web.create.success.checkEmail.part4']}
                <b>{strings['creation.web.create.success.checkEmail.part5']}</b>
                {strings['creation.web.create.success.checkEmail.part6']}
              </>,
              <>
                {strings['creation.web.create.success.checkEmail.part7']}
                <b>{strings['creation.web.create.success.checkEmail.part8']}</b>
                {strings['creation.web.create.success.checkEmail.part9']}
              </>,
            ]}
          />
        </BoxInfoContent>
        <div className="profile-button">
          <Button
            tabIndex={tabOrder.PROFILE_BUTTON}
            color="green"
            text={strings['creation.button.createSuccess']}
            to="/profile"
          />
        </div>
      </Fragment>
    );
  }
}

export default withTabOrder(RegistrationSuccessContent);
