import React from 'react';
import PropTypes from 'prop-types';

import withPageTitle from 'containers/common/withPageTitle';
import BoxInfo from 'components/pages/common/box/BoxInfo';
import BackButton from 'components/app/BackButton';
import LeaveGroupContent from 'components/pages/groups/leaveGroup/LeaveGroupContent';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getGroup } from '@wfp/freerice-core/modules/groups';

import strings from 'res/strings';

const LeaveGroup = ({ group }) => (
  <BoxInfo type="medium-width">
    {!!group && <LeaveGroupContent groupName={group.name} groupCode={group.code} />}
    <BackButton to="/my-groups" text={strings.back_to_groups} />
  </BoxInfo>
);

LeaveGroup.propTypes = {
  group: PropTypes.object,
};

LeaveGroup.defaultProps = {
  group: null,
};

export default compose(
  withPageTitle({ title: strings.groups_link }),
  connect((state, props) => ({ group: getGroup(state, props.match.params.id) }))
)(LeaveGroup);
