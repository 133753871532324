import _defineProperty from "/home/vsts/work/1/s/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "/home/vsts/work/1/s/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { createSelector } from 'reselect';
import { produce } from 'immer';
import _uniq from 'lodash/uniq';
import { createRequestTypes, createAction } from '../utils/actionHelpers';
import { createApiCall } from '../utils/api';
import { USER } from './user'; // region ACTION TYPES

export var GROUPS = {
  CREATE_GROUP: createRequestTypes('GROUPS_CREATE_GROUP'),
  GET_MY_GROUPS: createRequestTypes('GROUPS_LOAD_USER_GROUPS'),
  GET_GROUP_DETAILS: createRequestTypes('GET_GROUP_DETAILS'),
  GET_GROUP_MEMBERS: createRequestTypes('LOAD_GROUP_MEMBERS'),
  GET_GROUP_RANK: createRequestTypes('GET_GROUP_RANK'),
  GET_GROUPS_RANKS: 'GET_GROUPS_RANKS',
  LOAD_USER_RANK_IN_GROUP: createRequestTypes('LOAD_USER_RANK_IN_GROUP'),
  UPDATE_GROUP: createRequestTypes('GROUPS_UPDATE'),
  JOIN_GROUP: createRequestTypes('GROUPS_JOIN_GROUP'),
  REFRESH_CODE: createRequestTypes('GROUPS_REFRESH_CODE'),
  LEAVE_GROUP: createRequestTypes('GROUPS_LEAVE_GROUP'),
  CLEAR_ERRORS: 'USER_CLEAR_ERRORS'
}; // endregion
// region ACTIONS

export var clearErrors = createAction(GROUPS.CLEAR_ERRORS);
export var createNewGroup = createApiCall(GROUPS.CREATE_GROUP, function (_ref) {
  var name = _ref.name,
      description = _ref.description,
      avatar = _ref.avatar,
      lang = _ref.lang;
  return {
    url: "/groups?_format=json&_lang=".concat(lang),
    method: 'POST',
    data: {
      name: name,
      description: description,
      avatar: avatar
    }
  };
}, function (_, _ref2) {
  var onSuccess = _ref2.onSuccess;
  return {
    apiType: 'accounts',
    onSuccess: onSuccess
  };
});
export var loadMyGroups = createApiCall(GROUPS.GET_MY_GROUPS, function () {
  return {
    url: '/groups?_format=json',
    method: 'GET'
  };
}, function (mode) {
  return {
    apiType: 'accounts',
    mode: mode
  };
});
export var loadGroup = createApiCall(GROUPS.GET_GROUP_DETAILS, function (id) {
  return {
    url: "/groups/".concat(id),
    method: 'GET'
  };
}, {
  apiType: 'accounts'
});
export var loadGroupRank = createApiCall(GROUPS.GET_GROUP_RANK, function (id) {
  return {
    url: "/groups/".concat(id),
    method: 'GET'
  };
}, {
  apiType: 'engine'
});
export var loadAllGroupsRanks = function loadAllGroupsRanks() {
  return {
    type: GROUPS.GET_GROUPS_RANKS
  };
};
export var loadGroupMembers = createApiCall(GROUPS.GET_GROUP_MEMBERS, function (group) {
  var current = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return {
    url: '/group-members?_format=json',
    params: {
      group: group,
      current: current,
      limit: '20'
    },
    method: 'GET'
  };
}, {
  apiType: 'engine'
});
export var loadUserRankInGroup = createApiCall(GROUPS.LOAD_USER_RANK_IN_GROUP, function (group, user) {
  return {
    url: "/group-members/".concat(group, "--").concat(user),
    method: 'GET'
  };
}, {
  apiType: 'engine'
});
export var updateGroup = createApiCall(GROUPS.UPDATE_GROUP, function (uuid, params, lang) {
  return {
    url: "/groups/".concat(uuid, "?_format=json&_lang=").concat(lang),
    method: 'PUT',
    data: params
  };
}, {
  apiType: 'accounts'
});
export var joinExistingGroup = createApiCall(GROUPS.JOIN_GROUP, function (_ref3) {
  var code = _ref3.code,
      lang = _ref3.lang;
  return {
    url: "/groups/join?_format=json&_lang=".concat(lang),
    method: 'POST',
    data: {
      code: code
    }
  };
}, function (_, _ref4) {
  var onSuccess = _ref4.onSuccess;
  return {
    apiType: 'accounts',
    onSuccess: onSuccess
  };
});
export var refreshCodeGroup = createApiCall(GROUPS.REFRESH_CODE, function (uuid, _ref5) {
  var code = _ref5.code,
      lang = _ref5.lang;
  return {
    url: "groups/".concat(uuid, "/new-code?_format=json&_lang=").concat(lang),
    method: 'POST',
    data: {
      code: code
    }
  };
}, {
  apiType: 'accounts'
});
export var leaveGroup = createApiCall(GROUPS.LEAVE_GROUP, function (uuid) {
  return {
    url: "groups/".concat(uuid, "/members?_format=json"),
    method: 'DELETE'
  };
}, function (uuid) {
  return {
    apiType: 'accounts',
    uuid: uuid
  };
}); // endregion
// region REDUCER

var initialState = {
  groups: [],
  isLoading: false,
  isLoadingGroupMembers: false,
  isLoadingCode: false,
  loadDataForGroups: null,
  errors: null
};

var mergeMembers = function mergeMembers(oldMembers, newMembers) {
  var newMembersArray = Object.values(newMembers);

  var memberIds = _uniq([].concat(_toConsumableArray(oldMembers.map(function (m) {
    return m.user;
  })), _toConsumableArray(newMembersArray.map(function (m) {
    return m.uuid;
  }))));

  return memberIds.map(function (uuid) {
    return _objectSpread(_objectSpread({}, oldMembers.find(function (m) {
      return m.user === uuid;
    }) || {}), newMembersArray.find(function (m) {
      return m.uuid === uuid;
    }) || {});
  });
};

var mergeGroups = function mergeGroups(oldGroups, newGroups) {
  return newGroups.map(function (group) {
    var oldGroup = oldGroups.find(function (g) {
      return g.uuid === group.uuid;
    });

    if (!oldGroup) {
      return group;
    }

    return _objectSpread(_objectSpread({}, oldGroup), group);
  });
};

export default produce(function (draft, _ref6) {
  var type = _ref6.type,
      payload = _ref6.payload,
      meta = _ref6.meta,
      options = _ref6.options;

  switch (type) {
    case GROUPS.CLEAR_ERRORS:
      draft.errors = null;
      break;

    case GROUPS.CREATE_GROUP.REQUEST:
    case GROUPS.GET_MY_GROUPS.REQUEST:
    case GROUPS.UPDATE_GROUP.REQUEST:
    case GROUPS.GET_GROUP_DETAILS.REQUEST:
    case GROUPS.JOIN_GROUP.REQUEST:
      draft.isLoading = true;
      draft.errors = null;
      break;

    case GROUPS.GET_GROUP_MEMBERS.REQUEST:
      draft.isLoadingGroupMembers = true;
      break;

    case GROUPS.REFRESH_CODE.REQUEST:
      draft.isLoadingCode = true;
      break;

    case GROUPS.REFRESH_CODE.SUCCESS:
      {
        draft.isLoadingCode = false;
        var idx = draft.groups.findIndex(function (g) {
          return g.uuid === payload.uuid;
        });
        draft.groups[idx].code = payload.code;
        break;
      }

    case GROUPS.CREATE_GROUP.SUCCESS:
    case GROUPS.GET_GROUP_DETAILS.SUCCESS:
    case GROUPS.UPDATE_GROUP.SUCCESS:
    case GROUPS.JOIN_GROUP.SUCCESS:
      {
        var foundIdx = draft.groups.findIndex(function (g) {
          return g.uuid === payload.uuid;
        });

        if (foundIdx >= 0) {
          draft.groups[foundIdx] = payload;
        } else {
          draft.groups.push(payload);
        }

        draft.isLoading = false;
        break;
      }

    case GROUPS.GET_MY_GROUPS.SUCCESS:
      {
        draft.isLoading = false;
        draft.groups = mergeGroups(draft.groups, payload);
        break;
      }

    case GROUPS.GET_GROUP_RANK.SUCCESS:
      {
        var id = payload.id,
            rice = payload.rice,
            rank = payload.rank;

        var _foundIdx = draft.groups.findIndex(function (g) {
          return g.uuid === id;
        });

        if (_foundIdx >= 0) {
          draft.groups[_foundIdx] = _objectSpread(_objectSpread({}, draft.groups[_foundIdx]), {}, {
            rice: rice,
            rank: rank
          });
        }

        break;
      }

    case GROUPS.GET_GROUP_MEMBERS.SUCCESS:
      {
        draft.isLoadingGroupMembers = false;

        var _foundIdx2 = draft.groups.findIndex(function (g) {
          return g.uuid === options.params.group;
        });

        if (_foundIdx2 >= 0) {
          draft.groups[_foundIdx2].members = (draft.groups[_foundIdx2].members || []).concat(payload);
          draft.groups[_foundIdx2].membersPagination = meta.pagination;
        }

        break;
      }

    case GROUPS.LOAD_USER_RANK_IN_GROUP.SUCCESS:
      {
        var group = payload.group,
            _rice = payload.rice,
            _rank = payload.rank;

        var _foundIdx3 = draft.groups.findIndex(function (g) {
          return g.uuid === group;
        });

        if (_foundIdx3 >= 0) {
          draft.groups[_foundIdx3] = _objectSpread(_objectSpread({}, draft.groups[_foundIdx3]), {}, {
            personalRank: {
              rice: _rice,
              rank: _rank
            }
          });
        }

        break;
      }

    case USER.LOAD_PUBIC_PROFILE.SUCCESS:
      {
        var params = meta.params;

        if (params.group) {
          var _foundIdx4 = draft.groups.findIndex(function (g) {
            return g.uuid === params.group;
          });

          if (_foundIdx4 >= 0) {
            draft.groups[_foundIdx4].members = mergeMembers(draft.groups[_foundIdx4].members, payload);
          }
        }

        break;
      }

    case GROUPS.LEAVE_GROUP.SUCCESS:
      {
        var _idx = draft.groups.findIndex(function (g) {
          return g.uuid === meta.uuid;
        });

        if (_idx >= 0) {
          draft.groups[_idx].leaved = true;
        }

        break;
      }

    case GROUPS.CREATE_GROUP.FAILURE:
      draft.isLoading = false;
      draft.errors = {
        message: payload.message,
        category: 'create'
      };
      break;

    case GROUPS.GET_MY_GROUPS.FAILURE:
      draft.isLoading = false;

      if (Array.isArray(payload)) {
        draft.errors = payload;
      } else if ('message' in payload) {
        draft.errors = [payload.message];
      } else {
        draft.errors = [payload];
      }

      break;

    case GROUPS.GET_GROUP_MEMBERS.FAILURE:
      draft.isLoadingGroupMembers = false;

      if (Array.isArray(payload)) {
        draft.errors = payload;
      } else if ('message' in payload) {
        draft.errors = [payload.message];
      } else {
        draft.errors = [payload];
      }

      break;

    case GROUPS.UPDATE_GROUP.FAILURE:
      draft.isLoading = false;
      draft.errors = {
        message: payload.message,
        category: 'update'
      };
      break;

    case GROUPS.JOIN_GROUP.FAILURE:
      draft.isLoading = false;
      draft.errors = {
        message: payload.message,
        category: 'join'
      };
      break;

    case GROUPS.REFRESH_CODE.FAILURE:
      draft.isLoadingCode = false;
      draft.errors = {
        message: payload.message,
        category: 'refresh-code'
      };
      break;

    default:
      break;
  }
}, initialState); // endregion
// region SELECTORS

var groupsState = function groupsState(state) {
  return state.groups;
};

export var isLoading = createSelector(groupsState, function (_ref7) {
  var isLoading = _ref7.isLoading;
  return isLoading;
});
export var isLoadingGroupMembers = createSelector(groupsState, function (state) {
  return state.isLoadingGroupMembers;
});
export var isLoadingCode = createSelector(groupsState, function (_ref8) {
  var isLoadingCode = _ref8.isLoadingCode;
  return isLoadingCode;
});
export var isLoadDataForGroups = createSelector(groupsState, function (_ref9) {
  var isLoadDataForGroups = _ref9.isLoadDataForGroups;
  return isLoadDataForGroups;
});
export var getGroupsErrors = createSelector(groupsState, function (_ref10) {
  var errors = _ref10.errors;
  return errors;
});
var getAllGroups = createSelector(groupsState, function (state) {
  return state.groups;
});
export var getGroups = createSelector(getAllGroups, function (groups) {
  return groups.filter(function (g) {
    return !g.leaved;
  });
});
export var getGroup = createSelector(getAllGroups, function (_, groupId) {
  return groupId;
}, function (groups, groupId) {
  return groups.find(function (g) {
    return g.uuid === groupId;
  });
});
export var getNumberOfGroups = createSelector(getGroups, function (groups) {
  return groups.length;
}); // endregion