import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import BackButton from 'components/app/BackButton';
import LevelBar from 'components/pages/levels/LevelBar';
import BoxInfo from 'components/pages/common/box/BoxInfo';

import {
  getGameID,
  getGameCategory,
  getGameLevel,
  changeCategory,
  createGame,
  getGameError,
} from '@wfp/freerice-core/modules/game';
import { getUserID } from '@wfp/freerice-core/modules/user';
import { getLevels } from '@wfp/freerice-core/modules/levels';
import { getLanguage } from '@wfp/freerice-core/modules/app';
import BoxInfoError from 'components/pages/common/box/BoxInfoError/BoxInfoError';
import { withAnimatedNav } from 'components/app/Main/AnimatedNavContext';
import strings from 'res/strings';
import { withTabOrder, tabOrders, TAB_NAMES } from 'components/app/TabOrder';
import withPageTitle from '../common/withPageTitle';


class Levels extends Component {
  static propTypes = {
    category: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    gameId: PropTypes.string,
    levels: PropTypes.array.isRequired,
    level: PropTypes.number.isRequired,
    history: PropTypes.object.isRequired,
    changeCategory: PropTypes.func.isRequired,
    createGame: PropTypes.func.isRequired,
    error: PropTypes.array,
    user: PropTypes.string,
    animatedNav: PropTypes.shape({
      setAnimationState: PropTypes.func.isRequired,
    }).isRequired,
    language: PropTypes.string,
    setTabOrder: PropTypes.func.isRequired,
    focusTo: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {
    gameId: null,
    error: null,
    user: null,
    language: strings.getLanguage(),
  };

  state = {
    // eslint-disable-next-line react/destructuring-assignment
    currentLevel: this.props.level,
  };

  componentDidMount() {
    const { setTabOrder, focusTo, location } = this.props;

    setTabOrder(tabOrders.LEVELS);

    if (location.state && location.state.focusAfter) focusTo(TAB_NAMES.MENU_TOGGLE);
  }

  componentDidUpdate(prevProps) {
    const {
      level,
      history,
      animatedNav: { setAnimationState },
    } = this.props;
    if (level !== prevProps.level) {
      setAnimationState(true, () => history.push('/'));
    }
  }

  levelItemHandler = event => {
    const { target } = event;
    this.setState({ currentLevel: parseInt(target.getAttribute('data-target'), 10) });
  };

  handleSave = () => {
    const { currentLevel } = this.state;
    const { gameId, category, changeCategory, createGame, user, language } = this.props;
    if (!gameId) {
      createGame(category.id, currentLevel, user, language);
    } else {
      changeCategory(gameId, { category: category.id, level: currentLevel }, language);
    }
  };

  render() {
    const { currentLevel } = this.state;
    const { level, category, levels, error } = this.props;

    return (
      <BoxInfo type="medium-width">
        <LevelBar
          currentLevel={currentLevel}
          levels={levels}
          maxLevels={category.levels}
          handlerLevelItem={this.levelItemHandler}
        />
        {!!error && <BoxInfoError messages={error} />}
        <BackButton onClick={this.handleSave} text={strings['levels.backButton']} isVisible={currentLevel !== level} />
      </BoxInfo>
    );
  }
}

export default compose(
  withPageTitle({ title: strings['web.levels_link'] }),
  withAnimatedNav,
  withTabOrder,
  connect(
    createStructuredSelector({
      gameId: getGameID,
      category: getGameCategory,
      levels: getLevels,
      level: getGameLevel,
      error: getGameError,
      user: getUserID,
      language: getLanguage,
    }),
    { changeCategory, createGame }
  )
)(Levels);
