import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Category from 'components/pages/categories/Category';
import BoxInfoContent from 'components/pages/common/box/BoxInfoContent';
import { withTabOrder } from 'components/app/TabOrder';

const categoryGroups = {
  'new-categories': 'New Categories',
  'art-culture': 'Art & Culture',
  geography: 'Geography',
  history: 'History',
  'focus-on-food': 'Focus on Food',
  'ambassadors-for-change': 'Ambassadors for Change',
  english: 'English',
  'language-learning': 'Language learning',
  japanese: 'Japanese',
  chinese: 'Chinese',
  math: 'Mathematics',
  science: 'Science',
  'social-sciences': 'Social Sciences',
  'sustainable-development-goals': 'Sustainable Development Goals',
  // 'wfp-goals': 'Global Goals: Using Facts to Fuel Action',
  // humanities: 'Humanities',
};

class CategoriesContent extends Component {
  static propTypes = {
    currentCategory: PropTypes.object,
    categories: PropTypes.object,
    onCategorySelect: PropTypes.func.isRequired,
    tabOrder: PropTypes.object.isRequired,
  };

  static defaultProps = {
    currentCategory: null,
    categories: {},
  };

  state = { selection: null };

  componentDidUpdate(_, prevState) {
    const { onCategorySelect } = this.props;
    const { selection } = this.state;
    if (selection !== prevState.selection && selection) {
      onCategorySelect(selection);
    }
  }

  handleCategoryClick = event => {
    const selection = event.currentTarget.getAttribute('data-target');
    this.setState({ selection });
  };

  renderCategoryList = () => {
    const { categories, currentCategory, tabOrder } = this.props;
    const { selection } = this.state;

    let tabIndex = tabOrder.CATEGORIES_FIRST;
    let prevCountItems = 0;
    return Object.entries(categoryGroups).map(([group, defaultTitle]) => {
      const items = categories[group];
      const title = (items && items[0].category_group_label) ? items[0].category_group_label : defaultTitle;
      if (!items || !items.length) {
        return null;
      }

      tabIndex += prevCountItems;
      prevCountItems = items.length;

      return (
        <Category
          startTabIndex={tabIndex}
          key={group}
          title={title}
          items={items}
          currentCategory={currentCategory}
          onItemClick={this.handleCategoryClick}
          selection={selection}
        />
      );
    });
  };

  render() {
    const { selection } = this.state;
    return (
      <BoxInfoContent className={selection ? 'page--categories--is-disabled' : null}>
        {this.renderCategoryList()}
      </BoxInfoContent>
    );
  }
}

export default withTabOrder(CategoriesContent);
