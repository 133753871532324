import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const isMobile = window.innerWidth < 992;
const rulerStyles = {
  position: 'fixed',
  zIndex: 1000,
  backgroundColor: 'red',
  height: '100%',
  top: '0',
  left: '0',
  pointerEvents: 'none',
};

export default class MobileBodyHeight extends PureComponent {
  static propTypes = {
    children: PropTypes.func.isRequired,
  };

  state = { height: isMobile ? window.document.body.clientHeight : undefined };

  heightRef = React.createRef();

  componentDidMount() {
    // calculate real height of the window to position globe correctly on mobile devices (especially on iPhone Safari).
    if (isMobile) {
      setTimeout(() => {
        this.setState({ height: this.heightRef.current.clientHeight });
      }, 200);
    }
  }

  render() {
    const { children } = this.props;
    const { height } = this.state;

    return (
      <>
        {isMobile && <div ref={this.heightRef} style={rulerStyles} />}
        {children(height)}
      </>
    );
  }
}
