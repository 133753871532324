import React from 'react';
import PropTypes from 'prop-types';
import './PageHeader.scss';

const PageHeader = ({ title, subTitle }) => (
  <div className="page-header">
    <h1 className="page-header__title">{title}</h1>
    {subTitle && <p className="page-header__subtitle">{subTitle}</p>}
  </div>
);

PageHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
};


PageHeader.defaultProps = {
  title: '',
  subTitle: '',
};
export default PageHeader;
