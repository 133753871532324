import _toConsumableArray from "/home/vsts/work/1/s/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _ from 'lodash';
import { createSelector } from 'reselect';
import { produce } from 'immer';
import { createAction, createRequestTypes } from '../utils/actionHelpers';
import { createApiCall } from '../utils/api';
import { USER } from './user'; // region ACTION TYPES

var base = 'CHALLENGES';
export var CHALLENGES = {
  LIST_CHALLENGES: createRequestTypes("".concat(base, "_LIST_CHALLENGES")),
  LOAD_CHALLENGE: createRequestTypes("".concat(base, "_LOAD_CHALLENGE")),
  USER_CONTRIBUTIONS: createRequestTypes("".concat(base, "_USER_CONTRIBUTIONS")),
  JOIN_CHALLENGE: createRequestTypes("".concat(base, "_JOIN_CHALLENGE")),
  LEAVE_CHALLENGE: createRequestTypes("".concat(base, "_LEAVE_CHALLENGE")),
  CLEAR_ERRORS: "".concat(base, "_CLEAR_ERRORS")
}; // endregion
// region ACTIONS

export var loadChallenges = createApiCall(CHALLENGES.LIST_CHALLENGES, function (_ref) {
  var lang = _ref.lang;
  return {
    url: '/challenges',
    params: {
      lang: lang
    }
  };
});
export var loadChallenge = createApiCall(CHALLENGES.LOAD_CHALLENGE, function (_ref2) {
  var id = _ref2.id;
  return {
    url: "/challenges/".concat(id)
  };
});
export var loadUserContributions = createApiCall(CHALLENGES.USER_CONTRIBUTIONS, function (userId) {
  return {
    url: "/users/".concat(userId, "/challenges")
  };
});
export var joinChallenge = createApiCall(CHALLENGES.JOIN_CHALLENGE, function (challengeId) {
  return {
    url: "/challenges/".concat(challengeId, "/members"),
    method: 'POST',
    withCredentials: true
  };
});
export var leaveChallenge = createApiCall(CHALLENGES.LEAVE_CHALLENGE, function (challengeId) {
  return {
    url: "/challenges/".concat(challengeId, "/members"),
    method: 'DELETE',
    withCredentials: true
  };
});
export var clearErrors = createAction(CHALLENGES.CLEAR_ERRORS); // endregion
// region REDUCER

var initialState = {
  challenges: [],
  userContributions: [],
  loading: false,
  userContributionsLoading: false,
  errors: null,
  userContributionsError: null
};
export default produce(function (draft, _ref3) {
  var type = _ref3.type,
      payload = _ref3.payload;

  switch (type) {
    case CHALLENGES.LIST_CHALLENGES.REQUEST:
    case CHALLENGES.LOAD_CHALLENGE.REQUEST:
    case CHALLENGES.JOIN_CHALLENGE.REQUEST:
    case CHALLENGES.LEAVE_CHALLENGE.REQUEST:
      draft.loading = true;
      break;

    case CHALLENGES.USER_CONTRIBUTIONS.REQUEST:
      draft.userContributionsLoading = true;
      break;

    case CHALLENGES.LOAD_CHALLENGE.SUCCESS:
      {
        draft.loading = false;
        var foundIndex = draft.challenges.findIndex(function (c) {
          return c.id === payload.id;
        });

        if (foundIndex >= 0) {
          draft.challenges[foundIndex] = payload;
        } else {
          draft.challenges.push(payload);
        }

        draft.challenges = _.orderBy(draft.challenges, 'datetime_start', 'desc') || [];
      }
      break;

    case CHALLENGES.LIST_CHALLENGES.SUCCESS:
      draft.loading = false;
      draft.challenges = _.orderBy(_.unionBy([].concat(_toConsumableArray(draft.challenges), _toConsumableArray(payload)), 'id'), 'datetime_start', 'desc') || [];
      break;

    case CHALLENGES.JOIN_CHALLENGE.SUCCESS:
    case CHALLENGES.LEAVE_CHALLENGE.SUCCESS:
      draft.loading = false;
      break;

    case CHALLENGES.USER_CONTRIBUTIONS.SUCCESS:
      draft.userContributionsLoading = false;
      draft.userContributions = payload || [];
      break;

    case CHALLENGES.LIST_CHALLENGES.FAILURE:
    case CHALLENGES.LOAD_CHALLENGE.FAILURE:
    case CHALLENGES.JOIN_CHALLENGE.FAILURE:
    case CHALLENGES.LEAVE_CHALLENGE.FAILURE:
      draft.loading = false;
      draft.errors = payload.message;
      break;

    case CHALLENGES.USER_CONTRIBUTIONS.FAILURE:
      draft.userContributionsLoading = false;
      draft.userContributionsError = payload.message;
      draft.userContributions = [];
      break;

    case CHALLENGES.CLEAR_ERRORS:
      draft.errors = null;
      break;

    case USER.SIGN_OUT:
      draft.userContributions = [];
      break;

    default:
      break;
  }
}, initialState); // endregion
// region SELECTORS

var challengesState = function challengesState(state) {
  return state.challenges;
};

export var getChallenges = createSelector(challengesState, function (state) {
  return state.challenges;
});
export var getUserContributions = createSelector(challengesState, function (state) {
  return state.userContributions;
});
export var isChallengesLoading = createSelector(challengesState, function (state) {
  return state.loading;
});
export var isUserContributionsLoading = createSelector(challengesState, function (state) {
  return state.userContributionsLoading;
});
export var getChallengesErrors = createSelector(challengesState, function (state) {
  return state.errors;
}); // endregion