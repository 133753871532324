import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import './Button.scss';

const Button = ({
  color = 'green',
  isOutlined,
  to,
  text,
  className,
  handlerClick,
  isDisabled = false,
  isLoading,
  tabIndex,
  history,
  ...buttonProps
}) =>
  React.createElement(
    'div',
    {
      tabIndex,
      onKeyPress: () => (to ? history.push(to, { focusAfter: true }) : handlerClick()),
    },
    React.createElement(
      to ? Link : 'button',
      {
        tabIndex: -1,
        disabled: isDisabled,
        onClick: handlerClick,
        to,
        type: to ? undefined : 'button',
        className: `box-button box-button-${isOutlined ? 'outlined' : 'filled'}-${color} ${className}`,
        ...buttonProps,
      },
      isLoading
        ? React.createElement(
          'div',
          {
            className: isLoading ? 'loading-spinner' : '',
          },
          '',
        )
        : text,
    ),
  );

Button.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  handlerClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isDisabled: PropTypes.bool,
  isOutlined: PropTypes.bool,
  isLoading: PropTypes.bool,
  tabIndex: PropTypes.number,
  history: PropTypes.object.isRequired,
};

Button.defaultProps = {
  isOutlined: false,
  isLoading: false,
  color: 'green',
  className: '',
  isDisabled: false,
  to: undefined,
  handlerClick: undefined,
  tabIndex: -1,
};

export default withRouter(Button);
