import _defineProperty from "/home/vsts/work/1/s/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "/home/vsts/work/1/s/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _toArray from "/home/vsts/work/1/s/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toArray";
import _slicedToArray from "/home/vsts/work/1/s/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import upperFirst from 'lodash/upperFirst';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/_baseIsEqualDeep';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';

var required = function required(data, field) {
  return !isEmpty(data[field]);
};

var minLength = function minLength(data, field, length) {
  return isEmpty(data[field]) || data[field].length >= length;
}; // ({ field }) => `${upperFirst(field)} must be at least of ${length} characters.`
// 'Email is not valid.'
// eslint-disable-next-line


var emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

var email = function email(data, field) {
  return isEmpty(data[field]) || emailRegexp.test(data[field]);
};

var match = function match(data, field1, field2) {
  return isEqual(data[field1], data[field2]);
};

var eq = function eq(data, field, value) {
  return isEqual(data[field], value);
};

var age = function age(data, field, value) {
  return isEqual(data[field], value);
};

export var rulesMethods = {
  required: required,
  minLength: minLength,
  email: email,
  match: match,
  eq: eq,
  age: age
};

var validate = function validate(data, config, messages) {
  return Object.entries(config).reduce(function (errors, _ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        field = _ref2[0],
        rules = _ref2[1];

    return errors.concat(rules.split('|').map(function (rule) {
      var _rule$split = rule.split(':'),
          _rule$split2 = _toArray(_rule$split),
          ruleName = _rule$split2[0],
          args = _rule$split2.slice(1);

      if (typeof rulesMethods[ruleName] === 'function') {
        if (rulesMethods[ruleName].apply(rulesMethods, [data, field].concat(_toConsumableArray(args)))) {
          return null;
        }

        var error = messages.formatString(messages["app.validation.".concat(ruleName)], _objectSpread({
          field: messages["fields.".concat(field)] || upperFirst(field)
        }, mapKeys(mapValues(args, function (value) {
          return messages["fields.".concat(value)] || value;
        }), function (_, key) {
          return "arg_".concat(key);
        })));
        return error;
      }

      throw Error("Rule ".concat(ruleName, " is not defined."));
    }));
  }, []).filter(function (message) {
    return !!message;
  });
};

export default validate;