import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

import BoxInfoProgressBar from 'components/pages/common/box/BoxInfoProgressBar';
import BoxInfoContent from 'components/pages/common/box/BoxInfoContent';
import BoxInfoTitle from 'components/pages/common/box/BoxInfoTitle';
import BoxInfoError from 'components/pages/common/box/BoxInfoError';
import Button from 'components/pages/common/Button';
import Input from 'components/pages/common/Input';
import './RegistrationContent.scss';

import { withTabOrder } from 'components/app/TabOrder';
import strings from 'res/strings';

class RegistrationContent extends Component {
  static propTypes = {
    handlerCreateAccount: PropTypes.func.isRequired,
    errors: PropTypes.array,
    isLoading: PropTypes.bool.isRequired,
    validation: PropTypes.object.isRequired,
    tabOrder: PropTypes.object.isRequired,
  };

  static defaultProps = {
    errors: [],
  };

  state = {
    username: '',
    email: '',
    password: '',
    confirm_password: '',
    // validAge: true,
    captcha: '',
    right_to_email_send: false,
  };

  // changeAgeHandler = (event) => {
  //   this.setState({ validAge: event.target.id === 'up13' });
  // };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  changeRightToEmailSendHandler = event => {
    this.setState({ right_to_email_send: event.target.checked });
  };

  createAccountHandler = () => {
    // eslint-disable-next-line camelcase
    const { username, email, password, confirm_password, captcha, right_to_email_send } = this.state;
    const { handlerCreateAccount } = this.props;

    console.log('right_to_email_send: ', right_to_email_send);

    handlerCreateAccount(username, email, password, confirm_password, captcha);
  };

  onChangeCaptcha = captcha => {
    this.setState({ captcha });
  };

  render() {
    // const { validAge } = this.state;
    const { isLoading, errors, validation, tabOrder } = this.props;

    return (
      <Fragment>
        <BoxInfoContent>
          <BoxInfoTitle text={strings['creation.title.createAccount']} />
          <BoxInfoProgressBar isVisible={isLoading}>
            <Input
              tabIndex={tabOrder.REGISTRATION_USERNAME}
              id="register-username"
              type="text"
              name="username"
              placeholder={strings['creation.placeholder.username']}
              isInputError={validation.isUsername}
              handlerChange={this.changeHandler}
              label={strings['creation.input.username']}
            />
            <Input
              tabIndex={tabOrder.REGISTRATION_EMAIL}
              id="register-email"
              type="email"
              placeholder={strings['creation.placeholder.email']}
              isInputError={validation.isEmail}
              handlerChange={this.changeHandler}
              label={strings['creation.input.email']}
            />
            <p>
              {strings['creation.web.input.email.text1']}
              <b>{strings['creation.web.input.email.text2']}</b>
              {strings['creation.web.input.email.text3']}
            </p>
            <p>
              {strings['creation.web.input.email.text4']}
              <b>{strings['creation.web.input.email.text5']}</b>
              {strings['creation.web.input.email.text6']}
            </p>
            <h3>{strings['creation.title.password']}</h3>
            <Input
              tabIndex={tabOrder.REGISTRATION_PASSWORD}
              id="register-password"
              type="password"
              name="password"
              placeholder={strings['creation.placeholder.password']}
              isInputError={validation.isPassword}
              handlerChange={this.changeHandler}
              label={strings['creation.input.password']}
            />
            <Input
              tabIndex={tabOrder.REGISTRATION_PASSWORD_CONFIRM}
              id="register-password-confirm"
              type="password"
              name="confirm_password"
              isInputError={validation.isConfirmPassword}
              handlerChange={this.changeHandler}
              placeholder={strings['creation.placeholder.confirm']}
              label={strings['creation.input.confirm']}
            />
            {validation.messages && validation.messages.length !== 0 && <BoxInfoError messages={validation.messages} />}
            {errors && errors.length !== 0 && <BoxInfoError messages={errors} />}
            {/* <AgeRegistration handlerChangeAge={this.changeAgeHandler} /> */}
          </BoxInfoProgressBar>
        </BoxInfoContent>
        {process.env.REACT_APP_GOOGLE_CAPTCHA && (
          <div className="captcha-container">
            <ReCAPTCHA size="normal" sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA} onChange={this.onChangeCaptcha} />
          </div>
        )}
        <div className="register-button">
          <Button
            tabIndex={tabOrder.REGISTRATION_BUTTON}
            color="green"
            // isDisabled={!validAge}
            text={strings['creation.button.create']}
            className="user-register-submit"
            handlerClick={this.createAccountHandler}
          />
        </div>
      </Fragment>
    );
  }
}

export default withTabOrder(RegistrationContent);
