import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './CardButton.scss';

const CardButton = ({ buttonId, state, text, onClick, className, keyboardKeyBind, disabled, tabIndex }) => {
  const buttonRef = useRef(null);

  useEffect(() => {
    const keyboardPressHandler = (e) => {
      const eventKeyCode = e.which || e.keyCode;
      const eventKeyCodeString = String.fromCharCode(eventKeyCode);
      if (keyboardKeyBind && !disabled && eventKeyCodeString === keyboardKeyBind) {
        buttonRef.current.click();
      }
    };

    document.addEventListener('keypress', keyboardPressHandler, false);

    return () => {
      document.removeEventListener('keypress', keyboardPressHandler);
    };
  }, [keyboardKeyBind, buttonRef, disabled]);

  return (
    <div
      tabIndex={tabIndex}
      onClick={onClick}
      onKeyPress={(e) => e.charCode === 13 && onClick({
        target: buttonRef.current,
      }, true)}>
      <div
        tabIndex={-1}
        ref={buttonRef}
        className={classNames('card-button', className, {
          correct: state === 'CORRECT',
          wrong: state === 'WRONG',
          selected: state === 'SELECTED',
          disabled,
        })}
        data-target={buttonId}
        dir="ltr"
      >
        {text}
      </div>
    </div>
  );
};

CardButton.propTypes = {
  buttonId: PropTypes.string.isRequired,
  state: PropTypes.oneOf(['SELECTED', 'CORRECT', 'WRONG']),
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  keyboardKeyBind: PropTypes.string,
  tabIndex: PropTypes.number,
};

CardButton.defaultProps = {
  state: undefined,
  className: '',
  disabled: false,
  keyboardKeyBind: '',
  tabIndex: 0,
};

export default CardButton;
