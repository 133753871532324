import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import withPageTitle from 'containers/common/withPageTitle';
import BoxInfo from 'components/pages/common/box/BoxInfo';
import BoxInfoContent from 'components/pages/common/box/BoxInfoContent';
import BoxInfoMessage from 'components/pages/common/box/BoxInfoMessage';
import BoxInfoProgressBar from 'components/pages/common/box/BoxInfoProgressBar';
import BoxInfoTitle from 'components/pages/common/box/BoxInfoTitle';
import BoxInfoError from 'components/pages/common/box/BoxInfoError';
import Button from 'components/pages/common/Button';
import BackButton from 'components/app/BackButton';
import Input from 'components/pages/common/Input';

import { loginRecover, getUserDataErrors, isLoadingUser } from '@wfp/freerice-core/modules/user';
import { getLanguage } from '@wfp/freerice-core/modules/app';
import validate from '@wfp/freerice-core/utils/validators';
import { withTabOrder, tabOrders, TAB_NAMES } from 'components/app/TabOrder';

import strings from 'res/strings';

class ForgotPassword extends Component {
  static propTypes = {
    errors: PropTypes.array,
    isLoading: PropTypes.bool,
    loginRecover: PropTypes.func.isRequired,
    language: PropTypes.string,
    setTabOrder: PropTypes.func.isRequired,
    focusTo: PropTypes.func.isRequired,
    tabOrder: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {
    errors: [],
    isLoading: false,
    language: strings.getLanguage(),
  };

  state = {
    error: '',
    email: '',
    phase: null,
  };

  componentDidMount() {
    const { setTabOrder, focusTo, location } = this.props;

    setTabOrder(tabOrders.FORGOT_PASSWORD);

    if (location.state && location.state.focusAfter) focusTo(TAB_NAMES.MENU_TOGGLE);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.phase === 'submitting' && nextProps.isLoading) {
      return { phase: 'sending' };
    }
    if (prevState.phase === 'sending' && !nextProps.isLoading) {
      return { phase: 'submitted' };
    }
    return null;
  }

  resetPasswordHandler = () => {
    const { email } = this.state;
    const { loginRecover, language } = this.props;
    if (this.isValid(email)) {
      this.setState({ phase: 'submitting' }, () => {
        loginRecover(email, language);
      });
    }
  };

  isValid = email => {
    let isValid = false;
    const messages = validate({ email }, {
      email: 'required|email',
    }, strings);

    if (messages.length) {
      this.setState({ error: messages[0] });
    } else {
      this.setState({ error: '' });
      isValid = true;
    }

    return isValid;
  };

  changeHandler = event => {
    this.setState({ email: event.target.value, error: '' });
  };

  render() {
    const { error, email, phase } = this.state;
    const { errors, isLoading, tabOrder } = this.props;
    return (
      <BoxInfo type="medium-width">
        <BoxInfoContent className="box-info-content--auth">
          <BoxInfoTitle text={strings['forgot.title.forgot']} />
          <BoxInfoProgressBar isVisible={isLoading}>
            <Input
              tabIndex={tabOrder.FORGOT_PASSWORD_EMAIL}
              type="email"
              id="reset-email"
              name="email"
              placeholder={strings['forgot.placeholder.email']}
              value={email}
              isInputError={!!error}
              handlerChange={this.changeHandler}
              label={strings['forgot.input.email']}
            />
            {error && <BoxInfoError messages={[error]} />}
            {errors && <BoxInfoError messages={errors} />}
            <Button
              tabIndex={tabOrder.FORGOT_PASSWORD_BUTTON}
              color="green"
              text={strings['forgot.button.reset']}
              handlerClick={this.resetPasswordHandler}
            />
          </BoxInfoProgressBar>
          {phase === 'submitted' && !errors.length && (
            <BoxInfoMessage text={strings['forgot.success']} />
          )}
        </BoxInfoContent>
        <BackButton />
      </BoxInfo>
    );
  }
}

export default withTabOrder(withPageTitle({ title: strings['forgot.title.profile'] })(
  connect(
    createStructuredSelector({ errors: getUserDataErrors, isLoading: isLoadingUser, language: getLanguage }),
    { loginRecover }
  )(ForgotPassword)
));
