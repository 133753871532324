import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BoxInfo from 'components/pages/common/box/BoxInfo';
import './BoxInfoImage.scss';
import BoxInfoProgressBar from 'components/pages/common/box/BoxInfoProgressBar';
import EditButton from 'components/pages/common/EditButton';

const BoxInfoImage = ({ imageType, loading, children, avatarId, groupIconId, handlerClick, editMode }) => (
  <BoxInfo type="with-image" className="box-info--medium-width">
    <BoxInfoProgressBar isVisible={loading} isCircle>
      <div
        className={classNames(
          `box-info-image box-info-image--${imageType}`,
          { [`${avatarId}`]: avatarId },
          { [`${groupIconId}`]: groupIconId }
        )}
      >
        {editMode && <EditButton className="edit-image" handlerClick={handlerClick} />}
      </div>
    </BoxInfoProgressBar>
    {children}
  </BoxInfo>
);

BoxInfoImage.propTypes = {
  imageType: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  children: PropTypes.any.isRequired,
  avatarId: PropTypes.string,
  groupIconId: PropTypes.string,
  handlerClick: PropTypes.func,
  editMode: PropTypes.bool,
};

BoxInfoImage.defaultProps = {
  loading: false,
  editMode: false,
  avatarId: null,
  groupIconId: null,
  handlerClick: () => {},
};

export default BoxInfoImage;
