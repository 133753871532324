import React from 'react';
import PropTypes from 'prop-types';
import SocialLinks from 'components/app/SocialLinks';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getLanguage } from '@wfp/freerice-core/modules/app';

import { withTabOrder } from 'components/app/TabOrder';
import strings from 'res/strings';

import './Footer.scss';

const Footer = ({ language, tabOrder, history }) => {
  const footerClass = language === 'ar' ? 'footer-rtl' : 'footer';

  return (
    <footer className={`${footerClass} container`}>
      <div className="row">
        <div className="column">
          <div
            tabIndex={tabOrder.FOOTER_LOGO}
            onKeyPress={() => {
              window.location.href = 'http://www.wfp.org';
            }}
          >
            <a
              tabIndex={-1}
              className={`${footerClass}__logo`}
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.wfp.org"
            >
              {strings['footer.web.name']}
            </a>
          </div>
          <p>
            <strong>
              {strings['footer.web.address']}
              <br />
              {strings['footer.web.city']}
            </strong>
          </p>
        </div>
        <div className="column">
          <p>
            {strings['footer.web.notice']}
          </p>
          <SocialLinks />
        </div>
      </div>
      <p className={`${footerClass}__copyright`}>
        {strings['footer.web.copyright']}
        {' '}
        <span
          tabIndex={tabOrder.PRIVACY_POLICY}
          onKeyPress={() => {
            history.push('/privacy-policy', { focusAfter: true });
          }}
        >
          {/* <Link tabIndex={-1} to="/privacy-policy"> */}
          <a
            tabIndex={-1}
            href="https://assets.freerice.com/privacy/policy.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            {strings['web.privace_policy_link']}
          </a>
          {/* <Link tabIndex={-1} to="https://assets.freerice.com/privacy/policy.html">
            {strings['web.privace_policy_link']}
          </Link> */}
        </span>
      </p>
    </footer>
  );
};

Footer.propTypes = {
  language: PropTypes.string,
  tabOrder: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

Footer.defaultProps = {
  language: strings.getLanguage(),
};

export default withRouter(connect((state) => ({
  language: getLanguage(state),
}))(withTabOrder(Footer)));
