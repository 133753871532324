
export const TAB_NAMES = {
  MENU_TOGGLE: 'MENU_TOGGLE',
  SIDE_DRAWER_CLOSE: 'SIDE_DRAWER_CLOSE',
  EDIT_BUTTON: 'EDIT_BUTTON',
  ICON_LIST_CLOSE_BUTTON: 'ICON_LIST_CLOSE_BUTTON',
};

const menuTabs = {
  [TAB_NAMES.MENU_TOGGLE]: 1,

  [TAB_NAMES.SIDE_DRAWER_CLOSE]: 2,
  SIDE_DRAWER_PROFILE_LINK: 3,
  SIDE_DRAWER_LOGIN_LINK: 4,
  SIDE_DRAWER_REGISTER_LINK: 5,
  SIDE_DRAWER_MENU_ITEM_1: 6,
  SIDE_DRAWER_MENU_ITEM_2: 7,
  SIDE_DRAWER_MENU_ITEM_3: 8,
  SIDE_DRAWER_MENU_ITEM_4: 9,
  SIDE_DRAWER_MENU_ITEM_5: 10,
  SIDE_DRAWER_MENU_ITEM_6: 11,
  SIDE_DRAWER_MENU_ITEM_7: 12,
  SIDE_DRAWER_LANGUAGE_SELECTOR: 13,
  SIDE_DRAWER_LANGUAGE_SELECTOR_ITEM_1: 14,
};

const footerTabs = (startWith, inc = 1) => ({
  FOOTER_LOGO: startWith,
  PRIVACY_POLICY: startWith + inc * 1,
  SOCIAL_LINK_1: startWith + inc * 2,
  SOCIAL_LINK_2: startWith + inc * 3,
  SOCIAL_LINK_3: startWith + inc * 4,
  SOCIAL_LINK_4: startWith + inc * 5,
});


export default {
  GAME: {
    ...menuTabs,
    MIGRATE_TOTAL_BLOCK_COMMUNITY_GUIDELINES: 14,
    MIGRATE_TOTAL_BLOCK_ACTION_BUTTON: 15,
    MIGRATE_TOTAL_BLOCK_CLOSE_IT: 16,
    GET_THE_APP_IOS: 17,
    GET_THE_APP_ANDROID: 18,
    QUESTION_IMAGE_BUTTON: 19,
    QUESTION_TEXT: 20,
    QUESTION_ANSWER_1: 21,
    QUESTION_ANSWER_2: 22,
    QUESTION_ANSWER_3: 23,
    QUESTION_ANSWER_4: 24,
    PROFILE_LINK: 25,
    ADVERTISEMENT_1: 26,
    ADVERTISEMENT_2: 27,

    ...footerTabs(28),
  },
  LOGIN: {
    ...menuTabs,
    PROFILE_LINK: -1,
    LOGIN_USERNAME: 14,
    LOGIN_PASSWORD: 15,
    LOGIN_FORGOT_PASSWORD: 16,
    LOGIN_BUTTON: 17,
    LOGIN_REGISTRATION_BUTTON: 18,
    LOGIN_USE_FACEBOOK_BUTTON: 19,
    BACK_BUTTON: 20,

    ...footerTabs(21),
  },
  USE_FACEBOOK_TO_LOGIN: {
    ...menuTabs,
    PROFILE_LINK: -1,

    BACK_BUTTON: 20,

    ...footerTabs(21),
  },
  FORGOT_PASSWORD: {
    ...menuTabs,
    PROFILE_LINK: -1,
    FORGOT_PASSWORD_EMAIL: 14,
    FORGOT_PASSWORD_BUTTON: 15,
    BACK_BUTTON: 16,

    ...footerTabs(17),
  },
  REGISTRATION: {
    ...menuTabs,
    PROFILE_LINK: -1,
    REGISTRATION_USERNAME: 14,
    REGISTRATION_EMAIL: 15,
    REGISTRATION_RIGHT_TO_SEND_EMAIL: 16,
    REGISTRATION_PASSWORD: 17,
    REGISTRATION_PASSWORD_CONFIRM: 18,
    REGISTRATION_AGE: 19,
    REGISTRATION_BUTTON: 0,
    BACK_BUTTON: 0,

    ...footerTabs(0, 0),
  },
  REGISTRATION_SUCCESS: {
    ...menuTabs,
    PROFILE_LINK: -1,
    PROFILE_BUTTON: 14,

    ...footerTabs(0, 0),
  },
  CATEGORIES: {
    ...menuTabs,

    CATEGORIES_FIRST: 14,

    BACK_BUTTON: 40,

    FOOTER_LOGO: 41,
    PRIVACY_POLICY: 42,

    PROFILE_LINK: 43,

    SOCIAL_LINK_1: 44,
    SOCIAL_LINK_2: 45,
    SOCIAL_LINK_3: 46,
    SOCIAL_LINK_4: 47,
  },
  LEVELS: {
    ...menuTabs,

    LEVEL_1: 14,
    LEVEL_2: 15,
    LEVEL_3: 16,
    LEVEL_4: 17,
    LEVEL_5: 18,

    BACK_BUTTON: 19,
    PROFILE_LINK: 20,

    ...footerTabs(21),
  },
  GROUPS_ANONYMOUS: {
    ...menuTabs,

    ANONYMOUS_LOGIN: 14,
    ANONYMOUS_SIGN_UP: 15,
    BACK_BUTTON: 16,
    PROFILE_LINK: 17,

    ...footerTabs(18),
  },
  GROUPS: {
    ...menuTabs,

    GO_TO_MY_GROUPS: 14,
    MAKE_NEW_GROUP_INPUT: 15,
    MAKE_NEW_GROUP_BUTTON: 16,
    JOIN_EXISTING_GROUP_INPUT: 17,
    JOIN_EXISTING_GROUP_BUTTON: 18,
    BACK_BUTTON: 19,
    PROFILE_LINK: 20,

    ...footerTabs(21),
  },
  MY_GROUPS: {
    ...menuTabs,

    GROUPS_START_INDEX: 14,
    BACK_BUTTON: 114,
    PROFILE_LINK: 115,

    ...footerTabs(116),
  },
  GROUP: {
    ...menuTabs,

    TABS: 14,
    EDIT_GROUP_BUTTON: 15,
    LEAVE_GROUP_BUTTON: 16,

    BACK_BUTTON: 17,
    PROFILE_LINK: 18,

    ...footerTabs(19),
  },
  LEADERBOARD: {
    ...menuTabs,

    TABS: 14,
    LEADERBOARD_START_INDEX: 15,
    BACK_BUTTON: 115,
    PROFILE_LINK: 116,

    ...footerTabs(117),
  },
  ABOUT: {
    ...menuTabs,

    ABOUT_LINK_1: 14,
    ABOUT_LINK_2: 15,
    ABOUT_LINK_3: 16,
    ABOUT_LINK_4: 17,
    ABOUT_LINK_5: 18,
    ABOUT_LINK_6: 19,
    ABOUT_LINK_7: 20,
    ABOUT_LINK_8: 21,
    ABOUT_LINK_9: 22,

    PROFILE_LINK: 23,

    ...footerTabs(24),
  },
  PROFILE: {
    ...menuTabs,

    TABS: 14,
    EDIT_PROFILE_BUTTON: 15,
    SIGN_OUT_BUTTON: 16,
    BACK_BUTTON: 17,
    PROFILE_LINK: 18,

    ...footerTabs(117),
  },
  EDIT_PROFILE: {
    ...menuTabs,

    [TAB_NAMES.EDIT_BUTTON]: 14,
    EDIT_PROFILE_USERNAME: 15,
    EDIT_PROFILE_EMAIL: 16,
    EDIT_PROFILE_PASSWORD: 17,
    EDIT_PROFILE_PASSWORD_CONFIRM: 18,
    EDIT_PROFILE_SAVE_BUTTON: 19,

    [TAB_NAMES.ICON_LIST_CLOSE_BUTTON]: 20,
    ICON_LIST_START: 21,

    BACK_BUTTON: 37,
    PROFILE_LINK: 38,

    ...footerTabs(39),
  },
};
