import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getLanguage } from '@wfp/freerice-core/modules/app';

import logo from 'res/images/logo.svg';
import { ReactComponent as MenuToggle } from 'res/images/navigation/menu.svg';
import './ToolBar.scss';

import strings from 'res/strings';
import { withTabOrder, TAB_NAMES } from 'components/app/TabOrder';

const TopBar = ({ handlerDrawerClick, user, language, tabOrder, addFocus, history }) => {
  const toolbarClass = language === 'ar' ? 'toolbar-rtl' : 'toolbar';
  return (
    <div className={`${toolbarClass}-wrapper`}>
      <header className={`${toolbarClass} container`}>
        <div className={`${toolbarClass}__menu-toggle`}>
          <div
            ref={elem => addFocus(TAB_NAMES.MENU_TOGGLE, () => {
              if (elem) elem.focus();
            })}
            tabIndex={tabOrder.MENU_TOGGLE}
            onKeyPress={() => handlerDrawerClick({ focusAfter: true })}
            className={`${toolbarClass}__menu-toggle-icon-container`}
          >
            <MenuToggle
              className={`${toolbarClass}__menu-toggle-icon`}
              tabIndex={-1}
              onClick={handlerDrawerClick}
              title="Menu Toggle"
            />
          </div>
        </div>
        <Link tabIndex={-1} className={`${toolbarClass}__logo ${toolbarClass}__logo--freerice`} to="/game">
          <img src={logo} alt={strings['web.title']} />
        </Link>
        <div className={`${toolbarClass}__user`}>
          <span className={`${toolbarClass}__user-salut`}>
            {!!user.username && (
              <div
                tabIndex={tabOrder.PROFILE_LINK}
                onKeyPress={() => history.push('/profile', { focusAfter: true })}
              >
                {strings['drawer.header']}
                <Link tabIndex={-1} to="/profile">{user.username}</Link>
              </div>
            )}
            {!user.username && (
              <div
                tabIndex={tabOrder.PROFILE_LINK}
                onKeyPress={() => history.push('/profile-login', { focusAfter: true })}
              >
                <Link tabIndex={-1} to="/profile-login">{strings['anonymous.text.login']}</Link>
              </div>
            )}
          </span>
          <Link tabIndex={-1} to={user.username ? '/profile' : '/profile-login'}>
            <span className={`${toolbarClass}__user-avatar user-${user.avatar || 'default'}`} />
          </Link>
        </div>
      </header>
    </div>
  );
};

TopBar.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    avatar: PropTypes.string,
  }),
  handlerDrawerClick: PropTypes.func.isRequired,
  language: PropTypes.string,
  tabOrder: PropTypes.object.isRequired,
  addFocus: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

TopBar.defaultProps = {
  user: {},
  language: strings.getLanguage(),
};

export default withTabOrder(withRouter(connect((state) => ({
  language: getLanguage(state),
}))(TopBar)));
