import React from 'react';
import PropTypes from 'prop-types';
import { withTabOrder } from 'components/app/TabOrder';

import strings from 'res/strings';

const ExpandButton = ({ openedImage, tabOrder, onClick, ...props }) => (
  <div
    tabIndex={tabOrder.QUESTION_IMAGE_BUTTON}
    onKeyPress={onClick}
    className="question-image--button"
  >
    <div tabIndex={-1}>
      <button
        tabIndex={-1}
        type="button"
        {...props}
      >
        {openedImage ? strings['game.hideHint'] : strings['game.showHint']}
        <svg
          x="0px"
          y="0px"
          width="20px"
          height="22px"
          viewBox="0 0 18.57 22.36"
          enableBackground="0 0 18.57 22.36"
          xmlSpace="preserve">
          <g>
            {/* eslint-disable max-len */}
            <path d="M16.51,9.29a2,2,0,0,0-1,.16A2.15,2.15,0,0,0,13.59,8a2.11,2.11,0,0,0-1.18.26,2.24,2.24,0,0,0-1.73-1.11,1.9,1.9,0,0,0-.85.13V5.13A2.22,2.22,0,0,0,7.76,2.88a2.08,2.08,0,0,0-1.6.59A2.15,2.15,0,0,0,5.48,5v8.05l-.63-.56A2.35,2.35,0,0,0,2.1,12a2.11,2.11,0,0,0-1,1.38,2.25,2.25,0,0,0,.33,1.76L6,22S6,22,6,22l.11.16a.49.49,0,0,0,.39.2h9.82a.49.49,0,0,0,.43-.26l.19-.35h0l1.25-2.3A2.81,2.81,0,0,0,18.56,18V11.51A2.21,2.21,0,0,0,16.51,9.29Zm.75,5.85L17.13,18a1.65,1.65,0,0,1-.2.78L15.71,21H7L2.59,14.38a.92.92,0,0,1-.13-.65.73.73,0,0,1,.33-.46c.13-.06.39-.23,1.14.36l1.21,1a1.05,1.05,0,0,1,.33.72v.46a.72.72,0,0,0,1.44,0V5.05a.88.88,0,0,1,.23-.55.72.72,0,0,1,.56-.2.82.82,0,0,1,.72.82v7.46a.72.72,0,0,0,1.44,0V9.31a.9.9,0,0,1,.23-.56.7.7,0,0,1,.55-.2.81.81,0,0,1,.72.82v3.24a.73.73,0,0,0,.7.75.71.71,0,0,0,.74-.69V10.13A1,1,0,0,1,13,9.57a.89.89,0,0,1,.56-.2.83.83,0,0,1,.72.82v3.34a.72.72,0,0,0,.69.75.71.71,0,0,0,.75-.69V11.41a.74.74,0,0,1,.79-.76.82.82,0,0,1,.72.82Z" />
            <path d="M14.41,6.7h-.18a.73.73,0,0,1-.62-.82h0a6.14,6.14,0,0,0-1.09-4.47.71.71,0,0,1,.18-1,.71.71,0,0,1,1,.16A7.53,7.53,0,0,1,15,6.09.71.71,0,0,1,14.41,6.7Z" />
            <path d="M2.1,9.72a.75.75,0,0,1-.67-.3A7.57,7.57,0,0,1,.6,2a.73.73,0,0,1,1-.39.72.72,0,0,1,.38,1,6.11,6.11,0,0,0,.67,6,.73.73,0,0,1-.5,1.15Z" />
            <path d="M7.56,1.55A3.44,3.44,0,0,1,11,5a3.38,3.38,0,0,1-.26,1.29,3,3,0,0,1,1.48.52A5,5,0,1,0,2.56,5a5,5,0,0,0,.26,1.59h0A5,5,0,0,0,4.59,9V6.71a3.43,3.43,0,0,1,3-5.16Z" />
            {/* eslint-enable max-len */}
          </g>
        </svg>
      </button>
    </div>
  </div>
);

ExpandButton.propTypes = {
  openedImage: PropTypes.bool.isRequired,
  tabOrder: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default withTabOrder(ExpandButton);
