import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import axios from 'axios';
import reducers from './modules';
import sagas from './sagas';
import { appStart } from './modules/app'; // Configure axios client.

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json;version=2';
export var configureStore = function configureStore(initialState, _ref) {
  var _ref$compose = _ref.compose,
      composeEnhancer = _ref$compose === void 0 ? compose : _ref$compose,
      sessionStorage = _ref.sessionStorage,
      localStorage = _ref.localStorage,
      accountsBaseUrl = _ref.accountsBaseUrl,
      engineBaseUrl = _ref.engineBaseUrl,
      frontendVersion = _ref.frontendVersion,
      language = _ref.language;
  var engineApiClient = axios.create({
    baseURL: engineBaseUrl
  });
  engineApiClient.defaults.headers['Content-Type'] = 'application/json';
  engineApiClient.defaults.headers.Accept = 'application/vnd.api+json;version=2';
  engineApiClient.interceptors.request.use(function (config) {
    if (frontendVersion.startsWith('web')) {
      var zipCode = localStorage.getItem('zipCode');

      if (zipCode) {
        config.headers['X-Freerice-ZipCode'] = zipCode;
      }
    }

    return config;
  });
  var sagaMiddleware = createSagaMiddleware({
    context: {
      sessionStorage: sessionStorage,
      localStorage: localStorage,
      accountsApiClient: axios.create({
        baseURL: accountsBaseUrl
      }),
      engineApiClient: engineApiClient,
      frontendVersion: frontendVersion,
      language: language
    }
  });
  var middleware = [sagaMiddleware];
  var enhancers = composeEnhancer(applyMiddleware.apply(void 0, middleware));
  var store = createStore(reducers, initialState, enhancers);
  sagaMiddleware.run(sagas);
  store.dispatch(appStart());
  return store;
};
export var rootReducer = reducers;
export var rootSaga = sagas;