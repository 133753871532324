import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import withPageTitle from 'containers/common/withPageTitle';
import BoxInfoImage from 'components/pages/common/box/BoxInfoImage';
import BoxInfoError from 'components/pages/common/box/BoxInfoError';
import GroupsContent from 'components/pages/groups/GroupsContent';
import BackButton from 'components/app/BackButton';
import withPrivateArea from 'containers/common/PrivateArea';

import {
  isLoading,
  createNewGroup,
  getGroups,
  getNumberOfGroups,
  loadMyGroups,
  joinExistingGroup,
  getGroupsErrors,
} from '@wfp/freerice-core/modules/groups';
import { getLanguage } from '@wfp/freerice-core/modules/app';
import validate from '@wfp/freerice-core/utils/validators';
import { withTabOrder, tabOrders, TAB_NAMES } from 'components/app/TabOrder';

import strings from 'res/strings';

class Groups extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    loadMyGroups: PropTypes.func.isRequired,
    createNewGroup: PropTypes.func.isRequired,
    joinExistingGroup: PropTypes.func.isRequired,
    numberOfGroups: PropTypes.number.isRequired,
    user: PropTypes.object.isRequired,
    isLoadingGroups: PropTypes.bool.isRequired,
    groupsErrors: PropTypes.any,
    language: PropTypes.string,
    setTabOrder: PropTypes.func.isRequired,
    focusTo: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {
    groupsErrors: null,
    language: strings.getLanguage(),
  };

  state = {
    errors: {
      newGroup: '',
      groupCode: '',
    },
  };

  componentDidMount() {
    const { loadMyGroups, setTabOrder, focusTo, location } = this.props;

    loadMyGroups();

    setTabOrder(tabOrders.GROUPS);

    if (location.state && location.state.focusAfter) focusTo(TAB_NAMES.MENU_TOGGLE);
  }

  onSuccess = ({ uuid }) => {
    const { history } = this.props;
    history.push(`/group/${uuid}`);
  }

  makeNewGroupHandler = ({ name }) => {
    if (!this.isValidGroupName(name)) {
      return;
    }
    const { createNewGroup, language } = this.props;

    createNewGroup({ name, avatar: '', lang: language }, { onSuccess: this.onSuccess });
  };

  joinGroupHandler = ({ code }) => {
    if (!this.isValidGroupCode(code)) {
      return;
    }
    const { joinExistingGroup, language } = this.props;

    joinExistingGroup({ code, lang: language }, { onSuccess: this.onSuccess });
  };

  isValidGroupName = groupName => {
    const messages = validate({
      name: groupName,
    }, {
      name: 'required|minLength:3',
    }, strings);

    this.setState(prevState => ({
      errors: {
        newGroup: messages.length ? messages[0] : '',
        groupCode: prevState.errors.groupCode,
      },
    }));

    return !messages.length;
  };

  isValidGroupCode = code => {
    const messages = validate({
      code,
    }, {
      code: 'required',
    }, strings);

    this.setState(prevState => ({
      errors: {
        groupCode: messages.length ? messages[0] : '',
        newGroup: prevState.errors.newGroup,
      },
    }));

    return !messages.length;
  };

  render() {
    const { errors } = this.state;
    const { numberOfGroups, user, isLoadingGroups, groupsErrors } = this.props;

    return (
      <BoxInfoImage imageType="profile-avatar" avatarId={user.avatar}>
        {Array.isArray(groupsErrors) && <BoxInfoError messages={groupsErrors} />}
        <GroupsContent
          groupsErrors={Array.isArray(groupsErrors) ? null : groupsErrors}
          numberOfGroups={numberOfGroups}
          user={user}
          errors={errors}
          isLoadingGroups={isLoadingGroups}
          handlerMakeNewGroup={this.makeNewGroupHandler}
          handlerJoinGroup={this.joinGroupHandler}
          showMyGroupsButton
        />
        <BackButton />
      </BoxInfoImage>
    );
  }
}

export default compose(
  withPrivateArea({ fallback: '/groups-anonymous' }),
  withPageTitle({ title: strings.groups_link }),
  withTabOrder,
  connect(
    createStructuredSelector({
      groups: getGroups,
      numberOfGroups: getNumberOfGroups,
      isLoadingGroups: isLoading,
      groupsErrors: getGroupsErrors,
      language: getLanguage,
    }),
    { loadMyGroups, createNewGroup, joinExistingGroup }
  )
)(Groups);
