import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';

const MenuItem = ({
  to,
  exact,
  isActive,
  groupName,
  itemName,
  tabIndex,
  hasRedCircle,
  handlerClick,
  children,
  history,
}) => {
  const linkRef = useRef(null);

  return (
    <div
      tabIndex={tabIndex}
      onKeyPress={() => handlerClick({ focusAfter: false }) || history.push(to, { focusAfter: true })}
    >
      <NavLink
        tabIndex={-1}
        to={to}
        exact={exact}
        className={`${groupName}-item ${groupName}-item--${itemName}`}
        isActive={isActive}
        innerRef={linkRef}
      >
        {children}
        {!!hasRedCircle && (
          <div className={`${groupName}-item-red-circle`} />
        )}
      </NavLink>
    </div>
  );
};

MenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  exact: PropTypes.bool.isRequired,
  isActive: PropTypes.func,
  tabIndex: PropTypes.number,
  hasRedCircle: PropTypes.bool,
  handlerClick: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

MenuItem.defaultProps = {
  isActive: undefined,
  tabIndex: 0,
  hasRedCircle: false,
};

export default withRouter(MenuItem);
