import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLanguage } from '@wfp/freerice-core/modules/app';

import strings from 'res/strings';

import Tabs from 'components/pages/common/tab/Tabs';
import BoxInfoContent from 'components/pages/common/box/BoxInfoContent';
import BoxInfoTitle from 'components/pages/common/box/BoxInfoTitle';
import BoxInfoProgressBar from 'components/pages/common/box/BoxInfoProgressBar';
import { withTabOrder } from 'components/app/TabOrder';
import LeaderboardItem from './LeaderboardItem';

import './Leaderboard.scss';

class Leaderboard extends PureComponent {
  static propTypes = {
    users: PropTypes.array,
    groups: PropTypes.array,
    currentUser: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
    tabOrder: PropTypes.object.isRequired,
    language: PropTypes.string,
  };

  static defaultProps = {
    users: [],
    groups: [],
    currentUser: null,
    language: strings.getLanguage(),
  };

  tabs = [
    { name: 'players', label: strings['leaderboard.players.title'] },
    { name: 'groups', label: strings['leaderboard.groups.title'] },
  ];

  renderPlayersStat() {
    const { users, currentUser, isLoading, language, tabOrder } = this.props;
    const leaderboardListClass = language === 'ar' ? 'leaderboard-list-rtl' : 'leaderboard-list';

    return (
      <div className="panel">
        <BoxInfoProgressBar isVisible={isLoading}>
          <BoxInfoTitle text={strings['leaderboard.players.top']} />
          <div className={leaderboardListClass}>
            {!!currentUser && !!currentUser.uuid && (
              <>
                <LeaderboardItem
                  tabIndex={tabOrder.LEADERBOARD_START_INDEX}
                  name={currentUser.username}
                  rank={currentUser.rank}
                  rice={currentUser.rice}
                  avatar={currentUser.avatar}
                  type="users"
                  className="current-user"
                />
                <hr />
              </>
            )}
            {(users || []).map((u, index) => (
              <LeaderboardItem tabIndex={tabOrder.LEADERBOARD_START_INDEX + 1 + index} key={u.id} {...u} />
            ))}
          </div>
        </BoxInfoProgressBar>
      </div>
    );
  }

  renderGroupsStat() {
    const { groups, tabOrder } = this.props;
    return (
      <div className="panel">
        <BoxInfoTitle text={strings['leaderboard.groups.top']} />
        <div className="leaderboard-list">
          {(groups || []).map((g, index) => (
            <LeaderboardItem tabIndex={tabOrder.LEADERBOARD_START_INDEX + index} key={g.id} {...g} />
          ))}
        </div>
      </div>
    );
  }

  render() {
    return (
      <Tabs tabs={this.tabs}>
        {activeTab => (
          <BoxInfoContent className="leaderboard-content">
            {activeTab === 'players' && this.renderPlayersStat()}
            {activeTab === 'groups' && this.renderGroupsStat()}
          </BoxInfoContent>
        )}
      </Tabs>
    );
  }
}

export default withTabOrder(connect((state) => ({
  language: getLanguage(state),
}))(Leaderboard));
