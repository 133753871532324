// https://engine.staging.freerice.com/notifications/user/{user_id}
import { createSelector } from 'reselect';
import { produce } from 'immer';
import { createRequestTypes } from '../utils/actionHelpers';
import { createApiCall } from '../utils/api'; // region ACTION TYPES

export var ACTIVITY_FEED = {
  LOAD_FEED: createRequestTypes('ACTIVITY_LOAD_FEED'),
  LOAD_ANNOUNCEMENTS: createRequestTypes('ACTIVITY_LOAD_ANNOUNCEMENTS')
}; // endregion
// region ACTIONS

export var loadNotifications = createApiCall(ACTIVITY_FEED.LOAD_FEED, function (_ref) {
  var id = _ref.id,
      lang = _ref.lang;
  return {
    url: "/notifications/user/".concat(id),
    params: {
      lang: lang
    }
  };
});
export var loadAnnouncements = createApiCall(ACTIVITY_FEED.LOAD_ANNOUNCEMENTS, function (_ref2) {
  var lang = _ref2.lang;
  return {
    url: '/api/announcements',
    params: {
      lang: lang
    }
  };
}, {
  apiType: 'accounts'
}); // endregion
// region REDUCER

var initialState = {
  notifications: [],
  announcements: [],
  loading: false,
  error: null
};
export default produce(function (draft, _ref3) {
  var type = _ref3.type,
      payload = _ref3.payload;

  switch (type) {
    case ACTIVITY_FEED.LOAD_FEED.REQUEST:
    case ACTIVITY_FEED.LOAD_ANNOUNCEMENTS.REQUEST:
      draft.loading = true;
      break;

    case ACTIVITY_FEED.LOAD_FEED.SUCCESS:
      draft.loading = false;
      draft.notifications = payload;
      break;

    case ACTIVITY_FEED.LOAD_ANNOUNCEMENTS.SUCCESS:
      draft.loading = false;
      draft.announcements = payload;
      break;

    case ACTIVITY_FEED.LOAD_FEED.FAILURE:
    case ACTIVITY_FEED.LOAD_ANNOUNCEMENTS.FAILURE:
      draft.loading = false;
      draft.error = payload.message;
      break;

    default:
      break;
  }
}, initialState); // endregion
// region SELECTORS

var feedState = function feedState(state) {
  return state.activityFeed;
};

export var getNotifications = createSelector(feedState, function (state) {
  return state.notifications;
});
export var getAnnouncements = createSelector(feedState, function (state) {
  return state.announcements;
});
export var isFeedLoading = createSelector(feedState, function (state) {
  return state.loading;
}); // endregion