import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './BoxInfoProgressBar.scss';

const BoxInfoProgressBar = ({ isVisible, isCircle, children }) => (
  <>
    {!isVisible && children}
    {isVisible && <div className={classNames('loader', { circle: isCircle })}>{children}</div>}
  </>
);

BoxInfoProgressBar.propTypes = {
  isVisible: PropTypes.bool,
  isCircle: PropTypes.bool,
  children: PropTypes.any.isRequired,
};

BoxInfoProgressBar.defaultProps = {
  isVisible: true,
  isCircle: false,
};

export default BoxInfoProgressBar;
