import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { withTabOrder } from 'components/app/TabOrder';
import './AdBlock.scss';

const REVIVE_ID = process.env.REACT_APP_REVIVE_ID;

const REVIVE_SLOTS_DESKTOP = process.env.REACT_APP_REVIVE_DESKTOP_SLOTS.split(';').map(i => i.split(','));
const REVIVE_SLOTS_MOBILE = process.env.REACT_APP_REVIVE_MOBILE_SLOTS.split(';').map(i => i.split(','));

const knowIsDesktop = () => Math.max(document.documentElement.clientWidth, window.innerWidth || 0) >= 768;

// We set two locations for desktop utilization, because we must use fixed size on css-side:
// the first one has small rectangle size, the second one leaderboard size.
// Mobile uses always the first one, so the second one will be always empty in small breakpoint.
// The default desktop location is the second one, because internal ads have leadearboard size.
const AdBlock = ({ isCorrectAnswer = false, tabOrder }) => {
  const [isDesktop, setIsDesktop] = useState(knowIsDesktop());

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(knowIsDesktop());
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useLayoutEffect(() => {
    try {
      window.reviveAsync[REVIVE_ID].refresh();
    } catch (e) {
      console.error(e);
      console.warn('Missing Revive SDK. Please, check configuration');
    }
  }, [isCorrectAnswer, isDesktop]);

  const [correct, wrong] = isDesktop ? REVIVE_SLOTS_DESKTOP : REVIVE_SLOTS_MOBILE;
  const zones = isCorrectAnswer ? correct : wrong;

  return (
    <div id="advertisement" className="advertisement">
      {zones.map((zoneId, index) => (
        <div
          tabIndex={tabOrder[`ADVERTISEMENT_${index + 1}`]}
          key={zoneId}
          className="advertisement__item"
          title="Advertisement"
        >
          <ins data-revive-zoneid={zoneId} data-revive-id={REVIVE_ID} />
        </div>
      ))}
    </div>
  );
};

AdBlock.propTypes = {
  isCorrectAnswer: PropTypes.bool.isRequired,
  tabOrder: PropTypes.object.isRequired,
};

export default React.memo(withTabOrder(AdBlock));
