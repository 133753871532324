import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { getLanguage } from '@wfp/freerice-core/modules/app';

import strings from 'res/strings';

const LevelItem = ({ level, label, enabled, handleClick, language, tabIndex }) => {
  const levelsChartClass = language === 'ar' ? 'levels-chart-rtl' : 'levels-chart';

  return (
    <li
      tabIndex={tabIndex}
      key={level}
      data-target={level}
      onClick={enabled ? handleClick : undefined}
      onKeyPress={(e) => e.charCode === 13 && enabled && handleClick(e)}
      className={
        classNames(
          `${levelsChartClass}__level`, `${levelsChartClass}__level-${level}`, {
            [`${levelsChartClass}__level--enabled`]: enabled,
          }
        )
      }
    >
      <span className={`${levelsChartClass}__label`}>{label}</span>
    </li>
  );
};

LevelItem.propTypes = {
  level: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
  language: PropTypes.string,
};

LevelItem.defaultProps = {
  language: strings.getLanguage(),
};

export default connect((state) => ({
  language: getLanguage(state),
}))(LevelItem);
