import React from 'react';
import PropTypes from 'prop-types';
import ChallengeContent from 'components/pages/challenges/ChallengeContent';
import withPageTitle from 'containers/common/withPageTitle';

import strings from 'res/strings';

const Challenge = (props) => {
  const {
    match: {
      params: { id },
    },
    location: {
      search,
    },
  } = props;
  const query = new URLSearchParams(search);

  return (
    <ChallengeContent id={id} back={query.get('back')} />
  );
};

Challenge.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

Challenge.defaultProps = {
};

export default withPageTitle({ title: strings.challenges_link })(Challenge);
