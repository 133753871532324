import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from 'containers/App';
import { configureStore } from '@wfp/freerice-core';
import browsers from '@wfp/freerice-core/utils/browsers';
import strings from 'res/strings';
import { TabOrderProvider } from 'components/app/TabOrder/TabOrderContext';
import { NotificationProvider } from 'components/app/Notification/NotificationContext';

import 'styles/base/_base.scss';
import 'styles/base/_fonts.scss';

const BROWSER_LANGUAGE = !browsers.isIE ? strings.getLanguage() : null;
const PREFERENCE_LANGUAGE = window.localStorage.getItem('preferenceLanguage');

const DEFAULT_CATEGORY = BROWSER_LANGUAGE === 'ar' || PREFERENCE_LANGUAGE === 'ar' ?
  process.env.REACT_APP_DEFAULT_CATEGORY_AR :
  process.env.REACT_APP_DEFAULT_CATEGORY_EN;

const store = configureStore(
  {
    game: {
      gameId: window.sessionStorage.getItem('currentGame'),
      gameInfo: {
        category: window.localStorage.getItem('gameCategory') || DEFAULT_CATEGORY,
        level: Number(window.localStorage.getItem('gameLevel')) || 1,
        defaultCategory: DEFAULT_CATEGORY,
        listLangForCategories: {
          'ar': process.env.REACT_APP_DEFAULT_CATEGORY_AR,
          'en': process.env.REACT_APP_DEFAULT_CATEGORY_EN,
        },
      },
    },
    user: {
      user: JSON.parse(window.localStorage.getItem('user')) || {},
    },
  },
  {
    // eslint-disable-next-line no-underscore-dangle
    compose: process.env.NODE_ENV !== 'production' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : undefined,
    sessionStorage: window.sessionStorage,
    localStorage: window.localStorage,
    accountsBaseUrl: process.env.REACT_APP_ACCOUNTS_API_BASE,
    engineBaseUrl: process.env.REACT_APP_ENGINE_API_BASE,
    frontendVersion: 'web-1.0.0',
    language: PREFERENCE_LANGUAGE || BROWSER_LANGUAGE,
  }
);

if (document.querySelector('#root')) {
  ReactDOM.render(
    <Provider store={store}>
      <TabOrderProvider>
        <NotificationProvider>
          <BrowserRouter basename={process.env.REACT_APP_BASE_DIR}>
            <App />
          </BrowserRouter>
        </NotificationProvider>
      </TabOrderProvider>
    </Provider>,
    document.querySelector('#root')
  );
}
