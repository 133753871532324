import React from 'react';
import { ReactComponent as Character } from 'res/images/apps/character.svg';

import android from 'res/images/apps/android.svg';
import iPhone from 'res/images/apps/iPhone.svg';

import appStoreBadge from 'res/images/apps/app-store-badge.svg';
import googlePlayBadge from 'res/images/apps/google-play-badge.svg';

import BoxInfo from 'components/pages/common/box/BoxInfo';
import './AppsContent.scss';

import strings from 'res/strings';

export default () => (
  <BoxInfo type="small-width" className="apps-wrapper">
    <h1 className="grid-title">{strings['appscontent.web.title']}</h1>
    <div className="grid-character">
      <Character className="apps-wrapper__character" />
    </div>
    <div className="grid-ios">
      <img className="apps-wrapper__screenshot" src={iPhone} alt={strings['appscontent.web.screenshot.ios']} />
      <a
        href="https://apps.apple.com/app/apple-store/id1468090734?pt=120006284&ct=Beta_Landing_Page&mt=8"
        target="_blank"
        rel="noopener noreferrer"
        className="apps-wrapper__get-from-store"
      >
        <img className="apps-wrapper__store-badge" src={appStoreBadge} alt={strings['appscontent.web.getApp.ios']} />
      </a>
    </div>
    <div className="grid-android">
      <img className="apps-wrapper__screenshot" src={android} alt={strings['appscontent.web.screenshot.android']} />
      <a
        // eslint-disable-next-line
        href="https://play.google.com/store/apps/details?id=com.freerice.android&referrer=utm_source%3Dfreerice%26utm_medium%3Dwebsite%26utm_campaign%3DBeta_Landing_Page"
        target="_blank"
        rel="noopener noreferrer"
        className="apps-wrapper__get-from-store"
      >
        <img
          className="apps-wrapper__store-badge"
          src={googlePlayBadge}
          alt={strings['appscontent.web.getApp.android']}
        />
      </a>
    </div>
  </BoxInfo>
);
