import { createSelector } from 'reselect';
import { produce } from 'immer';
import { createRequestTypes } from '../utils/actionHelpers';
import { createApiCall } from '../utils/api'; // region ACTION TYPES

export var ABOUT = {
  LOAD_CONTENT: createRequestTypes('ABOUT_LOAD_CONTENT')
}; // endregion
// region ACTIONS

export var loadAboutPageContent = createApiCall(ABOUT.LOAD_CONTENT, function (_ref) {
  var lang = _ref.lang;
  return {
    url: '/api/about',
    params: {
      lang: lang
    },
    method: 'GET'
  };
}, {
  apiType: 'accounts'
}); // endregion
// region REDUCER

var initialState = {
  title: '',
  content: '',
  loading: false,
  error: null
};
export default produce(function (draft, _ref2) {
  var type = _ref2.type,
      payload = _ref2.payload;

  switch (type) {
    case ABOUT.LOAD_CONTENT.REQUEST:
      draft.loading = true;
      break;

    case ABOUT.LOAD_CONTENT.SUCCESS:
      draft.loading = false;
      draft.title = payload[0].title;
      draft.content = payload[0].content;
      break;

    case ABOUT.LOAD_CONTENT.FAILURE:
      draft.loading = false;
      draft.error = payload.message;
      break;

    default:
      break;
  }
}, initialState); // endregion
// region SELECTORS

var aboutState = function aboutState(state) {
  return state.about;
};

export var getTitle = createSelector(aboutState, function (state) {
  return state.title;
});
export var getContent = createSelector(aboutState, function (state) {
  return state.content;
}); // endregion