import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BoxInfoContent from 'components/pages/common/box/BoxInfoContent';
import BoxInfoProgressBar from 'components/pages/common/box/BoxInfoProgressBar';
import BoxInfoTitle from 'components/pages/common/box/BoxInfoTitle';
import GroupItem from 'components/pages/groups/myGroups/GroupItem';
import { withTabOrder } from 'components/app/TabOrder';
import './MyGroupsContent.scss';

import strings from 'res/strings';

class MyGroupsContent extends Component {
  static propTypes = {
    groups: PropTypes.array,
    handlerItemClick: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    tabOrder: PropTypes.object.isRequired,
  };

  static defaultProps = {
    groups: [],
    loading: false,
  };

  renderGroups = () => {
    const { groups, handlerItemClick, tabOrder } = this.props;

    return groups.map((group, index) => (
      <GroupItem
        tabIndex={tabOrder.GROUPS_START_INDEX + index}
        key={group.uuid}
        id={group.uuid}
        avatar={group.avatar}
        name={group.name}
        rice={group.rice}
        rank={group.rank}
        donated={group.donated}
        handlerClick={handlerItemClick}
      />
    ));
  };

  render() {
    const { loading, groups } = this.props;
    return (
      <BoxInfoProgressBar isVisible={loading}>
        <BoxInfoContent>
          <BoxInfoTitle className="my-groups-title" text={strings['myGroups.empty.title']} />
          {(groups && groups.length) && (
            <p>
              {strings.formatString(strings['groups.text.groups'], { count: <strong>{groups.length}</strong> })}
            </p>
          )}
          {(!groups || !groups.length) && <p>{strings['myGroups.empty.text']}</p>}
          {groups && <ul className="group-items">{this.renderGroups()}</ul>}
        </BoxInfoContent>
      </BoxInfoProgressBar>
    );
  }
}

export default withTabOrder(MyGroupsContent);
