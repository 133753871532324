import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import withPageTitle from 'containers/common/withPageTitle';
import { getGroupedCategories } from '@wfp/freerice-core/modules/categories';
import BackButton from 'components/app/BackButton';
import BoxInfo from 'components/pages/common/box/BoxInfo';
import CategoriesContent from 'components/pages/categories/CategoriesContent';

import { getGameID, getGameCategory, getGameError, changeCategory, createGame } from '@wfp/freerice-core/modules/game';
import { getUserID } from '@wfp/freerice-core/modules/user';
import { getLanguage } from '@wfp/freerice-core/modules/app';
import BoxInfoError from 'components/pages/common/box/BoxInfoError/BoxInfoError';

import { withTabOrder, tabOrders, TAB_NAMES } from 'components/app/TabOrder';
import strings from 'res/strings';

class Categories extends Component {
  static propTypes = {
    gameId: PropTypes.string,
    category: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.object.isRequired,
    categories: PropTypes.object.isRequired,
    changeCategory: PropTypes.func.isRequired,
    createGame: PropTypes.func.isRequired,
    error: PropTypes.array,
    user: PropTypes.string,
    language: PropTypes.string,
    setTabOrder: PropTypes.func.isRequired,
    focusTo: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {
    gameId: null,
    error: [],
    user: null,
    language: strings.getLanguage(),
  };

  componentDidMount() {
    const { setTabOrder, focusTo, location } = this.props;

    setTabOrder(tabOrders.CATEGORIES);

    if (location.state && location.state.focusAfter) focusTo(TAB_NAMES.MENU_TOGGLE);
  }

  componentDidUpdate(prevProps) {
    const { category, history } = this.props;
    if (category.id !== prevProps.category.id) {
      setTimeout(() => history.push(`/categories/${category.machine_name}`), 500);
    }
  }

  handleCategorySelect = category => {
    const { gameId, changeCategory, createGame, user, language } = this.props;
    if (!gameId) {
      createGame(category, 1, user, language);
    } else {
      changeCategory(gameId, { category, level: 1 }, language);
    }
  };

  render() {
    const { categories, error, category } = this.props;
    return (
      <BoxInfo type="medium-width">
        <CategoriesContent
          categories={categories}
          currentCategory={category}
          onCategorySelect={this.handleCategorySelect}
        />
        {!!error && <BoxInfoError messages={error} />}
        <BackButton />
      </BoxInfo>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  categories: getGroupedCategories,
  gameId: getGameID,
  category: getGameCategory,
  error: getGameError,
  user: getUserID,
  language: getLanguage,
});

export default compose(
  withTabOrder,
  withPageTitle({ title: strings.categories_link }),
  connect(
    mapStateToProps,
    { changeCategory, createGame }
  )
)(Categories);
