import _defineProperty from "/home/vsts/work/1/s/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { createSelector } from 'reselect';
import { produce } from 'immer';
import { createAction, createRequestTypes } from '../utils/actionHelpers';
import { createApiCall, transformUser } from '../utils/api'; // eslint-disable-next-line import/no-cycle

import { GAME } from './game';
import { APP } from './app';
import { getBadges } from './badges';
var spread = produce(Object.assign);
export var localStorageProperties = ['token', 'uuid']; // #region ACTION TYPES

export var USER = {
  SET_USER_DATA: 'USER_SET_USER_DATA',
  LOAD_ACCOUNT_DATA: 'LOAD_ACCOUNT_DATA',
  CLEAR_ERRORS: 'USER_CLEAR_ERRORS',
  LOGIN_FACEBOOK: createRequestTypes('USER_LOGIN_FACEBOOK'),
  LOAD_ADDITIONAL_ACCOUNT_DATA: createRequestTypes('USER_LOAD_ADDITIONAL_ACCOUNT_DATA'),
  LOAD_MAIN_ACCOUNT_DATA: createRequestTypes('USER_LOAD_MAIN_ACCOUNT_DATA'),
  UPDATE: createRequestTypes('USER_UPDATE'),
  SIGN_UP: createRequestTypes('USER_SIGN_UP'),
  SIGN_IN: createRequestTypes('USER_SIGN_IN'),
  DELETE: createRequestTypes('USER_DELETE'),
  RECOVER: createRequestTypes('USER_RECOVER'),
  ONETIME_LOGIN: createRequestTypes('USER_ONETIME_LOGIN'),
  LOAD_PUBIC_PROFILE: createRequestTypes('USER_PUBIC_PROFILE'),
  SIGN_OUT: 'USER_SIGN_OUT',
  SET_AGE_DATA: 'USER_SET_AGE_DATA'
}; // #endregion
// #region ACTIONS

export var setUserData = createAction(USER.SET_USER_DATA);
export var clearUserErrors = createAction(USER.CLEAR_ERRORS);
export var loadAccountData = createAction(USER.LOAD_ACCOUNT_DATA);
export var signOut = createAction(USER.SIGN_OUT);
export var loginWithFacebook = createApiCall(USER.LOGIN_FACEBOOK, function (data, lang) {
  return {
    url: "/auth/facebook?_lang=".concat(lang),
    method: 'POST',
    data: data
  };
}, {
  apiType: 'accounts'
});
export var signUp = createApiCall(USER.SIGN_UP, function (_ref) {
  var username = _ref.username,
      email = _ref.email,
      password = _ref.password,
      passwordConfirm = _ref.passwordConfirm,
      captcha = _ref.captcha,
      lang = _ref.lang;
  return {
    url: "/users?_format=json&_lang=".concat(lang),
    method: 'POST',
    headers: {
      'X-RecaptchaKey': process.env.REACT_APP_GOOGLE_CAPTCHA
    },
    data: {
      username: username,
      email: email,
      password: password,
      passwordConfirm: passwordConfirm,
      captcha: captcha
    }
  };
}, {
  apiType: 'accounts'
});
export var signIn = createApiCall(USER.SIGN_IN, function (_ref2) {
  var username = _ref2.username,
      password = _ref2.password,
      lang = _ref2.lang;
  return {
    url: "/auth/login?_format=json&_lang=".concat(lang),
    method: 'POST',
    data: {
      username: username,
      password: password
    }
  };
}, {
  apiType: 'accounts'
});
export var loadMainAccountData = createApiCall(USER.LOAD_MAIN_ACCOUNT_DATA, function (id) {
  return {
    url: "/users/".concat(id, "?_format=json"),
    method: 'GET'
  };
}, {
  apiType: 'accounts'
});
export var loadAdditionalAccountData = createApiCall(USER.LOAD_ADDITIONAL_ACCOUNT_DATA, function (id) {
  return {
    url: "/users/".concat(id, "?_format=json"),
    method: 'GET'
  };
}, {
  apiType: 'engine'
});
export var updateUserData = createApiCall(USER.UPDATE, function (id, params) {
  return {
    url: "/users/".concat(id, "?_format=json"),
    method: 'PATCH',
    data: params
  };
}, {
  apiType: 'accounts'
});
export var deleteAccount = createApiCall(USER.DELETE, function (_ref3) {
  var username = _ref3.username,
      password = _ref3.password;
  return {
    url: '/auth/users',
    method: 'DELETE',
    data: {
      username: username,
      password: password
    }
  };
}, {
  apiType: 'accounts'
});
export var loginRecover = createApiCall(USER.RECOVER, function (email, lang) {
  return {
    url: "/auth/recovery?_format=json&_lang=".concat(lang),
    method: 'POST',
    data: {
      email: email
    }
  };
}, {
  apiType: 'accounts'
});
export var oneTimeLogin = createApiCall(USER.ONETIME_LOGIN, function (data, lang) {
  return {
    url: "/auth/token-login?_format=json&_lang=".concat(lang),
    method: 'POST',
    data: data
  };
}, {
  apiType: 'accounts'
});
export var loadPublicProfile = createApiCall(USER.LOAD_PUBIC_PROFILE, function (uuids) {
  return {
    url: '/public/users',
    params: {
      uuids: uuids
    },
    method: 'GET'
  };
}, function (_, params) {
  return {
    apiType: 'accounts',
    params: params
  };
});
export var setAgeData = createAction(USER.SET_AGE_DATA); // #endregion
// #region REDUCER

var initialState = {
  isLoading: false,
  errors: [],
  user: {},
  ageData: {}
};
/* eslint-disable consistent-return */

export default produce(function (draft, _ref4) {
  var type = _ref4.type,
      payload = _ref4.payload;

  switch (type) {
    case APP.STARTING:
      return spread(initialState, draft);

    case USER.SET_USER_DATA:
    case USER.UPDATE_USER_DATA:
      draft.user = transformUser(_objectSpread(_objectSpread({}, draft.user || {}), payload));
      break;

    case USER.CLEAR_ERRORS:
      draft.errors = [];
      break;

    case USER.SIGN_UP.REQUEST:
    case USER.SIGN_IN.REQUEST:
    case USER.RECOVER.REQUEST:
    case USER.ONETIME_LOGIN.REQUEST:
    case USER.LOGIN_FACEBOOK.REQUEST:
    case USER.UPDATE.REQUEST:
    case USER.LOAD_MAIN_ACCOUNT_DATA.REQUEST:
    case USER.LOAD_ADDITIONAL_ACCOUNT_DATA.REQUEST:
    case USER.DELETE.REQUEST:
      draft.errors = [];
      draft.isLoading = true;
      break;

    case USER.SIGN_UP.SUCCESS:
    case USER.SIGN_IN.SUCCESS:
    case USER.LOGIN_FACEBOOK.SUCCESS:
    case USER.ONETIME_LOGIN.SUCCESS:
    case USER.LOAD_MAIN_ACCOUNT_DATA.SUCCESS:
    case USER.LOAD_ADDITIONAL_ACCOUNT_DATA.SUCCESS:
      draft.isLoading = false;
      draft.user = transformUser(_objectSpread(_objectSpread({}, draft.user || {}), payload));
      draft.error = '';
      break;

    case USER.UPDATE.SUCCESS:
      draft.isLoading = false;
      draft.user = transformUser(_objectSpread(_objectSpread({}, draft.user || {}), payload));
      draft.error = '';
      break;

    case GAME.SUBMIT_ANSWER.SUCCESS:
      draft.user.rice = payload.user_rice_total;
      break;

    case USER.RECOVER.SUCCESS:
      draft.isLoading = false;
      break;

    case USER.SIGN_OUT:
      draft.user = {};
      break;

    case USER.DELETE.SUCCESS:
      draft.isLoading = false;
      draft.user = {
        deleted: true
      };
      break;

    case USER.SIGN_UP.FAILURE:
    case USER.SIGN_IN.FAILURE:
    case USER.RECOVER.FAILURE:
    case USER.ONETIME_LOGIN.FAILURE:
    case USER.LOGIN_FACEBOOK.FAILURE:
    case USER.UPDATE.FAILURE:
    case USER.LOAD_MAIN_ACCOUNT_DATA.FAILURE:
    case USER.LOAD_ADDITIONAL_ACCOUNT_DATA.FAILURE:
      draft.isLoading = false;
      draft.errors = [payload.message];
      break;

    case USER.DELETE.FAILURE:
      draft.isLoading = false;
      draft.errors = [payload.code === 403 ? 'Wrong password' : payload.message];
      break;

    case USER.SET_AGE_DATA:
      draft.ageData = payload.ageData;
      break;

    default:
      break;
  }
}, initialState);
/* eslint-enable consistent-return */
// #endregion
// #region SELECTORS

var getUserState = function getUserState(state) {
  return state.user;
};

export var isLoadingUser = createSelector(getUserState, function (_ref5) {
  var isLoading = _ref5.isLoading;
  return isLoading;
});
export var getUserDataErrors = createSelector(getUserState, function (_ref6) {
  var errors = _ref6.errors;
  return errors;
});
export var getUserAccount = createSelector(getUserState, getBadges, function (_ref7, badges) {
  var user = _ref7.user;
  return user ? _objectSpread(_objectSpread({}, user), {}, {
    badgeIds: user.badges || [],
    badges: badges.filter(function (b) {
      return (user.badges || []).includes(b.id);
    })
  }) : {};
});
export var getUserID = createSelector(getUserAccount, function (account) {
  return account ? account.uuid : null;
});
export var getUserToken = createSelector(getUserState, function (_ref8) {
  var user = _ref8.user;
  return user ? user.token : null;
});
export var getAllAgeData = createSelector(getUserState, function (_ref9) {
  var ageData = _ref9.ageData;
  return ageData;
}); // #endregion